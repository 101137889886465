import React from 'react';
import { useGetUserByIdQuery } from '@api/users.api';
import { useAuth } from '@context/AuthProvider';
import { VscAccount } from 'react-icons/vsc';
import colors from '@styles/common/colors';
import styled from 'styled-components';
import typography from '@styles/common/typography';

const ProfileIcon: React.FC = () => {
  const { user } = useAuth();
  const userId = user?.uid || '';
  const { data: userData } = useGetUserByIdQuery(userId);

  return (
    <div>
      {userData && userData.photoURL ? (
        <AccountLayout>
          <img
            key={userData.photoURL} // Add a unique key to trigger a re-render when the photoURL changes
            src={userData.photoURL}
            alt="User Profile"
            style={{ width: '35px', height: '35px', borderRadius: '50%' }}
            loading="lazy"
          />
          <Text>{userData.displayName}</Text>
        </AccountLayout>
      ) : (
        <AccountLayout>
          <VscAccount size={35} color={colors.secondary_color.regular} />
        </AccountLayout>
      )}
    </div>
  );
};

export default ProfileIcon;

const AccountLayout = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Text = styled.div`
  color: ${colors.dark_grey};
  font-size: ${typography.fontSize.md};
  letter-spacing: 1px;
`;
