import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const ContainerTitle = styled.div`
  font-family: ${typography.fonts.body};
  font-size: ${sizes['2xl']};
  font-weight: ${typography.fontWeight.medium};
  color: ${colors.primary_color};
  margin-bottom: 1vh;
`;
