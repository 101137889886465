import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ModalState {
  modalType: string;
}

const initialState: ModalState = {
  modalType: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state: ModalState, action: PayloadAction<string>) => {
      state.modalType = action.payload;
      document.body.style.overflow = 'hidden';
    },
    hideModal: () => {
      document.body.style.overflow = 'unset';
      return initialState;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export const modalType = (state: RootState) => state.modal.modalType;
export default modalSlice.reducer;
