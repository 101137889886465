import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreateUpdateLocationDTO, LocationItem } from './location.interface';

const API_BASE_URL = 'http://localhost:3000/api/';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
  }),
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    getLocations: builder.query<LocationItem[], void>({
      query: () => 'locations',
      providesTags: [{ type: 'Locations', id: 'LIST' }],
    }),
    getLocationById: builder.query<LocationItem, number>({
      query: (locationId) => `locations/${locationId}`,
    }),

    createLocation: builder.mutation<LocationItem, CreateUpdateLocationDTO>({
      query: (location) => ({
        url: 'locations',
        method: 'POST',
        body: location,
      }),
      invalidatesTags: [{ type: 'Locations', id: 'LIST' }],
    }),
    updateLocation: builder.mutation<LocationItem, { locationId: number; location: CreateUpdateLocationDTO }>({
      query: ({ locationId, location }) => ({
        url: `locations/${locationId}`,
        method: 'PATCH',
        body: location,
      }),
      invalidatesTags: [{ type: 'Locations', id: 'LIST' }],
    }),
    deleteLocation: builder.mutation<void, number>({
      query: (id) => ({
        url: `locations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Locations', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetLocationByIdQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationApi;
