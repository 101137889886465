import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderLinkDesktop from './components/HeaderLinkDesktop';
import LanguageDropdown from '@components/Language/LanguageDropdown';
import AccountMenuDropdown from '@components/Profile/AccountMenuDropdown';
import { useAuth } from '@context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '@assets/icons/LogoWithTitle.png';
import { usePageLoaded } from '@hooks/usePageLoaded';
import typography from '@styles/common/typography';

const HeaderDesktop: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPageLoaded = usePageLoaded(100);
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setSticky(scrollPosition > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* To prevent the "Profile Icon" from appearing when you refresh the page */}
      {user ? (
        <HeaderContainer $issticky={isSticky}>
          <LogoLayout onClick={() => navigate('/')}>
            <img src={Logo} width={250} height={40} alt="La Pagode Khmère Logo" loading="lazy" />
          </LogoLayout>
          <HeaderLinkDesktop />
          <RightItemsLayout>
            <LanguageDropdown />
            <LinkText href="/donations">
              <ButtonLayout>
                <Text>{t('partner.donation')}</Text>
              </ButtonLayout>
            </LinkText>
            {isPageLoaded && <AccountMenuDropdown />}
          </RightItemsLayout>
        </HeaderContainer>
      ) : (
        <HeaderContainer $issticky={isSticky}>
          <img src={Logo} width={250} height={40} alt="La Pagode Khmère Logo" loading="lazy" />
          <HeaderLinkDesktop />
          <RightItemsLayout>
            <LanguageDropdown />
            <LinkText href="/donations">
              <ButtonLayout>
                <Text>{t('partner.donation')}</Text>
              </ButtonLayout>
            </LinkText>
            {isPageLoaded && <SigninBtn onClick={() => navigate('/signin')}>{t('button.signin')}</SigninBtn>}
          </RightItemsLayout>
        </HeaderContainer>
      )}
    </>
  );
};

export default HeaderDesktop;

const HeaderContainer = styled.div<{ $issticky: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 15px 15px;
  border-bottom: 1px solid ${colors.secondary_color.variant_two};
  box-shadow: ${shadows.base};
  background-color: ${(props) => (props.$issticky ? '#ffffff' : '#ffffff')};
  box-shadow: ${(props) => (props.$issticky ? shadows.md : 'none')};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
`;

const RightItemsLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SigninBtn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background: ${colors.white};
  border: 1px solid ${colors.dark_tangerine};
  border-radius: 4px;
  padding: 4px 10px;
  font-size: ${typography.fontSize.md};
  color: ${colors.dark_tangerine};
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    color: ${colors.text_white};
    background: ${colors.orange};
  }
`;

const LinkText = styled.a`
  text-decoration: none;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background: ${colors.dark_tangerine};
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background: ${colors.orange};
  }
`;

const Text = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.white};
  letter-spacing: 1px;
`;

const LogoLayout = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
