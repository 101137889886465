import { SubmitButton } from '@components/Button/SubmitButton';
import { ClassicFormInput } from '@components/UI/Input/ClassicFormInput';
import { ErrorSpan } from '@components/UI/Input/ErrorSpan.style';
import { Form, FormTitle, SSOButtonLayout } from '@components/UI/Input/Form.style';
import { FormInputWithIcon } from '@components/UI/Input/FormInputWithIcon';
import { Switch } from '@components/UI/Switch';
import { Label, TextBlue, TextSmall } from '@components/UI/Text';
import { useSubmitSigninForm } from '@hooks/useSubmitSigninForm';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { Toast } from '@utils/Toast';
import { LoginData } from '@api/auth.interface';
import GoogleSignInButton from '@components/SSO/GoogleSigninButton';
// import FacebookSignInButton from '@components/SSO/FacebookSigninButton';
import OrLineLayout from '@components/UI/LineLayout';
import TermsAndPrivacySigninLayout from '@components/UI/TermsAndPrivacy/TermsAndPrivacySigninLayout';
import { useAuth } from '@context/AuthProvider';
import styled from 'styled-components';
import { analytics, auth, firestore } from '@database/firebase';
import colors from '@styles/common/colors';
import { doc, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { logEvent } from '@firebase/analytics';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import Logo from '@assets/icons/LogoWithTitle.png';
import LogoIcon from '@assets/icons/Logo.png';
import Anna from '@assets/images/AnnaShop.png';
import TLBakery from '@assets/images/TLBakery.png';
import TLStore from '@assets/images/TSstore.png';
import Seo from '@components/SEO/Seo';

const defaultValue = {
  email: '',
  password: '',
};

const Signin = () => {
  const { control, handleSubmit, errors, reset } = useSubmitSigninForm();
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [formData, setFormData] = useState(defaultValue);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    logEvent(analytics, 'Login');
    const storedEmail = localStorage.getItem('rememberedEmail');
    const storedPassword = localStorage.getItem('rememberedPassword');

    if (storedEmail && storedPassword) {
      reset({
        email: storedEmail,
        password: storedPassword,
      });
      setIsEnable(true);
    }
  }, []);

  const saveTokenToUserCollection = async (userId: string, token: string) => {
    try {
      // Reference the user document in the collection
      const userRef = doc(firestore, 'users', userId);

      // Update the user document with the ID token
      await updateDoc(userRef, {
        idToken: token,
      });

      console.log(`Saved token to user collection for user ${userId}`);
    } catch (error) {
      console.log('Error saving token to user collection:', error);
    }
  };

  const onSubmit = useCallback(
    async (data: LoginData) => {
      try {
        setFormData(data);

        const response = await signIn(data.email, data.password);
        navigate('/');
        const currentUser = response.user;

        // Retrieve Id tokens
        const authToken = await currentUser.getIdToken();

        const refreshToken = auth.currentUser?.refreshToken || '';

        // Store the tokens in local storage
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('refreshToken', refreshToken);

        if (isEnable) {
          localStorage.setItem('rememberedEmail', data.email);
          localStorage.setItem('rememberedPassword', data.password);
        } else {
          localStorage.removeItem('rememberedEmail');
          localStorage.removeItem('rememberedPassword');
        }

        // Save the ID token to the user collection
        await saveTokenToUserCollection(currentUser.uid, authToken);

        const toast = new Toast(t('account.signin-success'));
        toast.showSuccess();
      } catch (error) {
        console.log(error);
        const toast = new Toast(t('account.signin-fail'));
        toast.showError();
      }
    },
    [navigate, isEnable]
  );

  const toggleSwitchButton = () => {
    setIsEnable(!isEnable);
  };

  const togglePassword: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <Seo
        title={t('button.signin') + ' | La Pagode Khmère'}
        description={t('metatag.about.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com/signin'}
        image={Logo}
      />
      <FormContainer>
        <InfoContainer>
          <SponsorsLayout>
            <Content>{t('partner.sponsors')}</Content>
            <Link to={'https://www.facebook.com/annaonlineshop16'}>
              <img src={Anna} width={120} height={80} />
            </Link>
            <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
              <img src={TLBakery} width={120} height={100} />
            </Link>
            <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
              <img src={TLStore} width={120} height={120} />
            </Link>
          </SponsorsLayout>
        </InfoContainer>
        <Form>
          <img src={LogoIcon} width={60} height={60} />
          <FormTitle>{t('button.signin')}</FormTitle>
          <Label aria-hidden={'true'}>{t('form.email')}</Label>
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = formData.email }, fieldState: { error } }) => (
              <ClassicFormInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                  onChange(sanitizedValue);
                }}
                onBlur={onBlur}
                placeholder="example@gmail.com"
                value={value}
                error={error ? true : false}
                aria-label={'email'}
                aria-required="true"
              />
            )}
          />
          <ErrorSpan>{errors.email?.message}</ErrorSpan>
          <Label aria-hidden={'true'}>{t('form.password')}</Label>
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = formData.password }, fieldState: { error } }) => (
              <FormInputWithIcon
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                  onChange(sanitizedValue);
                }}
                onBlur={onBlur}
                placeholder="Password"
                type={passwordShown ? 'text' : 'password'}
                value={value}
                error={error ? true : false}
                icon={passwordShown ? FaEye : FaEyeSlash}
                onClick={togglePassword}
                aria-label={'Password'}
                aria-required="true"
              />
            )}
          />
          <ErrorSpan>{errors.password?.message}</ErrorSpan>
          <TextBlue>{t('form.forgotPassword')}</TextBlue>
          <Switch
            active={isEnable}
            onClick={toggleSwitchButton}
            text={t('form.rememberMe') || ''}
            aria-pressed={'false'}
            aria-hidden="true"
          ></Switch>
          <SubmitButton text={t('button.signin')} aria-labelledby={'sign in'} onClick={handleSubmit(onSubmit)} />
          <OrLineLayout />
          <SSOButtonLayout>
            <GoogleSignInButton />
            {/* <FacebookSignInButton /> */}
          </SSOButtonLayout>
          <TextSmall>{t('form.noAccount')}</TextSmall>
          <CreateAccountBtn onClick={() => navigate('/signup')}>{t('button.createAccount')}</CreateAccountBtn>
          <TermsAndPrivacySigninLayout />
        </Form>
        <CookieConsentBanner />
      </FormContainer>
    </>
  );
};

export default Signin;

const CreateAccountBtn = styled.div`
  width: 100%;
  border: 1px solid ${colors.dark_tangerine};
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${colors.dark_tangerine};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background: ${colors.dark_tangerine};
  }
`;

const FormContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    justify-content: flex-start;
  }
`;

const InfoContainer = styled.div`
  display: none;

  @media (min-width: 1280px) {
    display: block;
    background: ${colors.dark_tangerine};
    height: 100vh;
    width: 50%;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  letter-spacing: 0.5px;
  color: ${colors.text_white};
`;

const SponsorsLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
