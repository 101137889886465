import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import { NavProps } from '@utils/navigation-routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FooterLinks: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);
  const { t } = useTranslation();

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    navigate(pathname);
  };

  const navLinksMobile: NavProps[] = [
    {
      id: 1,
      label: t('header.home'),
      pathname: '/',
    },
    {
      id: 2,
      label: t('header.about'),
      pathname: '/about',
    },
    {
      id: 3,
      label: t('header.author'),
      pathname: '/author',
    },
    {
      id: 4,
      label: t('header.search'),
      pathname: '/search',
    },
    {
      id: 5,
      label: t('header.map'),
      pathname: '/map',
    },
    {
      id: 6,
      label: t('header.contact'),
      pathname: '/contact',
    },
    {
      id: 7,
      label: t('partner.donation'),
      pathname: '/donations',
    },
  ];

  return (
    <>
      <Nav>
        {navLinksMobile.map((item) => (
          <NavLink
            key={item.id}
            href={item.pathname}
            $isactive={activePathname === item.pathname}
            onClick={() => handleLinkClick(item.pathname)}
          >
            <NavItem>{item.label}</NavItem>
          </NavLink>
        ))}
      </Nav>
    </>
  );
};

export default FooterLinks;

type NavLinkProps = {
  $isactive: boolean;
};

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: ${({ $isactive }) => ($isactive ? colors.dark_tangerine : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ $isactive }) => ($isactive ? '1' : '0')});
    transition: transform 500ms ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  ${({ $isactive }) => ($isactive ? 'text-decoration: underline underline-offset(8px);' : '')}
`;

const NavItem = styled.span`
  position: relative;
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -1px;
    left: 0;
    background-color: ${colors.dark_tangerine};
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
  }

  &:hover::before {
    transform: scaleX(1);
  }
`;
