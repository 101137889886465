import { Marker, Popup } from 'react-leaflet';
import { getIcon } from './GetIcon';
import { StyledTooltip, tooltipOptions } from '@components/Maps/Tooltip';
import { useMemo } from 'react';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import { useTranslation } from 'react-i18next';
import colors from '@styles/common/colors';
import {
  Button,
  ButtonInnerLayout,
  ButtonLayout,
  Description,
  ImageLayout,
  MarkerLayout,
  MovingChevron,
  Name,
} from './styles/markerMap.style';
import { LocationItem } from '@api/location.interface';
import Loader from '@components/Loader';
import { useAuth } from '@context/AuthProvider';
// import usePremiumStatus from '@stripe/usePremiumStatus';
// import { useGetUserByIdQuery } from '@api/users.api';
// import { UserRoles } from '@components/Users/roles.enum';
import { useNavigate } from 'react-router-dom';
import useFetchLocation from '@hooks/useFetchLocations';
import styled from 'styled-components';
import { PlaceholderImage } from '@components/Search/styles/filtredData.style';
import placeholderImage from '@assets/images/image-placeholder.png';

const MarkerMap = () => {
  const { t } = useTranslation();
  const language = useAppSelector(currentLanguage);
  const { user } = useAuth();
  const { locations, isLoading } = useFetchLocation();
  // const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  // const userIsPremium = usePremiumStatus(user);
  // const userRole = userData?.role;
  const navigate = useNavigate();

  const memoizedGetIcon = useMemo(() => getIcon, []);

  // const handleDetailsPageClick = (details: LocationItem) => {
  //   if (userIsPremium || userRole === UserRoles.ADMIN || userRole === UserRoles.PREMIUM) {
  //     navigate(`/map/details/${details.id}`);
  //   } else {
  //     navigate('/unlock-premium');
  //   }
  // };

  const handleDetailsPageClick = (details: LocationItem) => {
    if (user) {
      navigate(`/map/details/${details.id}`);
    } else {
      window.location.href = '/proceed-to-signin';
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {locations.length > 0 &&
        locations.map((location) => {
          const lat = (location.geometry as any)?.location?.lat;
          const lng = (location.geometry as any)?.location?.lng;

          if (lat !== undefined && lng !== undefined && location.geometry && (location.geometry as any)?.location) {
            return (
              <Marker draggable={true} icon={memoizedGetIcon(location.color)} key={location.id} position={[lat, lng]}>
                <Popup>
                  <MarkerLayout>
                    <ImageLayout>
                      {location?.images?.coverImage ? (
                        <Image src={location?.images?.coverImage} alt={location.name} />
                      ) : (
                        <PlaceholderImage src={placeholderImage} alt={location.name} />
                      )}
                    </ImageLayout>
                    <Name>
                      {t('search.vat')} {(location.locales as any)[language]?.name || location.name}
                    </Name>
                    <Description>
                      {(location.locales as any)[language]?.description || location.description}
                    </Description>
                    <ButtonLayout>
                      <ButtonInnerLayout onClick={() => handleDetailsPageClick(location)}>
                        <Button>{t('button.plus')}</Button>
                        <MovingChevron color={colors.white} size={18} />
                      </ButtonInnerLayout>
                    </ButtonLayout>
                  </MarkerLayout>
                </Popup>

                <StyledTooltip {...tooltipOptions}>
                  <MarkerLayout>
                    {location?.images?.coverImage ? (
                      <Image src={location?.images?.coverImage} alt={location.name} />
                    ) : (
                      <PlaceholderImage src={placeholderImage} alt={location.name} />
                    )}
                    <Name>
                      {t('search.vat')}&nbsp;
                      {(location.locales as any)[language]?.name || location.name}
                    </Name>
                    <Description>
                      {(location.locales as any)[language]?.description || location.description}
                    </Description>
                  </MarkerLayout>
                </StyledTooltip>
              </Marker>
            );
          } else {
            // Handle the case when lat or lng is undefined
            console.error('Invalid location:', location);
            return null;
          }
        })}
    </>
  );
};

export default MarkerMap;

export const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
