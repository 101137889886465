import { useEditUserMutation, useGetUserByIdQuery } from '@api/users.api';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import FormInput from '@components/Forms/FormInput';
import Header from '@components/Header/Header';
import UploadPhotoURLInput from '@components/Profile/UploadPhotURLInput';
import { User, initialValue } from '@components/Users/types';
import { useAuth } from '@context/AuthProvider';
import { analytics, firestore, storage } from '@database/firebase';
import { logEvent, setUserId } from '@firebase/analytics';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import { Toast } from '@utils/Toast';
import { doc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Settings: React.FC = () => {
  const [formData, setFormData] = useState<User>(initialValue);
  const [editUserMutation] = useEditUserMutation();
  const { user } = useAuth();
  const userId = user?.uid || '';
  const { data: currentUser, refetch } = useGetUserByIdQuery(userId);
  const { t } = useTranslation();

  const fetchUser = async () => {
    try {
      if (currentUser) {
        setFormData(currentUser);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    logEvent(analytics, 'SettingsPage');
    setUserId(analytics, userId);
    if (currentUser) {
      fetchUser();
    }
  }, [currentUser]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const updatedUserData = {
          id: formData.id,
          displayName: formData.displayName,
          email: formData.email,
          bio: formData.bio,
          country: formData.country,
          photoURL: formData.photoURL,
        };

        await editUserMutation(updatedUserData);

        const toast = new Toast('Profile successfully updated! 🚀');
        toast.showSuccess();
        setFormData(initialValue);
        refetch(); // The data is immediately updated after submit it
      } catch (error) {
        console.error('Error updating user:', error);
        const toast = new Toast('Error updating user!');
        toast.showError();
      }
    },
    [formData]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileUpload =
    (fieldName: string, userData: User) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return; // if file is null, exit early

      const fileExtension = file.name.split('.').pop();
      const fileName = file.name.replace(/\.[^/.]+$/, '');
      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString().replace(/\//g, '-')}-${currentDate
        .toLocaleTimeString()
        .replace(/:/g, ':')}`;

      try {
        // Delete the old photo if it exists
        if (userData.photoURL) {
          const oldPhotoRef = ref(storage, userData.photoURL);
          await deleteObject(oldPhotoRef);
        }

        // Store files in images folder
        const imageKey = ref(storage, `avatars/${nanoid()}-${formattedDate}-${fileName}.${fileExtension}`);
        // Upload file
        const snapshot = await uploadBytes(imageKey, file);
        // Get file url
        const url = await getDownloadURL(snapshot.ref);
        // handle the URL or update state as needed
        console.log(url);

        // Save the image URL to Firestore Database coverImage in locations collection
        if (userData.id) {
          // Check if location.id is defined
          const usersDocRef = doc(firestore, 'users', userData.id);
          await updateDoc(usersDocRef, {
            photoURL: url,
          });
        }

        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: url,
        }));
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <div>
      <Header />
      <SettingsLayout>
        <Title>{t('account.settings')}</Title>
        <UserContainer>
          <SubTitle>{t('account.user')}</SubTitle>
          <form onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={t('form.username')}
              name="displayName"
              value={formData.displayName || ''}
              onChange={handleChange}
            />
            {/* <FormInput type="email" label={'Email'} name="email" value={formData.email || ''} onChange={handleChange} /> */}
            <UploadPhotoURLInput
              fieldName="photoURL"
              label={t('account.photoProfile')}
              accept="image/*"
              handleFileUpload={handleFileUpload}
              userData={formData}
            />
            <StyledButton>
              <ButtonText>{t('account.saveProfile')}</ButtonText>
            </StyledButton>
          </form>
        </UserContainer>
      </SettingsLayout>
      <CookieConsentBanner />
    </div>
  );
};

export default Settings;

const SettingsLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 70px 0;

  @media (min-width: 1280px) {
    margin: 100px 0;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 26px;
  color: ${colors.text_grey};
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
`;

const UserContainer = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 20px 28px;
  background: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 6px;
  box-shadow: ${shadows.base};
`;

const SubTitle = styled.div`
  font-size: 24px;
  color: ${colors.text_grey};
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background: ${colors.dark_tangerine};
  padding: 10px;
  border-radius: 4px;
  margin: 12px 0 8px 0;
  cursor: pointer;
  border: none;
`;
const ButtonText = styled.div`
  color: ${colors.white};
  font-size: ${typography.fontSize.md};
  letter-spacing: 0.5px;
`;
