import colors from '@styles/common/colors';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SponsorLogoLinks from './SponsorLogoLinks';
import Footer from '@components/Footer/Footer';

const SponsorLogosProfile: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Layout>
      <SponsorLogoLinks />
      <LinksLayout>
        <RightButtonTitle>{t('partner.title-sponsors')}</RightButtonTitle>
        <SponsorButton onClick={() => navigate('/sponsors')}>{t('partner.become-sponsor')}</SponsorButton>
        <FooterLayout>
          <Footer />
        </FooterLayout>
      </LinksLayout>
    </Layout>
  );
};

export default SponsorLogosProfile;

const RightButtonTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 30px;
`;

const Layout = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8px 12px;
  margin-top: 50px;
`;

const SponsorButton = styled.div`
  width: 170px;
  height: 50px;
  border: 1px solid ${colors.dark_tangerine};
  padding: 8px 10px;
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${colors.dark_tangerine};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background: ${colors.dark_tangerine};
  }
`;

const LinksLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FooterLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
