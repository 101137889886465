import { useOnClickOutside } from '@hooks/useOnClickOutside';
import colors from '@styles/common/colors';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BsInfoSquare } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlineClose } from 'react-icons/md';

const MarkerInfoMobileDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleHideDrawer = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleShowDrawer = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = 'hidden';
  };

  useOnClickOutside(dropdownRef, handleHideDrawer);

  return (
    <div ref={dropdownRef}>
      <Overlay isOpen={isOpen} onClick={handleHideDrawer} />
      <Container>
        <InfoIconLayout onClick={handleShowDrawer}>
          <BsInfoSquare size={20} color={colors.secondary_color.regular} />
          <Text>{t('sidebar.markerInfo')}</Text>
        </InfoIconLayout>
        <Modal isOpen={isOpen}>
          <ModalContent isOpen={isOpen}>
            <MenuLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.red} />
                <Content>{t('markerInfoModal.norodom')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={'orange'} />
                <Content>{t('markerInfoModal.sisowath')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.yellow} />
                <Content>{t('markerInfoModal.monivong')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.blue} />
                <Content>{t('markerInfoModal.sihanouk')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.secondary_color.variant_one} />
                <Content>{t('markerInfoModal.khmerRouge')}</Content>
              </MarkerLayout>
            </MenuLayout>
          </ModalContent>
          <BorderLine />
          <CloseLayout>
            <MdOutlineClose size={20} color={colors.secondary_color.regular} onClick={handleHideDrawer} />
          </CloseLayout>
        </Modal>
      </Container>
    </div>
  );
};

export default MarkerInfoMobileDrawer;

interface ModalProps {
  isOpen: boolean;
}

const Container = styled.div`
  position: relative;
`;

const Overlay = styled.div<ModalProps>`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 999;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`;

const Modal = styled.div<ModalProps>`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  background-color: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  z-index: 99991;
  transition: bottom 0.3s ease-in-out;
  overflow: hidden;
`;

const ModalContent = styled.div<ModalProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 45px 5px 20px 5px;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;
`;

const MenuLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  gap: 0.3rem;
`;

const MarkerLayout = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Content = styled.div`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: ${colors.text_grey};

  @media (min-width: 360px) {
    font-size: 14px;
  }
`;

const CloseLayout = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`;

const BorderLine = styled.div`
  width: 100%;
  position: absolute;
  top: 30px;
  border: 0.8px solid ${colors.secondary_color.variant_one};
`;

const Text = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  text-align: center;
  line-height: 16px;
  padding: 0 4px;
`;

const InfoIconLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 6px;
  cursor: pointer;
`;
