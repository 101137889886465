export const sizes = {
  xs: '0.375em', // 6px
  sm: '0.625em', // 10px
  base: '0.75em', // 12px
  md: '0.875em', // 14px
  lg: '1em', // 16px
  xl: '1.250em', // 18px
  '2xl': '1.5em', // 24px
  '3xl': '2em', // 32px
  '4xl': '3em', // 48px
  '5xl': '4em',
};

export default sizes;
export type Sizes = typeof sizes;
