import About from '@pages/About';
import Author from '@pages/Author';
import Contact from '@pages/Contact';
import NotFoundPage from '@pages/NotFoundPage';
import Home from '@pages/Home';
import Map from '@pages/Map';
import Privacy from '@pages/Privacy';
import Search from '@pages/Search';
import Signup from '@pages/Signup';
import Success from '@pages/Success';
import { Route, Routes } from 'react-router-dom';
import CreateLocation from '@pages/CreateLocation';
import ProtectedRoutes from './ProtectedRoutes';
import UnauthorizedPage from '@pages/Unauthorize';
import CreateUsers from '@components/Users/CreateUsers';
import Dashboard from '@pages/Dashboard';
import { useGetUserByIdQuery } from '@api/users.api';
import { useAuth } from '@context/AuthProvider';
import { UserRoles } from '@components/Users/roles.enum';
import Profile from '@pages/Profile';
import Settings from '@pages/Settings';
import VerifyEmail from '@pages/VerifyEmail';
import Signin from '@pages/Signin';
// import PremiumPlans from '@pages/PremiumPlans';
import ProceedToSignin from '@pages/ProceedToSignin';
import Terms from '@pages/Terms';
import Sponsors from '@pages/Sponsors';
import UnlockPremiumFeatures from '@pages/UnlockPremiumFeatures';
import DetailsMap from '@pages/DetailsMap';
import Articles from '@pages/Articles';
import React from 'react';
import NavigateToTopPage from '@utils/NavigateToTopPage';

export const GlobalRouter = () => {
  const { user } = useAuth();
  const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  const userRole = userData?.role;
  const storedAuthToken = localStorage.getItem('authToken');

  const isAuthenticated = user || storedAuthToken;

  return (
    <React.Fragment>
      <NavigateToTopPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/author" element={<Author />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/map" element={<Map />} />
        <Route path="/map/details/:id" element={<DetailsMap />} />
        {/* <Route path="/plans" element={<PremiumPlans />} /> */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/proceed-to-signin" element={<ProceedToSignin />} />
        <Route path="/search" element={<Search />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/donations" element={<Sponsors />} />
        <Route path="/success" element={<Success />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/unlock-premium" element={<UnlockPremiumFeatures />} />
        <Route path="/verify-account" element={<VerifyEmail />} />
        <Route path="*" element={<NotFoundPage />} />

        {isAuthenticated && (
          <Route element={<ProtectedRoutes />}>
            {userRole === UserRoles.ADMIN && (
              <>
                <Route path="/articles" element={<Articles />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-location" element={<CreateLocation />} />
                <Route path="/users" element={<CreateUsers />} />
              </>
            )}
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        )}
      </Routes>
    </React.Fragment>
  );
};
