import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  padding: 100px 20px;
  margin-top: 90px;
  gap: 2px;

  @media (min-width: 360px) {
    padding: 100px 40px;
  }

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 1280px) {
    margin: 0 auto;
  }
`;

export const FormContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

export const FormTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.text_grey};
  margin-bottom: 12px;
`;

export const Input = styled.input`
  width: 100%;
  color: ${colors.secondary_color.regular};
  padding: 1.2vh;
  border: 0.5px solid ${colors.secondary_color.regular};
  border-radius: 4px;

  font-size: ${sizes.lg};
  &::placeholder {
    color: ${colors.secondary_color.variant_one};
    font-size: ${sizes.lg};
  }
  &:focus {
    outline: none;
    border: 1px solid ${colors.dark_tangerine};
    box-shadow: 0 0 2px ${colors.primary_color};
  }
`;

export const SSOButtonLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
`;
