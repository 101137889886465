import { app } from '@database/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

export async function goToBillingPortal() {
  const functions = getFunctions(app, 'europe-west3');

  const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

  try {
    const response = await functionRef({
      returnUrl: `${window.location.origin}/profile`,
    });

    const data = response.data as { url: string }; // Type casting

    window.location.assign(data.url);
  } catch (error) {
    console.error('Error creating billing portal link:', error);
  }
}
