import RichTextEditor from '@components/Articles/RichTextEditor';
import React from 'react';

const Articles: React.FC = () => {
  return (
    <div>
      <RichTextEditor />
    </div>
  );
};

export default Articles;
