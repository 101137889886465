import useWindowSize from '@hooks/useWindowSize';
import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header: React.FC = () => {
  const size = useWindowSize();
  return (
    <div>
      {size.width && size.width >= 1280 && <HeaderDesktop />}
      {size.width && size.width < 1280 && <HeaderMobile />}
    </div>
  );
};

export default Header;
