import { SignupData } from '@api/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameRegex, nameRegexMessage, passwordRegex } from '@utils/validator-form.regex';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useSubmitSignupForm = () => {
  const { t } = useTranslation();
  const passwordRegexMessage = t('signup.passwordRegexMessage');
  const usernameRequired = t('signup.usernameRequired');
  const emailRequired = t('signup.emailRequired');
  const passwordRequired = t('signup.passwordRequired');
  const confirmPasswordRequired = t('signup.confirmPasswordRequired');
  const confirmPasswordNotMatch = t('signup.confirmPasswordNotMatch');
  const emailNotValid = t('signup.emailNotValid');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        displayName: Yup.string().required(usernameRequired).matches(nameRegex, nameRegexMessage),
        email: Yup.string().required(emailRequired).email(emailNotValid),
        password: Yup.string().required(passwordRequired).matches(passwordRegex, passwordRegexMessage),
        passwordConfirm: Yup.string()
          .required(confirmPasswordRequired)
          .oneOf([Yup.ref('password')], confirmPasswordNotMatch),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SignupData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return {
    control,
    handleSubmit,
    errors,
    reset,
  };
};
