import { LocationItem } from '@api/location.interface';
import { ClassicButton } from '@components/Button/ClassicButton';
import Loader from '@components/Loader';
import useFetchLocation from '@hooks/useFetchLocations';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const DetailsMap: React.FC = () => {
  const { locations, isLoading } = useFetchLocation();
  const [selectedDetailsPage, setSelectedDetailsPage] = useState<LocationItem | null>(null);
  const { t } = useTranslation();
  const language = useAppSelector(currentLanguage);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (locations) {
      setSelectedDetailsPage(locations.find((detail) => detail.id === id) || null);
    }
  }, [locations]);

  return (
    <ModalLayout>
      <ModalContainer>
        <CloseLayout onClick={() => navigate('/map')}>
          <MdOutlineClose size={24} color={colors.secondary_color.regular} />
        </CloseLayout>
        {!isLoading ? (
          <MenuLayout>
            <TitleLayout>
              <VatTitle>
                {t('search.vat')} {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.name}
                &nbsp;-&nbsp;
                {selectedDetailsPage?.provinceOrCityCode}
              </VatTitle>
            </TitleLayout>

            <CoverLayout>
              {selectedDetailsPage?.images?.sanctuaryImageSide1 ? (
                <Image src={selectedDetailsPage?.images?.sanctuaryImageSide1} alt={selectedDetailsPage?.name} />
              ) : null}
              <CoverImageTitle>
                {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary1}
              </CoverImageTitle>
            </CoverLayout>

            <ContentLayout>
              <ContentInnerLayout>
                <div>
                  <SubtitleLayout>
                    <Title>{t('form.identification')}</Title>
                  </SubtitleLayout>
                  <Content>
                    <b>{t('form.name')}</b> {t('search.vat')}{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.name}
                  </Content>
                  <Content>
                    <b>{t('form.otherName')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.otherName}
                  </Content>
                  <Content>
                    <b>{t('form.country')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.country}
                  </Content>
                  <Content>
                    <b>{t('form.city')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.city}
                  </Content>
                  <Content>
                    <b>{t('form.district')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.district}
                  </Content>
                  <Content>
                    <b>{t('form.commune')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.commune}
                  </Content>
                  <Content>
                    <b>{t('form.latitude')}</b> {(selectedDetailsPage?.geometry as any)?.location?.lat}
                  </Content>
                  <Content>
                    <b>{t('form.longitude')}</b> {(selectedDetailsPage?.geometry as any)?.location?.lng}
                  </Content>
                  <Content>
                    <b>{t('form.lastVisit')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.lastVisit}
                  </Content>
                  <SubtitleLayout>
                    <Title>{t('form.paintingAndArchi')}</Title>
                    <ItalicText>{t('form.history')}</ItalicText>
                  </SubtitleLayout>
                  <Content>
                    <b>{t('form.constructionDate')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.constructionDate}
                  </Content>
                  <Content>
                    {selectedDetailsPage?.decorDate ? <b>{t('form.decorDate')}</b> : null}{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.decorDate}
                  </Content>
                  <Content>
                    <b>{t('form.archives')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.archives}
                  </Content>
                  <Content>
                    <b>{t('form.khmerRougePeriod')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.khmerRougePeriod}
                  </Content>
                  <Content>
                    <b>{t('form.restoration')}</b>{' '}
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.restoration}
                  </Content>
                  <SubtitleLayout>
                    <ContentTitle>{t('form.exterior')}</ContentTitle>
                    <Content>
                      {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.exterior}
                    </Content>
                    <ContentTitle>{t('form.bay')}</ContentTitle>
                    <Content>
                      {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.bay}
                    </Content>
                    <ContentTitle>{t('form.interior')}</ContentTitle>
                    <Content>
                      {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.interior}
                    </Content>
                    <ContentTitle>{t('form.particularity')}</ContentTitle>
                    <Content>
                      {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.particularity}
                    </Content>
                    {selectedDetailsPage?.decorText ? <ContentTitle>{t('form.decorText')}</ContentTitle> : null}{' '}
                    <Content>
                      {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.decorText}
                    </Content>
                  </SubtitleLayout>
                  <ButtonLayout>
                    <ClassicButton title={t('partner.donation')} onClick={() => navigate('/donations')} />
                  </ButtonLayout>
                </div>

                <ImageLayout>
                  {selectedDetailsPage?.images?.sanctuaryImageSide2 ? (
                    <Image src={selectedDetailsPage?.images?.sanctuaryImageSide2} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary2}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.sanctuaryImageSide3 ? (
                    <Image src={selectedDetailsPage?.images?.sanctuaryImageSide3} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary3}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.sanctuaryImageSide4 ? (
                    <Image src={selectedDetailsPage?.images?.sanctuaryImageSide4} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary4}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.sanctuaryDrawing ? (
                    <Image src={selectedDetailsPage?.images?.sanctuaryDrawing} alt={selectedDetailsPage?.name} />
                  ) : null}
                  {selectedDetailsPage?.images?.painting ? (
                    <Image src={selectedDetailsPage?.images?.painting} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.painting1 ? (
                    <Image src={selectedDetailsPage?.images?.painting1} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales
                      ? (selectedDetailsPage.locales as any)[language]?.titlePainting1
                      : null}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.painting2 ? (
                    <Image src={selectedDetailsPage?.images?.painting2} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting2}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.painting3 ? (
                    <Image src={selectedDetailsPage?.images?.painting3} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting3}
                  </ImageTitle>
                  {selectedDetailsPage?.images?.painting4 ? (
                    <Image src={selectedDetailsPage?.images?.painting4} alt={selectedDetailsPage?.name} />
                  ) : null}
                  <ImageTitle>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting4}
                  </ImageTitle>
                  <BackToMapButton>
                    <ClassicButton title={t('button.back')} onClick={() => navigate('/map')} />
                  </BackToMapButton>
                </ImageLayout>
              </ContentInnerLayout>
            </ContentLayout>
          </MenuLayout>
        ) : (
          <LoaderLayout>
            <Loader />
          </LoaderLayout>
        )}
      </ModalContainer>
    </ModalLayout>
  );
};

export default DetailsMap;

const CoverLayout = styled.div`
  margin: 8px 0;
`;

const BackToMapButton = styled.div`
  max-width: 270px;
  margin: 20px auto;
`;

const ButtonLayout = styled.div`
  max-width: 100px;
  margin: 30px auto;
`;

const LoaderLayout = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalLayout = styled.div`
  width: 100%;
  height: 100vh;
`;

const ModalContainer = styled.div`
  background: ${colors.white};
`;

const CloseLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
`;

const MenuLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.8rem;
  gap: 0.2rem;
  height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary_color.variant_one};
    border-radius: 5px;
    box-shadow: ${shadows.lg};
    width: 5px;
    height: 5px;
  }

  @media (min-width: 360px) {
    padding: 1rem 1.2rem;
  }
  @media (min-width: 768px) {
    padding: 1rem 4rem;
  }
  @media (min-width: 1024px) {
    padding: 3rem 15rem;
  }
  @media (min-width: 1440px) {
    padding: 3rem 25rem;
  }
  @media (min-width: 2560px) {
    padding: 3rem 40rem;
  }
`;

const ContentLayout = styled.div`
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 12px 0 24px 0;
`;

const ContentInnerLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1024px) {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    gap: 4rem;
  }
`;

const TitleLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const VatTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.dark_tangerine};

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text_grey};
  margin: 12px 0;

  @media (min-width: 1440px) {
    font-size: 20px;
  }
`;

const ItalicText = styled.div`
  font-size: 16px;
  color: ${colors.text_grey};
  margin: 4px 0;
  font-style: italic;
`;

const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text_grey};
  margin: 12px 0;

  @media (min-width: 1440px) {
    font-size: 20px;
  }
`;

const ImageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubtitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const Content = styled.div`
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${colors.text_grey};

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (min-width: 1440px) {
    line-height: 28px;
  }
`;

const ImageTitle = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${colors.text_grey};
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const CoverImageTitle = styled.div`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${colors.text_grey};
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
