import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
import { SSOButton } from '@components/Button/SSOButton';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { firestore } from '@database/firebase';
import { UserRoles } from '@components/Users/roles.enum';
import { useTranslation } from 'react-i18next';

const GoogleSignInButton: React.FC = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState<boolean>(false);
  const { t } = useTranslation();

  const signInWithGoogle = async () => {
    setAuthing(true);
    try {
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(auth, provider);
      const user = response.user;

      if (user) {
        const authToken = await user.getIdToken();
        console.log(authToken);
        localStorage.setItem('authToken', authToken);
      }

      const usersCollection = collection(firestore, 'users');
      const userQuery = query(usersCollection, where('uid', '==', user.uid));
      const querySnapshot = await getDocs(userQuery);

      let role = UserRoles.USER;
      if (querySnapshot.empty) {
        // If the user is new, add them to the Firestore users collection with the default role
        const newUser = {
          id: user.uid,
          displayName: user.displayName,
          email: user.email,
          authProvider: user.providerData[0].providerId,
          isEmailVerified: user.emailVerified, // after signed in, email is automatically verified
          role: UserRoles.USER,
          createdAt: new Date(),
        };

        // Add the new user to Firestore
        await setDoc(doc(firestore, 'users', user.uid), newUser);

        // Set the user role
        role = newUser.role;
      } else {
        // Retrieve the user's role from Firestore
        const userDoc = querySnapshot.docs[0];
        role = userDoc.data().role;
      }

      localStorage.setItem('userRole', role);

      navigate('/');
    } catch (error) {
      console.log(error);
      setAuthing(false);
    }
  };

  return (
    <SSOButton title={t('button.signinWithGoogle')} onClick={signInWithGoogle} disabled={authing} icon={FcGoogle} />
  );
};

export default GoogleSignInButton;
