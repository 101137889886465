import MohaLeapSanctuary from '@assets/home/SlideShow/Moha-Leap-sanctuary.webp';
import OffsetRoof from '@assets/home/SlideShow/Pothi-Voan-Offset-roof.webp';
import RoofFinial from '@assets/home/SlideShow/Enteak-Kosei-roof-finial.webp';
import Reamker from '@assets/home/SlideShow/Reach-Bo-Reamker.webp';
import DailyLife from '@assets/home/SlideShow/Reach-Bo-Daily-life.webp';
import Fence from '@assets/home/SlideShow/Kompoul-fence.webp';
import Tympanum from '@assets/home/SlideShow/Puthea-tympanum.webp';
import CelestialNymph from '@assets/home/SlideShow/Chong-Kaoh-celestial-nymph-on-the-ceiling.webp';
import PrinceSihanouk from '@assets/home/SlideShow/Chak-Prince-Sihanouk-attending-distribution-of-the-sacred-relics.webp';
import FloorTile from '@assets/home/SlideShow/Svay-Sach-Phnum-floor-tiles.webp';
import HighRelief from '@assets/home/SlideShow/Reach-Bo-high-relief-tympanum.webp';
import AdornedColumn from '@assets/home/SlideShow/Svay-Sach-Phnum-golden-adorned-column-painting.webp';
import PaintingColumn from '@assets/home/SlideShow/Moni-Sakor-Vessantara-jataka.webp';
import Vespa from '@assets/home/SlideShow/Chak-French-couple-riding-a-vespa-scooter-along-the-seaside.webp';
import Interior from '@assets/home/SlideShow/Roka-Kandal-Chas-Interior-of-Buddhist-sanctuary.webp';
import BasRelief from '@assets/home/SlideShow/Damrei-Sa-Bas-relief-on-exterior-wall-fence.webp';

interface ImageData {
  id: number;
  imageSrc: string;
  altText: string;
  content: string;
}

export const imageSlideShowData: ImageData[] = [
  {
    id: 1,
    imageSrc: MohaLeapSanctuary,
    altText: 'Moha Leap Buddhist Sanctuary',
    content:
      'Welcome to La Pagode Khmère: Unearth the Mysteries of Cambodia’s Ancient Buddhist Monastery from 1860 to 1975.',
  },
  {
    id: 2,
    imageSrc: OffsetRoof,
    altText: 'Offset Roof',
    content: 'Offset Roof of a buddhist Sanctuary',
  },
  {
    id: 3,
    imageSrc: RoofFinial,
    altText: 'Roof Finial',
    content: 'A roof finial of a buddhist Sanctuary',
  },
  {
    id: 4,
    imageSrc: Reamker,
    altText: 'Reamker',
    content: 'A mural painting depicting Rama lifts the bow, a scene from the Reamker, ',
  },
  {
    id: 5,
    imageSrc: DailyLife,
    altText: 'Daily Life',
    content: 'A mural painting depicting a scene from daily life',
  },
  {
    id: 6,
    imageSrc: Fence,
    altText: 'A decorative fence',
    content: 'A decorative fence of a buddhist sanctuary',
  },
  {
    id: 7,
    imageSrc: Tympanum,
    altText: 'A decorative tympanum',
    content: 'A decorative tympanum, exterior of a buddhist sanctuary',
  },
  {
    id: 8,
    imageSrc: CelestialNymph,
    altText: 'Celestial nymphs',
    content: 'A ceiling depicting celestial nymphs',
  },
  {
    id: 9,
    imageSrc: PrinceSihanouk,
    altText: 'Prince Sihanouk',
    content: 'A mural painting depicting Prince Sihanouk attending the distribution of sacred relics',
  },
  {
    id: 10,
    imageSrc: FloorTile,
    altText: 'Floor tiles',
    content: 'Floor tiles of a buddhist sanctuary',
  },
  {
    id: 11,
    imageSrc: HighRelief,
    altText: 'High-relief tympanum',
    content: 'A high-relief tympanum of a buddhist monastery',
  },
  {
    id: 12,
    imageSrc: AdornedColumn,
    altText: 'A golden adorned column',
    content: 'A golden adorned column of a buddhist monastery',
  },
  {
    id: 13,
    imageSrc: PaintingColumn,
    altText: 'Vessantara jātaka',
    content: 'A painted column depicting a scene from the Vessantara jātaka',
  },
  {
    id: 14,
    imageSrc: Vespa,
    altText: 'Vespa painting',
    content: 'A mural painting depicting a French couple riding a Vespa scooter along the seaside, ',
  },
  {
    id: 15,
    imageSrc: Interior,
    altText: 'An interior view',
    content: 'An interior view of a buddhist sanctuary',
  },
  {
    id: 16,
    imageSrc: BasRelief,
    altText: 'A bas-relief of a buddhist monastery fence',
    content: 'A bas-relief of a scene from the Reamker of a buddhist monastery',
  },
];
