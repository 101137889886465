import { ArchitectureLayout, SubTitle, Title } from '@components/UI/Home.style';
import React from 'react';
import ImageScroller from './ImageScroller';
import { monumentsImageData } from './MonumentsImageData';

interface ImageData {
  image: string;
  content: string;
  title: string;
}

const Architecture: React.FC = () => {
  const imagesData: ImageData[] = monumentsImageData.map((item) => ({
    image: item.imageSrc,
    title: item.altText,
    content: item.content,
  }));
  return (
    <ArchitectureLayout>
      <Title>Discover the Tranquil Beauty of these sacred sites </Title>
      <SubTitle>
        Immerse yourself in the rich spiritual and cultural heritage of this captivating country as you delve into a
        journey through history as you uncover the profound serenity and cultural richness of these sacred sites. From
        the serene landscapes of Siem Reap to the vibrant streets of Phnom Penh, every province holds its own treasure
        trove of ancient wonders waiting to be explored.
      </SubTitle>
      {/* <Title>Discover Ancient Painting</Title>
      <SubTitle>
        Embark on a visual odyssey through the intricate brushstrokes and vibrant colors of Khmer Buddhist paintings. La
        Pagode Khmère offers an interactive gallery where you can zoom in to explore the minutiae of each masterpiece.
      </SubTitle> */}
      <ImageScroller images={imagesData} />
    </ArchitectureLayout>
  );
};

export default Architecture;
