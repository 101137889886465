import { UserRoles } from './roles.enum';

export interface User {
  id: string;
  displayName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  bio: string;
  country: string;
  photoURL: string;
  role: UserRoles;
  authProvider: string;
  isEmailVerified: boolean;
  createdAt: Date;
}

export const initialValue: User = {
  id: '',
  displayName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  photoURL: '',
  bio: '',
  country: '',
  isEmailVerified: false,
  role: UserRoles.USER,
  authProvider: '',
  createdAt: new Date(),
};
