import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import sizes from '@styles/common/sizes';
import styled from 'styled-components';

export const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 22px;

  @media ${devices.tablet} {
    padding: 0 20vw;
  }

  @media ${devices.laptop} {
    padding: 0 35vw;
  }
`;

export const Input = styled.input`
  width: 100%;
  color: ${colors.text_grey};
  padding: 1vh;
  border: 1px solid ${colors.secondary_color.variant_one};
  border-radius: 4px;
  font-size: ${sizes.md};

  &:focus {
    outline: none;
    border-color: ${colors.dark_tangerine};
    box-shadow: 0 0 3px ${colors.dark_tangerine};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 15vh;
  color: ${colors.text_grey};
  padding: 1.2vh;
  border: 1px solid ${colors.secondary_color.variant_one};
  border-radius: 4px;
  font-size: ${sizes.md};
`;
