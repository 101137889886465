import L from 'leaflet';
import blueMarkerIcon from '@assets/icons/marker-blue.svg';
import orangeMarkerIcon from '@assets/icons/marker-orange.svg';
import yellowMarkerIcon from '@assets/icons/marker-yellow.svg';
import redMarkerIcon from '@assets/icons/marker-light-red.svg';
import grayMarkerIcon from '@assets/icons/marker-gray.svg';

export const getIcon = (color: string) => {
  let iconUrl;

  switch (color) {
    case 'blue':
      iconUrl = blueMarkerIcon;
      break;
    case 'red':
      iconUrl = redMarkerIcon;
      break;
    case 'orange':
      iconUrl = orangeMarkerIcon;
      break;
    case 'gray':
      iconUrl = grayMarkerIcon;
      break;
    default:
      iconUrl = yellowMarkerIcon;
  }
  return L.icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });
};

export const geolocationIcon = L.icon({
  iconUrl: blueMarkerIcon,
  iconRetinaUrl: blueMarkerIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});
