import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import sizes from '@styles/common/sizes';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: ${typography.fonts.body};
  font-size: ${sizes.md};
  color: ${colors.text_grey};

  @media ${devices.tablet} {
    font-size: ${sizes.lg};
  }
`;
