import { User } from '@components/Users/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_BASE_URL = 'https://europe-west3-vatkhmerapp.cloudfunctions.net/api/';

export const usersApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    // eslint-disable-next-line no-empty-pattern
    prepareHeaders: (headers, {}) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => 'users',
    }),

    getUserById: builder.query<User, string>({
      query: (id) => `users/${id}`,
    }),

    createUser: builder.mutation<User, Partial<User>>({
      query: (userData) => ({
        url: 'users/create-user',
        method: 'POST',
        body: userData,
      }),
    }),

    updateUser: builder.mutation<User, Partial<User>>({
      query: ({ id, ...userData }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body: userData,
      }),
    }),

    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
    }),

    editUser: builder.mutation<User, Partial<User>>({
      query: ({ id, ...userData }) => ({
        url: `users/edit-profile/${id}`,
        method: 'PATCH',
        body: userData,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} = usersApi;
