import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { Input, InputButton, InputLayout } from './Input.style';

const WarningIcon = styled(Warning)`
  height: 15px;
  width: 15px;
`;
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string;
  type?: string;
}

export const ClassicFormInput = (props: InputProps) => {
  const { error = false, placeholder, value = '', onChange, type = 'text' } = props;
  return (
    <InputLayout>
      <Input error={error} placeholder={placeholder} value={value} onChange={onChange} type={type} />
      {error ? (
        <InputButton>
          <WarningIcon />
        </InputButton>
      ) : null}
    </InputLayout>
  );
};
