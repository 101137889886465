import React from 'react';
import { Input } from './styles/form.style';
import styled from 'styled-components';
import colors from '@styles/common/colors';

interface FormInputProps {
  type: string;
  label: string;
  name: string;
  value?: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({ type, label, name, value, onChange }) => {
  return (
    <InputLayout>
      <Label>{label}</Label>
      <Input type={type} name={name} value={value ?? ''} onChange={onChange} />
    </InputLayout>
  );
};

export default FormInput;

const InputLayout = styled.div`
  width: 100%;
  max-width: 360px;
`;

const Label = styled.div`
  font-size: 16px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin: 4px 0;
`;
