import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import { GoChevronRight } from 'react-icons/go';
import styled from 'styled-components';

export const Name = styled.div`
  color: ${colors.text_grey};
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${colors.text_grey};
  letter-spacing: 0.8px;
  margin-bottom: 2px;
`;

export const MarkerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 4px 0;
`;

export const ButtonInnerLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.dark_tangerine};
  border-radius: 6px;
  box-shadow: ${shadows.base};
  padding: 6px 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Button = styled.div`
  font-size: ${typography.fontSize.md};
  font-weight: 500;
  color: ${colors.white};
  letter-spacing: 0.5px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

export const ImageLayout = styled.div`
  margin-top: 10px;
  margin-left: 2px;
`;

// const flashAnimation = keyframes`
//   0% { opacity: 1; }
//   50% { opacity: 0; }
//   100% { opacity: 1; }
// `;

// const FlashingGoChevronRight = styled(GoChevronRight)`
//   animation: ${flashAnimation} 1s infinite;
// `;

export const MovingChevron = styled(GoChevronRight)`
  animation: moveChevron 0.8s infinite alternate;

  @keyframes moveChevron {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(3px);
    }
  }
`;
