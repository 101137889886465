import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  BorderLine,
  BurgerLayout,
  ClearButton,
  DeleteButton,
  Description,
  HeaderRecentSearchLayout,
  MarkerInfoLayout,
  RecentSearchContainer,
  RecentSearchItem,
  RecentSearchName,
  RecentSearchTitle,
  SearchButton,
  SearchContainer,
  SearchInputStyled,
  SearchItem,
  SearchItemLayout,
  SearchResults,
} from './styles/autocomplete.style';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import { useTranslation } from 'react-i18next';
import MenuDrawer from '@components/Drawer/MenuDrawer';
import { BsSearch } from 'react-icons/bs';
import MarkerInfoMobileDrawer from '@components/Drawer/MarkerInfoMobileDrawer';
import colors from '@styles/common/colors';
import useRecentSearches from '@hooks/useRecentSearches';
import { LocationItem } from '@api/location.interface';
import useFetchLocation from '@hooks/useFetchLocations';

export const Autocomplete: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const { recentSearches, setRecentSearches, saveRecentSearch, loadRecentSearches, clearRecentSearches } =
    useRecentSearches();
  const [showRecentSearches, setShowRecentSearches] = useState<boolean>(false);
  const { locations } = useFetchLocation();
  const map = useMap();
  const searchResultRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const language = useAppSelector(currentLanguage);

  useEffect(() => {
    loadRecentSearches();
  }, []);

  const handleQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    try {
      setQuery(newQuery);

      if (newQuery.trim() === '') {
        setShowRecentSearches(true);
        await loadRecentSearches();
      } else {
        setShowRecentSearches(false);
      }
    } catch (error) {
      console.error('Error handling search:', error);
    }
  };

  const filteredData = useMemo(() => {
    if (query.trim() === '') {
      return [];
    }
    const queryLower = query.toLowerCase();
    return locations
      .filter((location) => {
        const name = location.name.toLowerCase();
        return name.includes(queryLower);
      })
      .splice(0, 5);
    // Limit the filtered data to 5 items;
  }, [locations, query]);

  const handleResultClick = (result: LocationItem) => {
    const lat = (result.geometry as any)?.location?.lat;
    const lng = (result.geometry as any)?.location?.lng;
    const markerIcon = L.icon({
      iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
      iconSize: [2, 2],
      iconAnchor: [8, 16],
    });
    const marker = L.marker([lat, lng], { icon: markerIcon }).addTo(map);
    const locationName = (result.locales as any)[language]?.name || result.name;

    marker
      .bindTooltip(locationName, {
        permanent: false,
        direction: 'top',
        offset: [0, -10],
        className: 'marker-tooltip',
      })
      .openTooltip();

    // marker.bindPopup(`<b>${name}</b><br>${description}`).openPopup();
    // marker.addTo(map);
    // marker.openPopup();

    map.flyTo([lat, lng], 14);
    setQuery('');

    // add selected query to recent searches
    if (!recentSearches.includes(locationName)) {
      setRecentSearches([locationName, ...recentSearches.slice(0, 4)]);
    }
    saveRecentSearch(locationName);
    loadRecentSearches(); // update recent searches
  };

  const handleCloseRecentSearch = () => {
    setShowRecentSearches(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(searchResultRef, () => {
    setQuery('');
    handleCloseRecentSearch();
  });

  return (
    <div>
      <BurgerLayout>
        <MenuDrawer />
      </BurgerLayout>
      <MarkerInfoLayout>
        <MarkerInfoMobileDrawer />
      </MarkerInfoLayout>
      <SearchButton>
        <BsSearch size={15} aria-label="Search" />
      </SearchButton>
      <SearchContainer>
        <SearchInputStyled
          type="text"
          value={query}
          placeholder={t('search.input') as string}
          onChange={handleQueryChange}
          onFocus={handleQueryChange}
        />
        {query && (
          <ClearButton onClick={() => setQuery('')}>
            <AiOutlineCloseCircle size={15} aria-label={'Clear'} />
          </ClearButton>
        )}
      </SearchContainer>
      {filteredData.length > 0 ? (
        <SearchResults ref={searchResultRef}>
          {filteredData.map((result: LocationItem) => (
            <React.Fragment key={result.id}>
              <SearchItem onClick={() => handleResultClick(result)}>
                <Description>{(result.locales as any)[language]?.description || result.description}</Description>
              </SearchItem>
              <BorderLine />
            </React.Fragment>
          ))}
        </SearchResults>
      ) : null}

      {/* Rechent searches */}
      {showRecentSearches && recentSearches.length > 0 ? (
        <RecentSearchContainer ref={searchResultRef} onClick={handleCloseRecentSearch}>
          <HeaderRecentSearchLayout>
            <RecentSearchTitle>{t('search.recentSearch')}</RecentSearchTitle>
            <DeleteButton onClick={clearRecentSearches}>{t('button.clear')}</DeleteButton>
          </HeaderRecentSearchLayout>
          {recentSearches.map((search: string, index: number) => (
            <React.Fragment key={`${search}-${index}`}>
              <RecentSearchItem
                onClick={() => {
                  setQuery(search);
                  setShowRecentSearches(false);
                }}
              >
                <SearchItemLayout>
                  <BsSearch size={15} aria-label="Search" color={colors.secondary_color.variant_one} />
                  <RecentSearchName>{search}</RecentSearchName>
                </SearchItemLayout>
              </RecentSearchItem>
            </React.Fragment>
          ))}
        </RecentSearchContainer>
      ) : null}
    </div>
  );
};
