import Header from '@components/Header/Header';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Seo from '@components/SEO/Seo';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import VatImage from '@assets/home/Moha-Leap-KampongCham.webp';
import { ArchitectureLayout, PageLayout, SubTitle, Title } from '@components/UI/Home.style';
import Footer from '@components/Footer/Footer';
import Architecture from '@components/Home/Architecture';
import { paintingImageData } from '@components/Home/PaintingImageData';
import PaintingScroller from '@components/Home/PaintingScroller';
import ImageSlideShow from '@components/Home/ImageSlideShow';

interface ImageData {
  image: string;
  content: string;
  title: string;
}

const Home: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    logEvent(analytics, 'HomePage_visited');
  }, []);

  const imagesData: ImageData[] = paintingImageData.map((item) => ({
    image: item.imageSrc,
    title: item.altText,
    content: item.content,
  }));

  return (
    <>
      <Seo
        title={t('header.home') + ' | La Pagode Khmère'}
        description={t('metatag.home.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com'}
        image={VatImage}
      />
      <Header />
      <PageLayout>
        <ImageSlideShow />
        <Architecture />
        <ArchitectureLayout>
          <Title>Discover Ancient Painting </Title>
          <SubTitle>
            Embark on a visual odyssey through the intricate brushstrokes and vibrant colors of Khmer Buddhist
            paintings. La Pagode Khmère offers an interactive gallery where you can explore the minutiae of each
            masterpiece.
          </SubTitle>
          <PaintingScroller images={imagesData} />
        </ArchitectureLayout>
      </PageLayout>
      <Footer />
      <CookieConsentBanner />
    </>
  );
};

export default Home;
