import React from 'react';
import { TextArea } from './styles/form.style';

interface LocationFormInputProps {
  label: string;
  name: string;
  value?: any;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const LocationTextAreaInput: React.FC<LocationFormInputProps> = ({ label, name, value, onChange }) => {
  return (
    <div>
      <label>{label}</label>
      <TextArea name={name} value={value ?? ''} onChange={onChange} />
    </div>
  );
};

export default LocationTextAreaInput;
