import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const PageLayout = styled.div`
  padding: 90px 0 60px 0;
`;

export const Title = styled.h2`
  font-size: ${typography.fontSize.lg};
  font-weight: bold;
  color: ${colors.text_grey};
  line-height: 26px;
  letter-spacing: 1px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize['2xl']};
    line-height: 28px;
  }
`;
export const SubTitle = styled.p`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 0.5px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.xl};
    line-height: 30px;
  }
`;

export const LandingText = styled.div`
  font-size: ${typography.fontSize.md};
  font-weight: bold;
  color: ${colors.text_white};
  line-height: 22px;
  letter-spacing: 0.5px;

  @media (min-width: 320px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }

  @media (min-width: 540px) {
    font-size: ${typography.fontSize['2xl']};
    line-height: 28px;
  }

  @media (min-width: 768px) {
    font-size: ${typography.fontSize['3xl']};
    line-height: 40px;
  }
`;

export const ArchitectureLayout = styled.div`
  padding: 30px 15px 0 15px;

  @media (min-width: 320px) {
    padding: 30px 30px 0 30px;
  }
  @media (min-width: 768px) {
    padding: 30px 15px 0 15px;
  }
  @media (min-width: 1024px) {
    padding: 30px 53px 0 53px;
  }
  @media (min-width: 1280px) {
    padding: 30px 180px 0 180px;
  }
  @media (min-width: 1440px) {
    padding: 30px 380px 0 380px;
  }
  @media (min-width: 1920px) {
    padding: 30px 590px 0 590px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const ImageLayout = styled.div`
  @media (min-width: 540px) {
    padding: 0 30px;
  }
  @media (min-width: 768px) {
    padding: 0 15px;
  }
  @media (min-width: 1024px) {
    padding: 0 53px;
  }
  @media (min-width: 1280px) {
    padding: 0 180px;
  }
  @media (min-width: 1440px) {
    padding: 0 380px;
  }
  @media (min-width: 1920px) {
    padding: 0 590px;
  }
`;

export const LandingImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
`;

export const LandingTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  padding: 15px;
  margin-bottom: 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  @media (min-width: 320px) {
    padding: 15px 30px;
  }
  @media (min-width: 540px) {
    padding: 30px 30px;
  }
  @media (min-width: 768px) {
    padding: 30px 150px 30px 30px;
  }
  @media (min-width: 1024px) {
    padding: 30px 300px 30px 30px;
  }
`;

export const LinkText = styled.a`
  text-decoration: none;
`;

export const ButtonLayout = styled.div`
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.dark_tangerine};
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background: ${colors.orange};
  }

  @media (min-width: 540px) {
    width: 120px;
  }
`;

export const ButtonText = styled.div`
  font-size: ${typography.fontSize.sm};
  color: ${colors.white};
  letter-spacing: 0.5px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.md};
  }
  @media (min-width: 768px) {
    font-size: ${typography.fontSize.lg};
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 5px 50px 5px;

  @media (min-width: 320px) {
    padding: 100px 30px 50px 30px;
  }

  @media (min-width: 540px) {
    padding: 100px 30px;
  }

  @media (min-width: 768px) {
    padding: 100px 60px;
  }

  @media (min-width: 820px) {
    margin: 100px 100px;
  }

  @media (min-width: 1024px) {
    padding: 80px 40px 10px 40px;
  }

  @media (min-width: 1280px) {
    margin: 80px 145px 0 145px;
  }

  @media (min-width: 1440px) {
    margin: 70px 300px;
  }

  @media (min-width: 1920px) {
    margin: 80px 640px;
  }
`;

export const LeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LinkLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 4px 8px;
`;
