import React from 'react';
import { Article } from '@api/article.interface';
import colors from '@styles/common/colors';
import styled from 'styled-components';
import { AiOutlineAudio } from 'react-icons/ai';

interface Props {
  fieldName: string;
  article: Article;
  label: string;
  accept: string;
  handleFileUpload: (
    fieldName: string,
    article: Article
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const UploadArticleAudio: React.FC<Props> = ({ fieldName, article, label, accept, handleFileUpload }) => {
  return (
    <FileInputLayout>
      <Label>
        {label}
        <LabelIconLayout>
          <AiOutlineAudio size={50} color={colors.secondary_color.regular} />
        </LabelIconLayout>
        <FileInput type="file" name={fieldName} onChange={handleFileUpload(fieldName, article)} accept={accept} />
      </Label>
    </FileInputLayout>
  );
};

export default UploadArticleAudio;

const FileInputLayout = styled.div`
  position: relative;
  display: inline-block;
`;

const Label = styled.label`
  color: ${colors.text_grey};
  letter-spacing: 1px;
`;

const LabelIconLayout = styled.div`
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 30%;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
`;
