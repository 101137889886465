import { useOnClickOutside } from '@hooks/useOnClickOutside';
import colors from '@styles/common/colors';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { GoChevronDown } from 'react-icons/go';
import { GoChevronUp } from 'react-icons/go';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { hideModal, modalType, showModal } from '@store/modal/modalSlice';
import { OptionsDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { SwitchLayout, Label } from './FiltredDateSidebar';
import { Switch } from '@components/Switch/Switch';

interface DateDropdownProps {
  onChange: (selectedDate: string | null) => void;
}

const DateDropdown: React.FC<DateDropdownProps> = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState<OptionsDate | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const modal = useAppSelector(modalType);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const optionsDate: OptionsDate[] = [
    { label: t('date.all'), value: 'All' },
    { label: t('date.red'), value: 'red' },
    { label: t('date.orange'), value: 'orange' },
    { label: t('date.yellow'), value: 'yellow' },
    { label: t('date.blue'), value: 'blue' },
    { label: t('date.gray'), value: 'gray' },
  ];

  const handleSelectDate = (date: OptionsDate) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date.value);
    }
    dispatch(hideModal());
  };

  const handleHideModal = () => {
    dispatch(hideModal());
  };

  const handleShowModal = () => {
    dispatch(showModal('dateDropdown'));
  };

  useOnClickOutside(dropdownRef, handleHideModal);

  return (
    <div ref={dropdownRef}>
      <Overlay isOpen={modal} onClick={handleHideModal} />
      <DropdownContainer>
        <DropdownButton onClick={handleShowModal}>
          <IconWrapper>
            {selectedDate ? selectedDate.label : t('city.chooseEraMobile')}
            {modal === 'dateDropdown' ? (
              <GoChevronDown color={colors.dark_tangerine} />
            ) : (
              <GoChevronUp color={colors.dark_tangerine} />
            )}
          </IconWrapper>
        </DropdownButton>

        <Modal isOpen={modal}>
          <ModalContent isOpen={modal}>
            {optionsDate.map((location, index) => (
              <Column key={index} onClick={() => handleSelectDate(location)}>
                <SwitchLayout>
                  <Switch
                    key={location.value}
                    active={selectedDate?.value === location.value}
                    onClick={() => handleSelectDate(location)}
                  />
                  <Label>{location.label}</Label>
                </SwitchLayout>
              </Column>
            ))}
          </ModalContent>
          <BorderLine />
          <Close onClick={handleHideModal}>Close</Close>
        </Modal>
      </DropdownContainer>
    </div>
  );
};

export default DateDropdown;

interface ModalProps {
  isOpen: string;
}

const DropdownContainer = styled.div`
  position: relative;
`;
const DropdownButton = styled.button`
  width: 100%;
  padding: 6px 7px;
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary_color.variant_one};
  border-radius: 5px;
  font-size: 11px;
  color: ${colors.text_grey};
  cursor: pointer;

  @media (min-width: 375px) {
    font-size: 13px;
  }

  @media (min-width: 540px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const Overlay = styled.div<ModalProps>`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`;

const Modal = styled.div<ModalProps>`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  background-color: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  z-index: 9999;
  transition: bottom 0.3s ease-in-out;
  overflow: hidden;
`;

const ModalContent = styled.div<ModalProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 50px 20px 20px 20px;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 360px) {
    padding: 50px 0 20px 40px;
  }
`;

const Column = styled.div`
  flex: 1 50%;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.secondary_color.variant_two};
  }
`;

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.dark_tangerine};
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const BorderLine = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
  border: 0.8px solid ${colors.secondary_color.variant_one};
`;
