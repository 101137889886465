import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { imageSlideShowData } from './ImageSlideShowData';
import typography from '@styles/common/typography';
import colors from '@styles/common/colors';

const ImageLayout = styled.div`
  @media (min-width: 540px) {
    padding: 0 30px;
  }
  @media (min-width: 768px) {
    padding: 0 15px;
  }
  @media (min-width: 1024px) {
    padding: 0 53px;
  }
  @media (min-width: 1280px) {
    padding: 0 180px;
  }
  @media (min-width: 1440px) {
    padding: 0 380px;
  }
  @media (min-width: 1920px) {
    padding: 0 590px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const SlideImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
  transition: left 0.5s ease;
`;

const Title = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: ${typography.fontSize.md};
  font-weight: bold;
  color: ${colors.text_white};
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 15px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 24px;
  }
  @media (min-width: 1920px) {
    font-size: ${typography.fontSize.xl};
    line-height: 26px;
  }
`;

const BulletContainer = styled.div`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Bullet = styled.li<{ active: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${({ active }) => (active ? colors.dark_tangerine : '#ccc')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;

const ImageSlideShow: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % imageSlideShowData.length);
    }, 4000);

    return () => clearInterval(slideInterval);
  }, []);

  const handleBulletClick = (index: number) => {
    setCurrentSlide(index);
  };
  return (
    <ImageLayout>
      <ImageContainer>
        {imageSlideShowData.map((slide, index) => (
          <React.Fragment key={slide.id}>
            <SlideImage
              src={slide.imageSrc}
              alt={slide.altText}
              style={{ display: index === currentSlide ? 'block' : 'none' }}
            />
            {index === currentSlide && <Title>{slide.content}</Title>}
          </React.Fragment>
        ))}
        <BulletContainer>
          {imageSlideShowData.map((_, index) => (
            <Bullet key={index} active={index === currentSlide} onClick={() => handleBulletClick(index)} />
          ))}
        </BulletContainer>
      </ImageContainer>
    </ImageLayout>
  );
};

export default ImageSlideShow;
