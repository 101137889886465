import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, QuerySnapshot } from 'firebase/firestore';
import { LocationItem } from '@api/location.interface';
import { firestore } from '@database/firebase';
import { Toast } from '@utils/Toast';

const useFetchLocation = (): {
  locations: LocationItem[];
  isLoading: boolean;
  isError: boolean;
  setLocations: React.Dispatch<React.SetStateAction<LocationItem[]>>;
} => {
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchLocations = useCallback(async () => {
    try {
      const locationsCollection = collection(firestore, 'locations');
      const locationsSnapshot: QuerySnapshot = await getDocs(locationsCollection);
      const locationsData: LocationItem[] = locationsSnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as LocationItem;
      });
      setLocations(locationsData.sort((a: any, b: any) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.error('Error fetching locations:', error);
      setIsError(true);
      const toast = new Toast('Error fetching locations!');
      toast.showError();
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, []);

  return { locations, setLocations, isLoading, isError };
};

export default useFetchLocation;
