export const initialValue: LocationItem = {
  id: '',
  provinceOrCityCode: '',
  geometry: {
    location: {
      lat: 0,
      lng: 0,
    },
  },
  name: '',
  otherName: '',
  description: '',
  country: '',
  city: '',
  district: '',
  commune: '',
  color: '',
  constructionDate: '',
  constructionDateSala: '',
  decorDate: '',
  ancientPainting: false,
  lastVisit: '',
  archives: '',
  khmerRougePeriod: '',
  restoration: '',
  exterior: '',
  interior: '',
  bay: '',
  particularity: '',
  audioText: '',
  audioFile: '',
  coverImage: '',
  decorText: '',
  painting: '',
  painting1: '',
  painting2: '',
  painting3: '',
  painting4: '',
  titlePainting: '',
  titlePainting1: '',
  titlePainting2: '',
  titlePainting3: '',
  titlePainting4: '',
  sanctuaryImageSide1: '',
  sanctuaryImageSide2: '',
  sanctuaryImageSide3: '',
  sanctuaryImageSide4: '',
  titleSanctuary1: '',
  titleSanctuary2: '',
  titleSanctuary3: '',
  titleSanctuary4: '',
  sanctuaryDrawing: '',
  // likedLocationCount: 0,
  locales: {
    fr: {
      provinceOrCityCode: '',
      name: '',
      otherName: '',
      description: '',
      color: '',
      country: '',
      city: '',
      district: '',
      commune: '',
      lastVisit: '',
      constructionDate: '',
      constructionDateSala: '',
      decorDate: '',
      archives: '',
      khmerRougePeriod: '',
      restoration: '',
      exterior: '',
      bay: '',
      interior: '',
      particularity: '',
      audioText: '',
      decorText: '',
      titlePainting: '',
      titlePainting1: '',
      titlePainting2: '',
      titlePainting3: '',
      titlePainting4: '',
      titleSanctuary1: '',
      titleSanctuary2: '',
      titleSanctuary3: '',
      titleSanctuary4: '',
    },
    en: {
      provinceOrCityCode: '',
      name: '',
      otherName: '',
      description: '',
      color: '',
      country: '',
      city: '',
      district: '',
      commune: '',
      lastVisit: '',
      constructionDate: '',
      constructionDateSala: '',
      decorDate: '',
      archives: '',
      khmerRougePeriod: '',
      restoration: '',
      exterior: '',
      bay: '',
      interior: '',
      particularity: '',
      audioText: '',
      decorText: '',
      titlePainting: '',
      titlePainting1: '',
      titlePainting2: '',
      titlePainting3: '',
      titlePainting4: '',
      titleSanctuary1: '',
      titleSanctuary2: '',
      titleSanctuary3: '',
      titleSanctuary4: '',
    },
    kh: {
      provinceOrCityCode: '',
      name: '',
      otherName: '',
      description: '',
      color: '',
      country: '',
      city: '',
      district: '',
      commune: '',
      lastVisit: '',
      constructionDate: '',
      constructionDateSala: '',
      decorDate: '',
      archives: '',
      khmerRougePeriod: '',
      restoration: '',
      exterior: '',
      bay: '',
      interior: '',
      particularity: '',
      audioText: '',
      decorText: '',
      titlePainting: '',
      titlePainting1: '',
      titlePainting2: '',
      titlePainting3: '',
      titlePainting4: '',
      titleSanctuary1: '',
      titleSanctuary2: '',
      titleSanctuary3: '',
      titleSanctuary4: '',
    },
  },
  images: {},
};

export type S3object = {
  bucket: string;
  key: string;
  region: string;
};

export interface LocationLocale {
  fr: Translation;
  en: Translation;
  kh: Translation;
}

export type Translation = {
  provinceOrCityCode: string;
  name: string;
  otherName: string;
  description: string;
  color: string;
  country: string;
  city: string;
  district: string;
  commune: string;
  lastVisit: string;
  constructionDate: string;
  constructionDateSala: string;
  decorDate: string;
  archives: string;
  khmerRougePeriod: string;
  restoration: string;
  exterior: string;
  bay: string;
  interior: string;
  particularity: string;
  audioText: string;
  decorText: string;
  titlePainting: string;
  titlePainting1: string;
  titlePainting2: string;
  titlePainting3: string;
  titlePainting4: string;
  titleSanctuary1: string;
  titleSanctuary2: string;
  titleSanctuary3: string;
  titleSanctuary4: string;
};

export interface LocationItem {
  id: string;
  provinceOrCityCode: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  color: string;
  name: string;
  otherName: string;
  description: string;
  country: string;
  city: string;
  district: string;
  commune: string;
  lastVisit: string;
  constructionDate: string;
  constructionDateSala: string;
  decorDate: string;
  ancientPainting: boolean;
  archives: string;
  khmerRougePeriod: string;
  restoration: string;
  exterior: string;
  interior: string;
  bay: string;
  particularity: string;
  audioText: string;
  audioFile: string;
  coverImage: string;
  decorText: string;
  painting: string;
  painting1: string;
  painting2: string;
  painting3: string;
  painting4: string;
  titlePainting: string;
  titlePainting1: string;
  titlePainting2: string;
  titlePainting3: string;
  titlePainting4: string;
  sanctuaryImageSide1: string;
  sanctuaryImageSide2: string;
  sanctuaryImageSide3: string;
  sanctuaryImageSide4: string;
  titleSanctuary1: string;
  titleSanctuary2: string;
  titleSanctuary3: string;
  titleSanctuary4: string;
  sanctuaryDrawing: string;
  // likedLocationCount: number;
  locales: LocationLocale;
  images: Record<string, string>;
}

export interface CreateUpdateLocationDTO {
  provinceOrCityCode?: string;
  geometry?: {
    location: {
      lat: number;
      lng: number;
    };
  };
  color?: string;
  name?: string;
  otherName?: string;
  description?: string;
  country?: string;
  city?: string;
  district?: string;
  commune?: string;
  lastVisit?: string;
  constructionDate?: string;
  archives?: string;
  khmerRougePeriod?: string;
  restoration?: string;
  exterior?: string;
  interior?: string;
  bay?: string;
  particularity?: string;
  audioText?: string;
  coverImage?: S3object | null;
  // likedLocationCount: number;
  locales: LocationLocale;
}
