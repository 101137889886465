import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

export enum UserRole {
  ADMIN = 'ADMIN',
  PREMIM = 'PREMIUM',
  USER = 'USER',
  GUEST = 'GUEST',
}
export interface User {
  id: number;
  role: UserRole;
  userName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  hashedRefreshToken: string;
  bio: string;
  country: string;
  resetPasswordToken: string;
  isEmailConfirmed: boolean;
  createdAt: Date;
  updatedAt: Date;
  // locations: LocationItem[];
  // likedLocations: LocationItem[];
  // avatar?: UploadAvatar;
  // coverImage?: UploadLocationCoverImage;
  // sanctuaryImageSide1?: UploadLocationCoverImage;
  // sanctuaryImageSide2: UploadSanctuaryImageSide2;
  // painting: Painting[];
  // sanctuaryDrawing: SanctuaryDrawing;
}

export interface AuthTokens {
  accessToken: string | null;
  refreshToken: string | null;
}

export interface AuthResponse {
  isAuthenticated: boolean;
  authTokens: AuthTokens;
  user: User | null;
}

const initialState: AuthResponse = {
  isAuthenticated: false,
  user: null,
  authTokens: {
    accessToken: null,
    refreshToken: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthResponse>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.authTokens.accessToken = action.payload.authTokens.accessToken;
      state.authTokens.refreshToken = action.payload.authTokens.refreshToken;
    },
    login: (state, action: PayloadAction<AuthResponse>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.authTokens.accessToken = action.payload.authTokens.accessToken;
      state.authTokens.refreshToken = action.payload.authTokens.refreshToken;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.authTokens.accessToken = null;
      state.authTokens.refreshToken = null;
    },
  },
});

export const { login, logout, setAuthState } = authSlice.actions;
export const isAuth = (state: RootState) => state.auth.isAuthenticated;
export const isCurrentUser = (state: RootState) => state.auth.user;
export default authSlice.reducer;
