import useWindowSize from '@hooks/useWindowSize';
import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const AdminHeader: React.FC = () => {
  const size = useWindowSize();
  return (
    <div>
      {size.width && size.width >= 1024 && <HeaderDesktop />}
      {size.width && size.width < 1024 && <HeaderMobile />}
    </div>
  );
};

export default AdminHeader;
