import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import sizes from '@styles/common/sizes';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const TextBlue = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-family: ${typography.fonts.body};
  font-size: ${sizes.sm};
  color: ${colors.blue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  @media ${devices.tablet} {
    font-size: ${sizes.md};
  }
`;
