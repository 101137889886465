import colors from '@styles/common/colors';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ExternalLinks from '@components/ExternalLinks';
import typography from '@styles/common/typography';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import Logo from '@assets/logos/LogoNoTitle.png';
import FooterLinks from './FooterLinks';

const FooterLayout = styled.div`
  background: #fcf9f7;
  padding: 15px 15px;

  @media (min-width: 320px) {
    padding: 15px 30px;
  }
  @media (min-width: 768px) {
    padding: 15px 15px;
  }
  @media (min-width: 1024px) {
    padding: 30px 53px;
  }
  @media (min-width: 1280px) {
    padding: 30px 180px;
  }
  @media (min-width: 1440px) {
    padding: 30px 380px;
  }
  @media (min-width: 1920px) {
    padding: 30px 590px;
  }
`;

const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
`;

const IconWrapper = styled.div`
  &:hover svg {
    fill: ${colors.dark_tangerine};
  }
`;

const CopyRight = styled.span`
  font-size: ${typography.fontSize.sm};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
`;

const LinksLayout = styled.div`
  display: flex;
  gap: 12px;
`;

const LogoSubTitle = styled.span`
  font-size: ${typography.fontSize.lg};
  color: ${colors.text_grey};
  letter-spacing: 1px;
  line-height: 24px;
`;

const SectionTitle = styled.span`
  font-size: ${typography.fontSize.lg};
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 26px;

  @media (min-width: 768px) {
    margin-top: 4px;
  }
  @media (min-width: 1024px) {
    margin-top: 16px;
  }
`;

const BorderLine = styled.div`
  border: 0.1px solid ${colors.secondary_color.variant_two};
  margin: 30px 0;
`;

const InnerLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LogoLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;

  @media (min-width: 540px) {
    width: 60%;
  }
  @media (min-width: 1280px) {
    width: 25%;
  }
`;

const TermsLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
`;

const TermsLink = styled.a`
  font-size: ${typography.fontSize.sm};
  color: ${colors.blue};
  letter-spacing: 0.5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <FooterLayout>
      <InnerLayout>
        <LogoLayout>
          <img src={Logo} width={40} height={40} alt="Website Logo" />
          <LogoSubTitle>
            Unearth the Mysteries of Cambodia&rsquo;s Ancient Buddhist Monastery from 1860 to 1975.
          </LogoSubTitle>
        </LogoLayout>
        <SectionLayout>
          <SectionTitle>About La Pagode Khmère</SectionTitle>
          <FooterLinks />
        </SectionLayout>
        <SectionLayout>
          <SectionTitle>Follow us</SectionTitle>
          <LinksLayout>
            <ExternalLinks href="https://www.facebook.com/lapagodekhmere/">
              <IconWrapper>
                <FaFacebook size={25} color={colors.secondary_color.regular} aria-label="Facebook page" />
              </IconWrapper>
            </ExternalLinks>
            <ExternalLinks href="https://www.linkedin.com/company/la-pagode-khmere/">
              <IconWrapper>
                <AiFillLinkedin size={25} color={colors.secondary_color.regular} aria-label="Linkedin page" />
              </IconWrapper>
            </ExternalLinks>
            <ExternalLinks href="https://t.me/lapagodekhmere">
              <IconWrapper>
                <FaTelegram size={25} color={colors.secondary_color.regular} aria-label="Telegram channel" />
              </IconWrapper>
            </ExternalLinks>
            <ExternalLinks href="https://www.instagram.com/lapagodekhmere/">
              <IconWrapper>
                <AiFillInstagram size={25} color={colors.secondary_color.regular} aria-label="Instagram page" />
              </IconWrapper>
            </ExternalLinks>
          </LinksLayout>
        </SectionLayout>
      </InnerLayout>
      <BorderLine />
      <TermsLayout>
        <TermsLink href={'/privacy'}>{t('header.privacy')}</TermsLink>
        <TermsLink href={'/terms'}>{t('header.terms')}</TermsLink>
      </TermsLayout>
      <CopyRight>
        &copy; {year} {t('footer.company')} - La Pagode Khmère
      </CopyRight>
    </FooterLayout>
  );
};

export default Footer;
