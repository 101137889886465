// import React, { useEffect, useState } from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor as DraftEditor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { SubmitButton } from '@components/Button/SubmitButton';
// import styled from 'styled-components';
// import shadows from '@styles/common/shadows';
// import colors from '@styles/common/colors';

// const EditorContainer = styled.div`
//   margin: 20px;
//   //   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const toolbarStyles = `
//   .custom-toolbar {
//     display: flex;
//     justify-content: center;
//     box-shadow: ${shadows.base};
//   }
// `;

// const textContentStyles = `
//   .custom-text-content {
//     padding: 16px;
//   }
// `;

// const RichTextEditor: React.FC = () => {
//   const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

//   const onEditorStateChange = (newEditorState: EditorState) => {
//     setEditorState(newEditorState);
//     // const contentState = convertToRaw(newEditorState.getCurrentContent());
//     // console.log(contentState);
//   };

//   useEffect(() => {
//     const styleElement = document.createElement('style');
//     styleElement.innerHTML = toolbarStyles;
//     document.head.appendChild(styleElement);
//   }, []);

//   useEffect(() => {
//     const styleElement = document.createElement('style');
//     styleElement.innerHTML = textContentStyles;
//     document.head.appendChild(styleElement);
//   }, []);

//   return (
//     <EditorContainer>
//       <DraftEditor
//         toolbarClassName="custom-toolbar"
//         editorClassName="custom-text-content"
//         editorState={editorState}
//         onEditorStateChange={onEditorStateChange}
//       />
//       <SubmitButton text={'Submit'} aria-labelledby={'sign in'} onClick={() => console.log('hello')} />
//     </EditorContainer>
//   );
// };

// export default RichTextEditor;

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import React, { useCallback, useEffect, useState } from 'react';
import shadows from '@styles/common/shadows';
import ImageResize from 'quill-image-resize';
import VideoResize from 'quill-blot-formatter';
import { Article, articleValue } from '@api/article.interface';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '@database/firebase';
import { nanoid } from 'nanoid';
import useFetchArticles from '@hooks/useFetchArticles';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import UploadArticleImage from '@components/Forms/UploadArticleImage';
import placeholderImage from '@assets/images/image-placeholder.png';
import ArticleFormInput from '@components/Forms/ArticleFormInput';
import { Toast } from '@utils/Toast';
import colors from '@styles/common/colors';
import LoaderButton from '@components/LoaderButton';
import Header from '@components/Header/Header';
import {
  MainLayout,
  RichTextEditorLayout,
  ArticleButtonLayout,
  ArticleButton,
  ButtonText,
  ArticlesLayout,
  ArticleImage,
  SelectedImageContainer,
  SectionTitle,
  Label,
  PreviewAudioLayout,
  AudioLabel,
} from './RichTextEditor.style';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import UploadArticleAudio from '@components/Forms/UploadArticleAudio';
import ReactAudioPlayer from 'react-audio-player';
import { Input } from '@components/Forms/styles/form.style';
import { IoVolumeMuteOutline } from 'react-icons/io5';

const RichTextEditor: React.FC = () => {
  const [formData, setFormData] = useState<Article>(articleValue);
  const { articles, newlyUploadedArticle, setNewlyUploadedArticle, isLoading } = useFetchArticles();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedAudio, setSelectedAudio] = useState<string | null>(null);
  const [selectedLocaleAudio, setSelectedLocaleAudio] = useState<{
    en: string | null;
    fr: string | null;
    kh: string | null;
  }>({
    en: null,
    fr: null,
    kh: null,
  });
  const [, setAudioFile] = useState<File | null>(null);
  const language = useAppSelector(currentLanguage);

  Quill.register('modules/imageResize', ImageResize);
  Quill.register('modules/blotFormatter', VideoResize);

  const modules = {
    toolbar: [
      [{ font: [] }, { header: [1, 2, 3, 4, 5] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block', 'direction'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image', 'video'],
    ],
    imageResize: {},
    blotFormatter: {},
  };

  const resetFormFields = () => {
    setFormData(articleValue);
    setSelectedImage(null);
    setIsFormValid(false);
  };

  useEffect(() => {
    // Reset/Clear the form when newlyUploadedArticle changes
    if (newlyUploadedArticle) {
      resetFormFields();
    }
  }, [newlyUploadedArticle]);

  const handleSubmit = async () => {
    const articleData = {
      id: formData.id || nanoid(),
      title: formData.title,
      slug: formData.slug,
      description: formData.description,
      vrlink: formData.vrlink,
      publishedDate: formData.publishedDate,
      modifiedDate: formData.modifiedDate,
      categories: formData.categories,
      image: formData.image,
      audio: formData.audio,
      content: formData.content,
      locales: formData.locales,
    };

    try {
      // Save the content to Firebase
      const artcileCollection = collection(firestore, 'articles');
      await addDoc(artcileCollection, articleData);

      // After successfully adding the new article to Firebase, Set the newly uploaded article in state. It means that the data will display immediately
      setNewlyUploadedArticle({ ...articleData });

      const toast = new Toast('You successfully added your data!');
      toast.showSuccess();

      // Clear the form fields
      resetFormFields();
    } catch (err) {
      console.log(err);
      const toast = new Toast('An error occurred while adding your data!');
      toast.showError();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    checkFormValidity({
      ...formData,
      [name]: value,
      content: formData.content,
      title: formData.title,
      description: formData.description,
      image: formData.image,
    });
  };

  const checkFormValidity = (formData: Article) => {
    const isFormValid = formData.title && formData.description && formData.image && formData.content;

    setIsFormValid(isFormValid as any);
  };

  const handleContentChange = (content: string) => {
    setFormData({
      ...formData,
      content,
    });

    checkFormValidity({ ...formData, content });
  };

  const handleFileUpload = (fieldName: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return; // if file is null, exit early

    const fileExtension = file.name.split('.').pop();
    const fileName = file.name.replace(/\.[^/.]+$/, '');
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString().replace(/\//g, '-')}-${currentDate
      .toLocaleTimeString()
      .replace(/:/g, ':')}`;

    try {
      // Store files in images folder
      const imageKey = ref(storage, `images/${nanoid()}-${formattedDate}-${fileName}.${fileExtension}`);
      // Upload file
      const snapshot = await uploadBytes(imageKey, file);
      // Get file url
      const url = await getDownloadURL(snapshot.ref);

      // Save the image URL to Firestore Database
      if (formData.id) {
        // Check if articles.id is defined
        const articleDocRef = doc(firestore, 'articles', formData.id);
        await updateDoc(articleDocRef, {
          image: url,
        });
      }

      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: url,
      }));

      // Delete the old image if it exists
      if (formData.image) {
        const oldArticleImageRef = ref(storage, formData.image);
        await deleteObject(oldArticleImageRef);
      }

      setSelectedImage(url); // Set the selected image
      checkFormValidity({ ...formData, [fieldName]: url });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAudioUpload = (fieldName: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return; // if file is null, exit early

    const fileExtension = file.name.split('.').pop();
    const fileName = file.name.replace(/\.[^/.]+$/, '');
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString().replace(/\//g, '-')}-${currentDate
      .toLocaleTimeString()
      .replace(/:/g, ':')}`;

    try {
      // Store files in images folder
      const audioKey = ref(storage, `audio/${nanoid()}-${formattedDate}-${fileName}.${fileExtension}`);
      // Upload file
      const snapshot = await uploadBytes(audioKey, file);
      // Get file url
      const url = await getDownloadURL(snapshot.ref);

      // Save the image URL to Firestore Database
      if (formData.id) {
        // Check if articles.id is defined
        const articleDocRef = doc(firestore, 'articles', formData.id);
        await updateDoc(articleDocRef, {
          audio: url,
        });
      }

      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: url,
      }));

      // Delete the old image if it exists
      if (formData.audio) {
        const oldArticleAudioRef = ref(storage, formData.audio);
        await deleteObject(oldArticleAudioRef);
      }

      setSelectedAudio(url); // Set the selected image
      checkFormValidity({ ...formData, [fieldName]: url });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocaleAudioUpload = async (
    fieldName: string,
    locale: keyof typeof formData.locales,
    audioFile: File | null
  ) => {
    try {
      if (!audioFile) return;

      const fileExtension = audioFile.name.split('.').pop();
      const fileName = audioFile.name.replace(/\.[^/.]+$/, '');
      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString().replace(/\//g, '-')}-${currentDate
        .toLocaleTimeString()
        .replace(/:/g, ':')}`;

      // Store files in audio folder
      const audioKey = ref(storage, `audio/${nanoid()}-${formattedDate}-${fileName}.${fileExtension}`);
      // Upload file
      const snapshot = await uploadBytes(audioKey, audioFile);
      // Get file url
      const url = await getDownloadURL(snapshot.ref);

      // Save the audio URL to Firestore Database
      if (formData.id) {
        // Check if articles.id is defined
        const articleDocRef = doc(firestore, 'articles', formData.id);
        const localeFieldName = `locales.${locale}.audio`;

        await updateDoc(articleDocRef, {
          [fieldName]: url,
          [localeFieldName]: url,
        });
      }

      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: url,
        locales: {
          ...prevState.locales,
          [locale]: {
            ...prevState.locales[locale],
            audio: url,
          },
        },
      }));

      // Delete the old audio if it exists
      if ((formData.locales as any)[locale].audio) {
        const oldArticleAudioRef = ref(storage, (formData.locales as any)[locale].audio);
        await deleteObject(oldArticleAudioRef);
      }

      // Set the selected audio for the specific language
      setSelectedLocaleAudio((prevAudio) => ({
        ...prevAudio,
        [locale]: url,
      }));
      checkFormValidity({ ...formData, [fieldName]: url });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocaleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, locale: keyof typeof formData.locales, field: string, value: string) => {
      setFormData((prevValues) => ({
        ...prevValues,
        locales: {
          ...prevValues.locales,
          [locale]: {
            ...prevValues.locales[locale],
            [field]: value,
          },
        },
      }));
    },
    [setFormData]
  );

  const handleLocaleContentChange = (locale: keyof typeof formData.locales, content: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      locales: {
        ...prevValues.locales,
        [locale]: {
          ...prevValues.locales[locale],
          content,
        },
      },
    }));
  };

  return (
    <>
      <Header />
      <MainLayout>
        <RichTextEditorLayout>
          <ArticleFormInput
            key="title"
            label={'Title'}
            name="title"
            value={formData.title ?? ''}
            onChange={handleInputChange}
          />
          <ArticleFormInput
            key="slug"
            label={'Slug'}
            name="slug"
            value={formData.slug ?? ''}
            onChange={handleInputChange}
          />
          <ArticleFormInput
            key="description"
            label={'Description'}
            name="description"
            value={formData.description ?? ''}
            onChange={handleInputChange}
          />
          <ArticleFormInput
            key="vrlink"
            label={'VR Link'}
            name="vrlink"
            value={formData.vrlink ?? ''}
            onChange={handleInputChange}
          />
          <UploadArticleImage
            fieldName="image"
            label={'Add an Article Image'}
            accept="image/*"
            handleFileUpload={handleFileUpload}
            article={formData}
          />
          <UploadArticleAudio
            fieldName="audio"
            label={'Add an Article Audio'}
            accept="audio/*"
            handleFileUpload={handleAudioUpload}
            article={formData}
          />
          <ReactQuill
            theme="snow"
            value={formData.content}
            onChange={handleContentChange}
            modules={modules}
            placeholder="Enter Your text here..."
            style={{ boxShadow: shadows.base, margin: '8px 0', background: colors.white }}
          />

          {/* English Data */}
          <SectionTitle>Insert English data</SectionTitle>
          <ArticleFormInput
            key="title"
            label={'Title'}
            name="title"
            value={formData.locales.en.title ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'title', e.target.value)}
          />
          <ArticleFormInput
            key="slug"
            label={'Slug'}
            name="slug"
            value={formData.locales.en.slug ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'slug', e.target.value)}
          />
          <ArticleFormInput
            key="description"
            label={'Description'}
            name="description"
            value={formData.locales.en.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'description', e.target.value)}
          />
          <ArticleFormInput
            key="vrlink"
            label={'VR Link'}
            name="vrlink"
            value={formData.locales.en.vrlink ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'description', e.target.value)}
          />
          <Label>Add an English Article Audio</Label>
          <Input
            type="file"
            accept="audio/*"
            onChange={(e) => {
              const file = e.target.files?.[0] || null;
              setAudioFile(file); // Set the audioFile state
              handleLocaleAudioUpload('audio', 'en', file);
            }}
          />
          <ReactQuill
            theme="snow"
            value={formData.locales.en.content}
            onChange={(content) => handleLocaleContentChange('en', content)}
            modules={modules}
            placeholder="Enter Your text here..."
            style={{ boxShadow: shadows.base, margin: '8px 0', background: colors.white }}
          />

          {/* French Data */}
          <SectionTitle>Insert French data</SectionTitle>
          <ArticleFormInput
            key="title"
            label={'Title'}
            name="title"
            value={formData.locales.fr.title ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'title', e.target.value)}
          />
          <ArticleFormInput
            key="slug"
            label={'Slug'}
            name="slug"
            value={formData.locales.fr.slug ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'slug', e.target.value)}
          />
          <ArticleFormInput
            key="description"
            label={'Description'}
            name="description"
            value={formData.locales.fr.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'description', e.target.value)}
          />
          <ArticleFormInput
            key="vrlink"
            label={'VR Link'}
            name="vrlink"
            value={formData.locales.fr.vrlink ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'description', e.target.value)}
          />
          <Label>Add a French Article Audio</Label>
          <Input
            type="file"
            accept="audio/*"
            onChange={(e) => {
              const file = e.target.files?.[0] || null;
              setAudioFile(file); // Set the audioFile state
              handleLocaleAudioUpload('audio', 'fr', file);
            }}
          />
          <ReactQuill
            theme="snow"
            value={formData.locales.fr.content}
            onChange={(content) => handleLocaleContentChange('fr', content)}
            modules={modules}
            placeholder="Enter Your text here..."
            style={{ boxShadow: shadows.base, margin: '8px 0', background: colors.white }}
          />

          {/* Khmer Data */}
          <SectionTitle>Insert Khmer data</SectionTitle>
          <ArticleFormInput
            key="title"
            label={'Title'}
            name="title"
            value={formData.locales.kh.title ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'title', e.target.value)}
          />
          <ArticleFormInput
            key="slug"
            label={'Slug'}
            name="slug"
            value={formData.locales.kh.slug ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'slug', e.target.value)}
          />
          <ArticleFormInput
            key="description"
            label={'Description'}
            name="description"
            value={formData.locales.kh.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'description', e.target.value)}
          />
          <ArticleFormInput
            key="vrlink"
            label={'VR Link'}
            name="vrlink"
            value={formData.locales.kh.vrlink ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'description', e.target.value)}
          />
          <Label>Add a Khmer Article Audio</Label>
          <Input
            type="file"
            accept="audio/*"
            onChange={(e) => {
              const file = e.target.files?.[0] || null;
              setAudioFile(file); // Set the audioFile state
              handleLocaleAudioUpload('audio', 'kh', file);
            }}
          />
          <ReactQuill
            theme="snow"
            value={formData.locales.kh.content}
            onChange={(content) => handleLocaleContentChange('kh', content)}
            modules={modules}
            placeholder="Enter Your text here..."
            style={{ boxShadow: shadows.base, margin: '8px 0', background: colors.white }}
          />
          <ArticleButtonLayout>
            <ArticleButton onClick={isFormValid ? handleSubmit : undefined} isActive={isFormValid}>
              {isLoading ? <LoaderButton /> : null}
              <ButtonText isActive={isFormValid}>Save</ButtonText>
            </ArticleButton>
          </ArticleButtonLayout>
        </RichTextEditorLayout>
        <ArticlesLayout>
          {selectedImage ? (
            <SelectedImageContainer>
              <img src={selectedImage} alt="Selected" style={{ maxWidth: '300px', maxHeight: '100%' }} />
            </SelectedImageContainer>
          ) : (
            <SelectedImageContainer>
              <img src={placeholderImage} alt="Placeholder" style={{ maxWidth: '300px', maxHeight: '100%' }} />
            </SelectedImageContainer>
          )}
          <PreviewAudioLayout>
            {selectedAudio ? <ReactAudioPlayer src={selectedAudio} controls /> : null}
            <AudioLabel>Audio for English language</AudioLabel>
            {selectedLocaleAudio.en ? (
              <ReactAudioPlayer src={selectedLocaleAudio.en} controls />
            ) : (
              <IoVolumeMuteOutline size={35} color={colors.secondary_color.regular} />
            )}
            <AudioLabel>Audio for French language</AudioLabel>
            {selectedLocaleAudio.fr ? (
              <ReactAudioPlayer src={selectedLocaleAudio.fr} controls />
            ) : (
              <IoVolumeMuteOutline size={35} color={colors.secondary_color.regular} />
            )}
            <AudioLabel>Audio for Khmer language</AudioLabel>
            {selectedLocaleAudio.kh ? (
              <ReactAudioPlayer src={selectedLocaleAudio.kh} controls />
            ) : (
              <IoVolumeMuteOutline size={35} color={colors.secondary_color.regular} />
            )}
          </PreviewAudioLayout>

          {articles.map((article) => (
            <div key={article.id}>
              <h2>{(article.locales as any)[language].title || article.title}</h2>
              <p>{(article.locales as any)[language].description || article.description}</p>
              {article.image ? (
                <ArticleImage src={article.image} alt={article.title} />
              ) : (
                <img src={placeholderImage} alt={article.title} height={200} width={250} />
              )}
              <p>
                {article.audio ? (
                  <ReactAudioPlayer src={(article.locales as any)[language].audio || article.audio} controls />
                ) : null}
              </p>

              <div
                dangerouslySetInnerHTML={{ __html: (article.locales as any)[language].content || article?.content }}
              />
            </div>
          ))}
        </ArticlesLayout>
      </MainLayout>
    </>
  );
};

export default RichTextEditor;
