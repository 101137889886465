import HeaderLinkMobile from '@components/Header/components/HeaderLinkMobile';
import LanguageDropdown from '@components/Language/LanguageDropdown';
import ProfileIcon from '@components/Profile/ProfileIcon';
import { useAuth } from '@context/AuthProvider';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
// import StripePremiumLink from '@stripe/stripePremiumLink';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const MenuDrawer: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(drawerRef, handleCloseMenu);

  return (
    <div ref={drawerRef}>
      <Overlay showMenu={showMenu} onClick={handleCloseMenu} />
      <BurgerMenu>
        <FiMenu aria-label="Menu Burger" size={25} color={colors.dark_grey} onClick={handleMenuClick} />
      </BurgerMenu>
      <Menu showMenu={showMenu}>
        <ItemsLayout>
          {user ? (
            <ProfileIcon />
          ) : (
            <ButtonLayout>
              <Text onClick={() => navigate('/signin')}>{t('button.signin')}</Text>
            </ButtonLayout>
          )}
          <CloseIconLayout>
            <MdOutlineClose aria-label="Close Icon" size={25} color={colors.dark_grey} onClick={handleCloseMenu} />
          </CloseIconLayout>
        </ItemsLayout>
        <LanguageLayout>
          <LanguageDropdown />
        </LanguageLayout>
        <BorderLine />
        <MenuInnerLayout>
          {/* <StripePremiumLink /> */}
          <div onClick={handleCloseMenu}>
            <HeaderLinkMobile />
          </div>
        </MenuInnerLayout>
      </Menu>
    </div>
  );
};

export default MenuDrawer;

const LanguageLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 30px 15px 30px;
`;

const Text = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.white};
  letter-spacing: 1px;

  @media (min-width: 540px) {
    font-size: 16px;
  }
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background: ${colors.dark_tangerine};
  border-radius: 6px;
  padding: 6px 14px;
  cursor: pointer;

  &:hover {
    background: ${colors.orange};
  }
`;

const BurgerMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BorderLine = styled.div`
  border: 0.1px solid ${colors.secondary_color.variant_two};
  margin-bottom: 12px;
`;

const ItemsLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
`;

const Menu = styled.div<{ showMenu: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ showMenu }) => (showMenu ? 0 : '-100%')};
  width: 100%;
  max-width: 330px;
  height: 100%;
  background: ${colors.white};
  transition: left 0.3s ease-in-out;
  box-shadow: ${shadows.base};
  z-index: 9999;
`;

const MenuInnerLayout = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 80px;
`;

const Overlay = styled.div<{ showMenu: boolean }>`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 999;
  pointer-events: ${({ showMenu }) => (showMenu ? 'auto' : 'none')};
`;
const CloseIconLayout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
