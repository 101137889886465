import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '@hooks/useWindowSize';
import { LuChevronLeftCircle, LuChevronRightCircle } from 'react-icons/lu';

interface ImageData {
  image: string;
  title: string;
  content: string;
}

interface ImageScrollerProps {
  images: ImageData[];
}

const ScrollerContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  background: ${colors.white};
  padding: 60px 0 30px 0;
  cursor: pointer;
`;

const ImageWrapper = styled.a`
  display: flex;
  flex-wrap: nowrap;
`;

const Image = styled.img`
  width: 220px;
  height: 150px;
  border-radius: 12px;

  &:hover {
    opacity: 0.9;
  }

  @media (min-width: 320px) {
    width: 280px;
    height: 200px;
  }

  @media (min-width: 540px) {
    width: 450px;
    height: 320px;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  font-size: ${typography.fontSize.xs};
  letter-spacing: 0.5px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.md};
  }
`;

const IconPrevStyled = styled.div`
  position: absolute;
  top: 180px;
  left: -25px;
  z-index: 1;
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    fill: ${colors.white};
    stroke: ${colors.dark_grey};
    stroke-width: 0.4px;
    transition: fill 0.5s, stroke 0.5s;
  }

  &:hover svg {
    fill: ${colors.dark_tangerine};
    stroke: ${colors.white};
  }
`;

const IconNextStyled = styled.div`
  position: absolute;
  top: 180px;
  right: -15px;
  z-index: 1;
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    fill: ${colors.white};
    stroke: ${colors.dark_grey};
    stroke-width: 0.4px;
    transition: fill 0.5s, stroke 0.5s;
  }

  &:hover svg {
    fill: ${colors.dark_tangerine};
    stroke: ${colors.white};
  }
`;

const IconsLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin-right: 10px;
`;

const ImageScrollerContainer = styled.div`
  position: relative;
`;

const ImageScroller: React.FC<ImageScrollerProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [hideRightIcon, setHideRightIcon] = useState(false);
  const size = useWindowSize();

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setClickCount(0); // Reset click count when going to previous slide
    setHideRightIcon(false); // Show the chevron right icon when going to previous slide
  };

  const nextSlide = () => {
    setClickCount((prevCount) => prevCount + 1);
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  };

  useEffect(() => {
    if (size.width && size.width >= 1024 && clickCount >= 5) {
      // For 1024px or lower width, hide the next icon after five clicks
      setHideRightIcon(true);
    } else {
      if (size.width && size.width >= 1920 && clickCount >= 3) {
        // For 1024px or lower width, hide the next icon after three clicks
        setHideRightIcon(true);
      }
    }
  }, [clickCount]);

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === images.length - 1;

  return (
    <>
      <ImageScrollerContainer>
        {size.width && size.width <= 768 ? (
          <IconsLayout>
            <IconPrevStyled style={{ display: 'none' }}></IconPrevStyled>
            <IconNextStyled style={{ display: 'none' }}></IconNextStyled>
          </IconsLayout>
        ) : (
          <IconsLayout>
            <IconPrevStyled onClick={prevSlide} style={{ display: isFirstSlide ? 'none' : 'block' }}>
              <LuChevronLeftCircle size={35} aria-label="Chevron Left Circle" />
            </IconPrevStyled>

            <IconNextStyled onClick={nextSlide} style={{ display: isLastSlide || hideRightIcon ? 'none' : 'block' }}>
              <LuChevronRightCircle size={35} aria-label="Chevron Right Circle" />
            </IconNextStyled>
          </IconsLayout>
        )}
      </ImageScrollerContainer>
      <ScrollerContainer>
        <ImageWrapper
          href="/search"
          style={{ transform: `translateX(-${currentIndex * 100}%)`, transition: 'transform 0.5s ease-in-out' }}
        >
          {images.map((image, index) => (
            <ImageContainer key={index}>
              <Image src={image.image} alt={image.title} />
              <Title>{image.content}</Title>
            </ImageContainer>
          ))}
        </ImageWrapper>
      </ScrollerContainer>
    </>
  );
};

export default ImageScroller;
