import { useVerifyEmailMutation } from '@api/auth.api';
import OtpInput from '@components/Input/OtpInput';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import { Toast } from '@utils/Toast';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GiMailbox } from 'react-icons/gi';
import FormRequiredInput from '@components/Forms/FormRequiredInput';
import { useTranslation } from 'react-i18next';

const VerifyEmail: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyMutation] = useVerifyEmailMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUserEmail = localStorage.getItem('rememberedEmail');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await verifyMutation({ email, verificationCode });
      console.log(response);
      navigate('/success');

      const toast = new Toast('Email successfully verified! 🚀');
      toast.showSuccess();
    } catch (error) {
      console.log(error);
      const toast = new Toast('An error occurred during verification. Please try again!');
      toast.showError();
    }
  };

  const handleVerificationCodeChange = (value: string) => {
    setVerificationCode(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <FormLayout>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <ContentLayout>
            <GiMailbox size={40} color={colors.dark_tangerine} />
            <Title>{t('verify-account.thanks')}</Title>
            <Content>
              {t('verify-account.title')} {''}
              <b>
                {currentUserEmail &&
                  currentUserEmail.substring(0, 3) +
                    '*'.repeat(currentUserEmail.lastIndexOf('@') - 3) +
                    currentUserEmail.substring(currentUserEmail.lastIndexOf('@'))}
              </b>
              {/* To display only the first three characters
                before the "@" symbol, replace the rest with asterisks before the "@" symbol, and display all the
                characters after the "@" symbol, */}
            </Content>
          </ContentLayout>
          <ul>
            <List>{t('verify-account.content-1')}</List>
            <List>
              {t('verify-account.content-2')} <u>contact@gautamatech.com</u>
            </List>
          </ul>
          <FormRequiredInput type="text" label={t('form.email')} name="email" value={email} onChange={handleChange} />
          <Label>{t('verify-account.verify-code')}</Label>
          <OtpInput value={verificationCode} valueLength={6} onChange={handleVerificationCodeChange} />
          <StyledButton>
            <ButtonText>{t('button.verify')}</ButtonText>
          </StyledButton>
        </Form>
      </FormContainer>
    </FormLayout>
  );
};

export default VerifyEmail;

const FormLayout = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 95%;
  max-width: 430px;
  background: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 6px;
  box-shadow: ${shadows.md};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const ContentLayout = styled.div`
  text-align: center;

  @media (min-width: 540px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: ${colors.text_grey};
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 8px;

  @media (min-width: 540px) {
    font-size: 20px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 22px;
`;

const Label = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin: 8px 0;
  text-align: left;

  @media (min-width: 540px) {
    padding: 0 16px;
  }
`;

const List = styled.li`
  font-size: 14px;
  color: ${colors.text_grey};
  line-height: 20px;
  letter-spacing: 0.5px;
`;

const StyledButton = styled.button`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background: ${colors.dark_tangerine};
  padding: 10px;
  border-radius: 4px;
  margin: 16px 0 8px 0;
  cursor: pointer;
  border: none;
`;

const ButtonText = styled.div`
  color: ${colors.white};
  font-size: ${typography.fontSize.md};
  letter-spacing: 1px;
`;
