import { collection, doc, addDoc, onSnapshot, DocumentSnapshot, DocumentData } from 'firebase/firestore';
import { firestore } from '@database/firebase';
import getStripe from '@stripe/stripe';

export async function createDonation5CheckoutSession(uid: string) {
  // Check if the user is signed in
  if (!uid) {
    // // Redirect to the login page
    window.location.href = '/proceed-to-signin';

    return;
  }

  // Create a new checkout session in the subcollection inside this users document
  const checkoutSessionData = {
    mode: 'payment', // add this line for one time payment
    price: process.env.REACT_APP_STRIPE_DONATION_5_PRICE_ID,
    success_url: `${window.location.origin}`,
    cancel_url: `${window.location.origin}`,
  };

  try {
    const checkoutSessionRef = await addDoc(
      collection(doc(firestore, 'users', uid), 'checkout_sessions'),
      checkoutSessionData
    );

    // Wait for the checkout session to get attached to the extension
    onSnapshot(checkoutSessionRef, async (snap: DocumentSnapshot<DocumentData>) => {
      const data = snap.data();

      // One time payment
      if (data && data.sessionId) {
        const stripe = await getStripe();

        stripe?.redirectToCheckout({ sessionId: data.sessionId });
      }
    });
  } catch (error) {
    console.error('Error creating checkout session:', error);
  }
}
