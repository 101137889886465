import React, { useEffect, useState } from 'react';
import { analytics } from '@database/firebase';
import { setAnalyticsCollectionEnabled } from '@firebase/analytics';
import styled from 'styled-components';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import { useTranslation } from 'react-i18next';
import { MdCookie, MdOutlineClose } from 'react-icons/md';
import Cookies from 'js-cookie';

const CookieConsentBannerSearch: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [, setCookiesAccepted] = useState<boolean>(false);
  const [, setCookiesDeclined] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleMenuClick = () => {
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleAccept = () => {
    Cookies.set('cookie-consent', 'accepted', { expires: 365 });
    setAnalyticsCollectionEnabled(analytics, true);
    setShowMenu(false);
    setCookiesAccepted(true);
  };

  const handleDecline = () => {
    Cookies.remove('cookie-consent');
    setAnalyticsCollectionEnabled(analytics, false);
    setShowMenu(false);
    setCookiesDeclined(true);
  };

  useEffect(() => {
    const cookieValue = Cookies.get('cookie-consent');
    if (cookieValue === 'accepted') {
      setCookiesAccepted(true);
    } else if (cookieValue === 'declined') {
      setCookiesDeclined(true);
    }
  }, []);

  return (
    <CookieLayout>
      {showMenu && (
        <CookieContainer>
          <CloseIconLayout>
            <MdOutlineClose size={20} color={colors.secondary_color.regular} onClick={handleCloseMenu} />
          </CloseIconLayout>
          <InnerLayout>
            <Content>
              {t('cookies.message')}{' '}
              <Link onClick={() => (window.location.href = 'privacy')}> {t('cookies.privacy')}</Link>
            </Content>
            <ButtonsLayout>
              <StyledAcceptButton onClick={handleAccept}>
                <AcceptButtonText>{t('button.accept')}</AcceptButtonText>
              </StyledAcceptButton>
              <StyledDeclineButton onClick={handleDecline}>
                <DeclineButtonText>{t('button.decline')}</DeclineButtonText>
              </StyledDeclineButton>
            </ButtonsLayout>
          </InnerLayout>
        </CookieContainer>
      )}
      {!showMenu && (
        <CookieIconLayout>
          <MdCookie size={40} color={'#d8ad6a'} onClick={handleMenuClick} />{' '}
        </CookieIconLayout>
      )}
    </CookieLayout>
  );
};

export default CookieConsentBannerSearch;

const CookieLayout = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 10px;
  left: 10px;
`;

const CookieContainer = styled.div`
  background: ${colors.white};
  border-radius: 6px;
  box-shadow: ${shadows.md};
  width: 100%;
  max-width: 350px;
  padding: 8px 16px 16px 16px;
`;
const CloseIconLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 12px;
`;

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 24px;
`;

const Link = styled.div`
  display: inline;
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
  }
`;

const ButtonsLayout = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const StyledAcceptButton = styled.button`
  width: 100%;
  background: ${colors.dark_tangerine};
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${colors.orange};
  }
`;

const AcceptButtonText = styled.div`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const StyledDeclineButton = styled.button`
  width: 100%;
  background: ${colors.light_gray};
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${colors.orange};
  }
`;

const DeclineButtonText = styled.div`
  color: ${colors.text_grey};
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const CookieIconLayout = styled.div`
  cursor: pointer;
`;
