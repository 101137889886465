import CookieConsentBannerSearch from '@components/Cookie/CookieConsentBannerSearch';
import Header from '@components/Header/Header';
import Seo from '@components/SEO/Seo';
import FilteredDataDesktop from '@components/Search/FilteredDataDesktop';
import FilteredDataMobile from '@components/Search/FiltredDataMobile';
import { SearchInput } from '@components/Search/components/SearchInput';
import { Container, SearchContainer } from '@components/Search/styles/searchInput.style';
import { useAuth } from '@context/AuthProvider';
import { analytics } from '@database/firebase';
import { logEvent, setUserId } from '@firebase/analytics';
import useWindowSize from '@hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import MapImage from '@assets/images/map.png';
import { useTranslation } from 'react-i18next';
import useFetchLocation from '@hooks/useFetchLocations';

const Search: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const { locations } = useFetchLocation();
  const size: any = useWindowSize();
  const { user } = useAuth();
  const userId = user?.uid || '';
  const { t } = useTranslation();

  useEffect(() => {
    logEvent(analytics, 'SearchPage');
    setUserId(analytics, userId);
  }, []);

  return (
    <>
      <Seo
        title={t('header.search') + ' | La Pagode Khmère'}
        description={t('metatag.search.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com/search'}
        image={MapImage}
      />
      <Header />
      <Container>
        <SearchContainer>
          <SearchInput query={query} setQuery={setQuery} />
        </SearchContainer>
        {size.width >= 1024 && <FilteredDataDesktop location={locations} query={query} />}
        {size.width < 1024 && <FilteredDataMobile location={locations} query={query} />}
        <CookieConsentBannerSearch />
      </Container>
    </>
  );
};

export default Search;
