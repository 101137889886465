import React, { useEffect, useMemo, useState } from 'react';
import {
  BoxContainer,
  BoxInnerLayout,
  ButtonBorderLine,
  ButtonLayout,
  ButtonsLayout,
  Content,
  ContentContainer,
  ContentLayout,
  DataLayout,
  FilterSideBarLayout,
  ImageWrapperDesktop,
  LocationsListDesktop,
  NotFoundDesktopLayout,
  PageButtons,
  PaginationButtons,
  PlaceholderImage,
  Title,
  TotalLocationLayout,
  TotalLocations,
  TotalLocationsLayout,
} from './styles/filtredData.style';
import FiltredCitySidebar from './components/FiltredCitySidebar';
import FiltredDateSidebar from './components/FiltredDateSidebar';
import Loader from '@components/Loader';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import { Button, ButtonInnerLayout, MovingChevron } from '@components/Maps/styles/markerMap.style';
import colors from '@styles/common/colors';
import DetailsPageModal from '@components/Modal/DetailsPageModal';
import placeholderImage from '@assets/images/image-placeholder.png';
import { LocationItem } from '@api/location.interface';
import { useAuth } from '@context/AuthProvider';
import styled from 'styled-components';
import useFetchLocation from '@hooks/useFetchLocations';
import FooterSearch from '@components/Footer/FooterSearch';

interface FilteredDataProps {
  location: LocationItem[];
  query: string;
}

const FilteredDataDesktop: React.FC<FilteredDataProps> = ({ location, query }) => {
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedDetailsPageId, setSelectedDetailsPageId] = useState<number | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  // const [upgradeToPremium, setUpgradeToPremium] = useState<boolean>(false);
  const language = useAppSelector(currentLanguage);
  const { t } = useTranslation();
  const { user } = useAuth();
  const { locations, isLoading, isError } = useFetchLocation();
  // const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  // const userIsPremium = usePremiumStatus(user);
  // const userRole = userData?.role;
  // const { Banner } = useBanner();

  // const handleDetailsPageClick = (details: LocationItem) => {
  //   if (userIsPremium || userRole === UserRoles.ADMIN || userRole === UserRoles.PREMIUM) {
  //     setSelectedDetailsPageId(details.id as any);
  //     setShowMenu(!showMenu);
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     setUpgradeToPremium(!upgradeToPremium);
  //   }
  // };

  const handleDetailsPageClick = (details: LocationItem) => {
    if (user) {
      setSelectedDetailsPageId(details.id as any);
      setShowMenu(!showMenu);
      document.body.style.overflow = 'hidden';
    } else {
      window.location.href = '/proceed-to-signin';
    }
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = 'unset';
    // setUpgradeToPremium(false);
  };

  // the location name is dynamically added to search URL when the modal is opened
  const urlSearchParams = new URLSearchParams(window.location.search);
  const locationParam = urlSearchParams.get('location');

  useEffect(() => {
    if (locationParam && locations) {
      const location = locations.find((loc) => loc.name === locationParam);
      if (location) {
        setSelectedDetailsPageId(location.id as any);
        setShowMenu(true);
      }
    }
  }, [locations, locationParam]);

  const filteredLocations = useMemo(() => {
    return location.filter((loc) => {
      const matchesCity = !selectedCity || selectedCity === 'All' || loc.city === selectedCity;
      const matchesDate = !selectedDate || selectedDate === 'All' || loc.color === selectedDate;
      const matchesQuery = loc.name.toLowerCase().includes(query.toLowerCase());
      return matchesCity && matchesDate && matchesQuery;
    });
  }, [location, query, selectedCity, selectedDate]);

  const pageButtons = [];
  const itemsPerPage = 8;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const PAGE_NUMBER_KEY = 'pagination.pageNumber';

  const currentItems = filteredLocations.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(filteredLocations.length / itemsPerPage);

  // To show page only page numbers
  // for (let i = 1; i <= totalPages; i++) {
  //   pageButtons.push(
  //     <PageButtons key={i} className={currentPage === i ? 'active' : ''} onClick={() => setCurrentPage(i)}>
  //       {i}
  //     </PageButtons>
  //   );
  // }
  // To display only four numbers in your pagination and add "..." for the remaining pages, you can modify
  // the loop that generates the page buttons by checking if the current page is within a range of four pages
  // from the current page. If it is not, you can add "..." instead of the page number. the first and last pages
  // are always shown, and if the current page is within two pages of the first or last page, those pages are also shown.
  //If the current page is more than two pages away from the first or last page, "..." is added instead of the page number.
  const shouldShowPageButton = (pageNumber: number): boolean => {
    const isLastPage = pageNumber === 1 || pageNumber === totalPages;
    const isNearCurrentPage = Math.abs(pageNumber - currentPage) <= 2;
    return isLastPage || isNearCurrentPage;
  };

  for (let i = 1; i <= totalPages; i++) {
    const isPageButtonVisible = shouldShowPageButton(i);

    if (isPageButtonVisible) {
      pageButtons.push(
        <PageButtons key={i} className={currentPage === i ? 'active' : ''} onClick={() => setCurrentPage(i)}>
          {t(`${PAGE_NUMBER_KEY}.${i}`)}
        </PageButtons>
      );
    } else if (pageButtons[pageButtons.length - 1]?.props?.children !== '...') {
      pageButtons.push(<span key={i}>...</span>);
    }
  }

  const totalItems = filteredLocations.length;
  const minItems = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <>
      <TotalLocationLayout>
        <TotalLocationsLayout>
          <TotalLocations>{t('search.totalNumber')}&nbsp;</TotalLocations>
          {filteredLocations.length > 0 && (
            <>
              {minItems} {t('search.of')} {totalItems}
            </>
          )}
        </TotalLocationsLayout>
        {totalPages > 1 && (
          <ButtonsLayout>
            <PaginationButtons onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
              {t('button.previous')}
            </PaginationButtons>
            <ButtonBorderLine />
            {pageButtons}
            <ButtonBorderLine />
            <PaginationButtons onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
              {t('button.next')}
            </PaginationButtons>
          </ButtonsLayout>
        )}
      </TotalLocationLayout>

      <DataLayout>
        <FilterSideBarLayout>
          <FiltredCitySidebar onChange={(value: string | null) => setSelectedCity(value)} />
          <FiltredDateSidebar onChange={(value: string | null) => setSelectedDate(value)} />
        </FilterSideBarLayout>

        <ContentContainer>
          {/* <Banner /> */}
          <LocationsListDesktop>
            {isLoading && <Loader />}

            {!isLoading && !isError && filteredLocations.length === 0 && (
              <NotFoundDesktopLayout>{t('search.noDataFound')}</NotFoundDesktopLayout>
            )}

            {filteredLocations.length > 0 &&
              currentItems.map((location, index) => (
                <BoxContainer key={`${location.id}-${index}`}>
                  <BoxInnerLayout>
                    <ImageWrapperDesktop onClick={() => handleDetailsPageClick(location)}>
                      {location?.images?.coverImage ? (
                        <Image src={location?.images?.coverImage} alt={location.name} />
                      ) : (
                        <PlaceholderImage src={placeholderImage} alt={location.name} />
                      )}
                    </ImageWrapperDesktop>
                    <ContentLayout>
                      <Title>
                        {t('form.name')} {t('search.vat')} {(location.locales as any)[language].name || location.name}
                      </Title>
                      <Content>
                        <b>{t('form.otherName')}</b>{' '}
                        {(location.locales as any)[language].otherName || location.otherName}
                      </Content>
                      <Content>
                        <b>{t('form.city')}</b> {(location.locales as any)[language].city || location.city}
                      </Content>
                      <Content>
                        <b>{t('form.district')}</b> {(location.locales as any)[language].district || location.district}
                      </Content>
                      <Content>
                        <b>{t('form.commune')}</b> {(location.locales as any)[language].commune || location.commune}
                      </Content>
                      <Content>
                        <b>{t('form.latitude')}</b> {(location.geometry as any)?.location?.lat}
                      </Content>
                      <Content>
                        <b>{t('form.longitude')}</b> {(location.geometry as any)?.location?.lng}
                      </Content>
                    </ContentLayout>
                  </BoxInnerLayout>
                  <ButtonLayout>
                    <ButtonInnerLayout onClick={() => handleDetailsPageClick(location)}>
                      <Button>{t('button.plus')}</Button>
                      <MovingChevron color={colors.white} size={18} />
                    </ButtonInnerLayout>
                  </ButtonLayout>
                </BoxContainer>
              ))}
          </LocationsListDesktop>
          <FooterSearch />
        </ContentContainer>
      </DataLayout>

      {selectedDetailsPageId && (
        <DetailsPageModal selectedDetailsPageId={selectedDetailsPageId} showMenu={showMenu} onClose={handleCloseMenu} />
      )}

      {/* {upgradeToPremium && <UpgradeToPremiumUserModal showMenu={upgradeToPremium} onClose={handleCloseMenu} />} */}
    </>
  );
};

export default FilteredDataDesktop;

export const FooterLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SponsorLogoLayout = styled.div`
  margin: 20px 0;
`;

export const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
