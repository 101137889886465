import DashboardDesktop from '@components/Dashboard/DashboardDesktop';
import DashboardMobile from '@components/Dashboard/DashboardMobile';
import useWindowSize from '@hooks/useWindowSize';
import React from 'react';

const Dashboard: React.FC = () => {
  const size = useWindowSize();

  return (
    <div>
      {size.width && size.width >= 768 && <DashboardDesktop />}
      {size.width && size.width < 768 && <DashboardMobile />}
    </div>
  );
};

export default Dashboard;
