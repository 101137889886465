import { ClassicButton } from '@components/Button/ClassicButton';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import notFoundPage from '@assets/icons/404-page.json';
import Lottie from 'react-lottie-player';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, 'NotFound_visited');
  }, []);

  return (
    <Container>
      <ErrorInfoBoxDiv>
        <Lottie
          loop
          animationData={notFoundPage}
          play
          style={{ width: 300, height: 300 }}
          role="presentation"
          aria-label="An animation svg of a handsome guy standing in front of 404 sign"
        />
        <Description>OPPS! PAGE NOT FOUND</Description>
        <ClassicButton title={'Go to Sign in'} onClick={() => navigate('/signin')} />
      </ErrorInfoBoxDiv>
    </Container>
  );
};

export default NotFoundPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ErrorInfoBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.p`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
`;
