import { useState, useEffect } from 'react';

export const usePageLoaded = (delay: number): boolean => {
  const [isPageLoaded, setPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPageLoaded(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  return isPageLoaded;
};
