import { SubmitButton } from '@components/Button/SubmitButton';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '@assets/icons/Logo.png';
import { useTranslation } from 'react-i18next';

const Success: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FormLayout>
      <FormContainer>
        <ContentLayout>
          <img src={Logo} width={50} height={50} />
          <Paragraph>{t('success.content')}</Paragraph>
          <SubmitButton text="Go to Signin" aria-labelledby="signin" onClick={() => navigate('/signin')} />
        </ContentLayout>
      </FormContainer>
    </FormLayout>
  );
};

export default Success;

const FormLayout = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 460px;
  background: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 6px;
  box-shadow: ${shadows.md};
  padding: 18px 24px;
`;

const ContentLayout = styled.div`
  text-align: center;
  font-size: 16px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 22px;
`;

const Paragraph = styled.div`
  font-size: 22px;
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 34px;
  margin: 24px 0;
`;
