import { NavProps } from '@utils/navigation-routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import { useTranslation } from 'react-i18next';
import { useGetUserByIdQuery } from '@api/users.api';
import { useAuth } from '@context/AuthProvider';
import { UserRoles } from '@components/Users/roles.enum';

const HeaderLinkDesktop: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  const userRole = userData?.role;

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    navigate(pathname);
  };

  const navLinksDesktop: NavProps[] = [
    {
      id: 1,
      label: t('header.home'),
      pathname: '/',
    },
    {
      id: 2,
      label: t('header.about'),
      pathname: '/about',
    },
    {
      id: 3,
      label: t('header.author'),
      pathname: '/author',
    },
    {
      id: 4,
      label: t('header.search'),
      pathname: '/search',
    },
    {
      id: 5,
      label: t('header.map'),
      pathname: '/map',
    },
    {
      id: 6,
      label: t('header.contact'),
      pathname: '/contact',
    },
  ];

  return (
    <>
      {(user && user?.providerData[0]?.providerId === 'password') ||
      user?.providerData[0]?.providerId === 'google.com' ||
      user?.providerData[0]?.providerId === 'facebook.com' ? (
        <Nav>
          {navLinksDesktop.map((item) => (
            <NavLink
              key={item.id}
              href={item.pathname}
              isActive={activePathname === item.pathname}
              onClick={() => handleLinkClick(item.pathname)}
            >
              <NavItem>{item.label}</NavItem>
            </NavLink>
          ))}
          {userRole === UserRoles.ADMIN && (
            <ButtonLayout>
              <LogoutText onClick={() => navigate('/dashboard')}>{t('header.dashboard')}</LogoutText>
            </ButtonLayout>
          )}
        </Nav>
      ) : (
        <Nav>
          {navLinksDesktop.map((item) => (
            <NavLink
              key={item.id}
              isActive={activePathname === item.pathname}
              onClick={() => handleLinkClick(item.pathname)}
            >
              <NavItem>{item.label}</NavItem>
            </NavLink>
          ))}
        </Nav>
      )}
    </>
  );
};

export default HeaderLinkDesktop;

type NavLinkProps = {
  isActive: boolean;
};

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (min-width: 1024px) {
    gap: 16px;
  }
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -1px;
    left: 0;
    background-color: ${({ isActive }) => (isActive ? colors.dark_tangerine : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ isActive }) => (isActive ? '1' : '0')});
    transition: transform 500ms ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  ${({ isActive }) => (isActive ? 'text-decoration: underline underline-offset(8px);' : '')}
`;

const NavItem = styled.span`
  position: relative;
  font-size: ${sizes.lg};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: ${colors.dark_tangerine};
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media (min-width: 1440px) {
    font-size: ${sizes.xl};
  }
`;

const LogoutText = styled.div`
  font-size: 14px;
  color: ${colors.text_grey};
  letter-spacing: 1px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }

  @media (min-width: 1440px) {
    font-size: ${sizes.xl};
  }
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;
