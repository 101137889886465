import { createGlobalStyle } from 'styled-components';
import { typography } from './common/typography';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body, html {
        padding: 0;
        margin: 0;
        font-family: ${typography.fonts.body};
        background: #ffffff;
        overflow-x: hidden;
        overflow: scroll;
        scroll-behavior: smooth;
    }

    #root {
        overflow: scroll;
    }
`;

export default GlobalStyle;
