const colors = {
  black: '#000000',
  white: '#ffffff',
  red: '#ff4122',
  blue: '#2a9df4',
  yellow: '#e5de00',
  pink: '#F5347F',
  dark_blue: '#1976D2',
  green: '#86DC3D',
  dark_green: '#07882B',
  dark_tangerine: '#dc6601',
  dark_grey: '#1010101',
  dark_yellow: '#FFCC00',
  orange: '#EB6A32',
  silver: '#9ba09c',
  bronze: '#cd7f32',
  gold: '#ffbf00',
  background: '#FCF9F7',
  text_white: '#FCF9F7',
  text_grey: '#2f2f2f',
  primary_color: '#0F6A7D',
  light_gray: '#f6f6f6',
  secondary_color: {
    regular: '#6C6B6B',
    variant_one: '#B9B7B7',
    variant_two: '#E5E5E5',
  },
};

export default colors;
export type Colors = typeof colors;
