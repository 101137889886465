export const articleValue: Article = {
  id: '',
  title: '',
  slug: '',
  description: '',
  vrlink: '',
  publishedDate: new Date(),
  modifiedDate: new Date(),
  categories: [],
  image: '',
  audio: '',
  content: '',
  locales: {
    en: {
      title: '',
      slug: '',
      description: '',
      vrlink: '',
      publishedDate: new Date(),
      modifiedDate: new Date(),
      categories: [],
      content: '',
      audio: '',
    },
    fr: {
      title: '',
      slug: '',
      description: '',
      vrlink: '',
      publishedDate: new Date(),
      modifiedDate: new Date(),
      categories: [],
      audio: '',
      content: '',
    },
    kh: {
      title: '',
      slug: '',
      description: '',
      vrlink: '',
      publishedDate: new Date(),
      modifiedDate: new Date(),
      categories: [],
      audio: '',
      content: '',
    },
  },
};

export interface ArticleLocale {
  fr: Translation;
  en: Translation;
  kh: Translation;
}

export interface Translation {
  title: string;
  slug: string;
  description: string;
  content: string;
  vrlink: string;
  publishedDate: Date;
  modifiedDate: Date;
  categories: string[];
  audio: string;
}

export interface Article {
  id: string;
  title: string;
  slug: string;
  description: string;
  vrlink: string;
  publishedDate: Date;
  modifiedDate: Date;
  categories: string[];
  image: string;
  audio: string;
  content: string;
  locales: ArticleLocale;
}
