import colors from '@styles/common/colors';
import { OptionsDate } from '@utils/date';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Title } from './FiltredCitySidebar';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/Switch/Switch';
import typography from '@styles/common/typography';

interface DateSideBarProps {
  onChange: (selectedDate: string | null) => void;
}

const FiltredDateSidebar: React.FC<DateSideBarProps> = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState<OptionsDate | null>(null);
  const { t } = useTranslation();

  const optionsDate: OptionsDate[] = [
    { label: t('date.all'), value: 'All' },
    { label: t('date.red'), value: 'red' },
    { label: t('date.orange'), value: 'orange' },
    { label: t('date.yellow'), value: 'yellow' },
    { label: t('date.blue'), value: 'blue' },
    { label: t('date.gray'), value: 'gray' },
  ];

  const handleSelectDate = (date: OptionsDate) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date.value);
    }
  };

  return (
    <div>
      <TitleLayout>
        <Title>{t('city.chooseEra')}</Title>
      </TitleLayout>
      {optionsDate.map((location, index) => (
        <SwitchLayout key={index}>
          <Switch
            key={location.value}
            active={selectedDate?.value === location.value}
            onClick={() => handleSelectDate(location)}
            aria-label="Switch button"
          />
          <Label>{location.label}</Label>
        </SwitchLayout>
      ))}
    </div>
  );
};

export default FiltredDateSidebar;

export const Label = styled.div`
  font-size: ${typography.fontSize.lg};
  color: ${colors.text_grey};
  line-height: 26px;
  letter-spacing: 0.5px;
`;

export const SwitchLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const TitleLayout = styled.div`
  margin: 18px 0;
`;
