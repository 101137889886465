import { NavProps, icons } from '@utils/navigation-routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import colors from '@styles/common/colors';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@context/AuthProvider';
import { useGetUserByIdQuery } from '@api/users.api';
import { UserRoles } from '@components/Users/roles.enum';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import typography from '@styles/common/typography';
import { BiLogOutCircle } from 'react-icons/bi';
import { Toast } from '@utils/Toast';

const HeaderLinkMobile: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  const userRole = userData?.role;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/signin');
    } catch (error) {
      console.error('Logout failed:', error);
      const toast = new Toast('Logout failed. Please try again.');
      toast.showError();
    }
  };

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    navigate(pathname);
  };

  const navLinksMobile: NavProps[] = [
    {
      id: 1,
      label: t('header.home'),
      pathname: '/',
    },
    {
      id: 2,
      label: t('header.about'),
      pathname: '/about',
    },
    {
      id: 3,
      label: t('header.author'),
      pathname: '/author',
    },
    {
      id: 4,
      label: t('header.search'),
      pathname: '/search',
    },
    {
      id: 5,
      label: t('header.map'),
      pathname: '/map',
    },
    {
      id: 6,
      label: t('header.contact'),
      pathname: '/contact',
    },
    {
      id: 7,
      label: t('partner.donation'),
      pathname: '/donations',
    },
    {
      id: 8,
      label: t('header.privacy'),
      pathname: '/privacy',
    },
    {
      id: 9,
      label: t('header.terms'),
      pathname: '/terms',
    },
  ];

  return (
    <>
      {(user && user?.providerData[0]?.providerId === 'password') ||
      user?.providerData[0]?.providerId === 'google.com' ||
      user?.providerData[0]?.providerId === 'facebook.com' ? (
        <>
          <Nav>
            {navLinksMobile.map((item, index) => {
              const Icon = icons[index];
              return (
                <NavLink
                  key={item.id}
                  href={item.pathname}
                  isActive={activePathname === item.pathname}
                  onClick={() => handleLinkClick(item.pathname)}
                >
                  <MenuItemsLayout>
                    <IconLayout>
                      <Icon size={18} color={colors.dark_grey} />
                    </IconLayout>
                    <NavLinkText isActive={activePathname === item.pathname}>{item.label}</NavLinkText>
                  </MenuItemsLayout>
                </NavLink>
              );
            })}
            {userRole === UserRoles.ADMIN && (
              <ButtonLayout>
                <MdOutlineDashboardCustomize size={20} color={colors.secondary_color.regular} />
                <LogoutText onClick={() => navigate('/dashboard')}>{t('header.dashboard')}</LogoutText>
              </ButtonLayout>
            )}
            <ButtonSignOutLayout>
              <BiLogOutCircle size={18} color={colors.secondary_color.regular} />
              <Text onClick={handleLogout}>{t('button.logout')}</Text>
            </ButtonSignOutLayout>
          </Nav>
        </>
      ) : (
        <Nav>
          {navLinksMobile.map((item, index) => {
            const Icon = icons[index];
            return (
              <NavLink
                key={item.id}
                href={item.pathname}
                isActive={activePathname === item.pathname}
                onClick={() => handleLinkClick(item.pathname)}
              >
                <MenuItemsLayout>
                  <IconLayout>
                    <Icon size={18} color={colors.dark_grey} />
                  </IconLayout>
                  <NavLinkText isActive={activePathname === item.pathname}>{item.label}</NavLinkText>
                </MenuItemsLayout>
              </NavLink>
            );
          })}
        </Nav>
      )}
    </>
  );
};

export default HeaderLinkMobile;

type NavLinkProps = {
  isActive: boolean;
};

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 15px 30px;
  gap: 6px;
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

const NavLinkText = styled.span<NavLinkProps>`
  position: relative;
  color: ${colors.text_grey};

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -0.5px;
    left: 0;
    background-color: ${({ isActive }) => (isActive ? colors.dark_tangerine : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ isActive }) => (isActive ? '1' : '0')});
    transition: transform 300ms ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  ${({ isActive }) => (isActive ? 'text-decoration: underline underline-offset(4px);' : '')}
`;

const MenuItemsLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;

const IconLayout = styled.div`
  margin-bottom: -5.5px;
  color: ${colors.text_grey};
`;

const LogoutText = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;

const Text = styled.div`
  font-size: ${typography.fontSize.md};
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
    svg {
      fill: ${colors.dark_tangerine};
    }
  }

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

const ButtonSignOutLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;
