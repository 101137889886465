import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const LocationsListMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px 60px 5px;
  gap: 15px;
  overflow-y: scroll;

  @media (min-width: 320px) {
    padding: 0 30px 60px 30px;
  }
  @media (min-width: 768px) {
    padding: 0 80px 60px 80px;
  }
`;

export const DropDownLayout = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 170px;

  @media (min-width: 768px) {
    margin-top: 170px;
  }
`;

export const FilterSideBarLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 450px;
  background: ${colors.white};
  box-shadow: ${shadows.lg};
  border-radius: 12px;
  overflow-y: auto;
`;
export const DataLayout = styled.div`
  display: flex;
  position: fixed;
  top: 250px;
  bottom: 0;
  left: 15px;
  right: 15px;
`;

export const TotalLocationLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${shadows.sm};
  padding: 2px 15px;
  margin-top: 200px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow-y: scroll;
`;

export const LocationsListDesktop = styled.div`
  width: 100%;
  display: grid;
  padding: 0 0 30px 16px;
  gap: 16px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 2560px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const TotalLocationMobileLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 0 16px 0;

  @media (min-width: 540px) {
    padding: 40px 0 16px 0;
  }
`;

export const TotalLocations = styled.div`
  font-size: ${typography.fontSize.lg};
  color: ${colors.dark_tangerine};
  letter-spacing: 0.5px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

export const TotalResultNumbers = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text_grey};
`;

export const TotalLocationsLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const NotFoundLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${colors.dark_tangerine};
`;

export const NotFoundDesktopLayout = styled.div`
  font-size: 16px;
  color: ${colors.dark_tangerine};
`;

export const BoxContainer = styled.div`
  width: 100%;
  background: ${colors.white};
  box-shadow: ${shadows.base};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 12px;
  border: none;

  @media (min-width: 1024px) {
    height: 1100px;
  }

  @media (min-width: 1280px) {
    height: 820px;
  }

  @media (min-width: 1440px) {
    height: 1050px;
  }

  @media (min-width: 1920px) {
    height: 1070px;
  }
`;

export const BoxInnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (min-width: 540px) {
    padding: 30px;
  }
`;

export const ContentLayout = styled.div`
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-top: 15px;

  @media (min-width: 540px) {
    padding-top: 30px;
  }

  @media (min-width: 1440px) {
    line-height: 28px;
  }
`;

export const ContentBtnLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.light_gray};
  padding: 30px;

  @media (min-width: 540px) {
    height: 380px;
  }
  @media (min-width: 768px) {
    height: 600px;
  }
`;

export const ImageWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  background: ${colors.light_gray};
  padding: 30px;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s ease-in-out;
  }

  img {
    transition: transform 0.3s ease-in-out;
  }

  &:hover img {
    transform: scale(1.05); /* zoom in the image a little bit on hover */
  }

  @media (min-width: 1024px) {
    height: 700px;
  }

  @media (min-width: 1280px) {
    height: 450px;
  }

  @media (min-width: 1440px) {
    height: 680px;
  }

  @media (min-width: 1920px) {
    height: 700px;
    padding: 50px;
  }
`;

export const Title = styled.div`
  font-size: ${typography.fontSize.md};
  font-weight: bold;
  color: ${colors.dark_tangerine};
  padding-bottom: 8px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

export const Content = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
    line-height: 28px;
  }
`;

export const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;

  @media (min-width: 540px) {
    padding: 30px;
  }
  @media (min-width: 1280px) {
    padding: 0 30px 0 0;
  }
  @media (min-width: 1440px) {
    padding: 0 30px 0 0;
  }
`;

export const PageButtons = styled.button`
  font-family: 'Siemreap';
  font-size: ${typography.fontSize.md};
  background: none;
  border: none;
  cursor: pointer;

  &.active {
    color: ${colors.dark_tangerine};
    font-weight: bold;
  }
`;

export const ButtonsLayout = styled.div`
  display: flex;
  gap: 0.5em;
  height: 35px;
  box-shadow: 0px 0px 2px ${colors.secondary_color.variant_one};
  border-radius: 4px;
  padding: 2px;
`;

export const PaginationButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${typography.fontSize.md};
  font-family: 'Siemreap';
  letter-spacing: 0.5px;
`;

export const ButtonBorderLine = styled.div`
  border: 0.5px solid ${colors.secondary_color.variant_one};
`;

export const LoaderLayout = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaceholderImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
