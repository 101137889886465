import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SearchContainer = styled.div`
  position: absolute;
  top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchInputStyled = styled.input`
  width: 265px;
  position: relative;
  padding: 12px;
  border-radius: 6px;
  outline: none;
  font-size: ${typography.fontSize.sm};
  text-indent: 25px;
  letter-spacing: 0.8px;

  &:focus {
    border: 0.5px solid ${colors.dark_tangerine};
    box-shadow: 0px 0px 3px ${colors.dark_tangerine};
  }

  &:not(:focus) {
    border: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 320px) {
    width: 300px;
  }
  @media (min-width: 375px) {
    width: 305px;
  }
  @media (min-width: 540px) {
    width: 325px;
  }
  @media (min-width: 1440px) {
    width: 340px;
    font-size: ${typography.fontSize.md};
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 8px;
  right: 4px;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  z-index: 999;
`;

export const SearchButton = styled.button`
  position: absolute;
  top: 10px;
  left: 6px;
  color: ${colors.dark_tangerine};
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99;

  @media (min-width: 1440px) {
    top: 12px;
    left: 6px;
  }
`;
