import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const NavItem = styled.nav`
  font-family: ${typography.fonts.title};
  font-size: ${sizes.xl};
  font-weight: ${typography.fontWeight.medium};
  color: ${colors.text_grey};
  cursor: pointer;
  &:hover {
    color: ${colors.primary_color};
  }
`;
