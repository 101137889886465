import { LoginData } from '@api/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useSubmitSigninForm = () => {
  const { t } = useTranslation();
  const emailRequired = t('signup.emailRequired');
  const passwordRequired = t('signup.passwordRequired');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required(emailRequired),
        password: Yup.string().required(passwordRequired),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return {
    control,
    handleSubmit,
    errors,
    reset,
  };
};
