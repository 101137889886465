import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';

interface ButtonProps {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ClassicButton = ({ onClick, title }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <ButtonText>{title}</ButtonText>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 100%;
  background: ${colors.dark_tangerine};
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonText = styled.div`
  color: ${colors.white};
  font-size: ${typography.fontSize.md};
  letter-spacing: 0.5px;
`;
