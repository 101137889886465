// import { useAppDispatch, useAppSelector } from '@store/hooks';
// import { currentLanguage, setLanguage } from '@store/language/languageSlice';
// import sizes from '@styles/common/sizes';
// import React, { useEffect } from 'react';
// import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
// import flagFr from '@assets/images/fr.png';
// import flagEn from '@assets/images/en.png';
// import flagKh from '@assets/images/kh.png';
// import colors from '@styles/common/colors';

// const LanguageDropdown: React.FC = () => {
//   const language = useAppSelector(currentLanguage);
//   const dispatch = useAppDispatch();
//   const { i18n } = useTranslation();

//   interface Language {
//     label: string;
//     flag: string;
//     code: string;
//   }

//   const languages: Language[] = [
//     { label: 'Français', flag: flagFr, code: 'fr' },
//     { label: 'English', flag: flagEn, code: 'en' },
//     { label: 'ភាសាខ្មែរ', flag: flagKh, code: 'kh' },
//   ];

//   //   Here, we are using the useEffect hook to check for a saved language in local storage
//   //   on app initialization. If a language is found and it is different from the current language,
//   //   we change the language using i18n.changeLanguage
//   //   and update the language state with dispatch(setLanguage(storedLanguage)).

//   useEffect(() => {
//     const storedLanguage = localStorage.getItem('language');
//     if (storedLanguage && storedLanguage !== language) {
//       i18n.changeLanguage(storedLanguage);
//       dispatch(setLanguage(storedLanguage));
//     }
//   }, [language, dispatch, i18n]);

//   const handleLanguageChange = (language: string) => {
//     i18n.changeLanguage(language);
//     dispatch(setLanguage(language));
//     localStorage.setItem('language', language); // save the language to local storage
//   };

//   return (
//     <LanguageSelect value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
//       {languages.map((language) => (
//         <Option key={language.code} value={language.code}>
//           <FlagImage src={language.flag} alt={language.label} />
//           <LanguageLabel>{language.label}</LanguageLabel>
//         </Option>
//       ))}
//     </LanguageSelect>
//   );
// };

// export default LanguageDropdown;

// const LanguageSelect = styled.select`
//   border: 1px solid ${colors.secondary_color.variant_one};
//   border-radius: 4px;
//   height: 30px;
//   font-size: ${sizes.md};
// `;

// const Option = styled.option`
//   display: flex;
//   align-items: center;
// `;

// const FlagImage = styled.img`
//   width: 24px;
//   height: 16px;
// `;

// const LanguageLabel = styled.span`
//   font-size: ${sizes.xs};
// `;

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { currentLanguage, setLanguage } from '@store/language/languageSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import flagFr from '@assets/images/fr.png';
import flagEn from '@assets/images/en.png';
import flagKh from '@assets/images/kh.png';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import {
  LanguageDropdownWrapper,
  LanguageButton,
  FlagImage,
  LanguageList,
  LanguageListItem,
  LanguageLabel,
  Overlay,
} from './style';

interface Language {
  label: string;
  flag: string;
  code: string;
}

const languages: Language[] = [
  { label: 'Français', flag: flagFr, code: 'fr' },
  { label: 'ភាសាខ្មែរ', flag: flagKh, code: 'kh' },
  { label: 'English', flag: flagEn, code: 'en' },
];

const LanguageDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const language = useAppSelector(currentLanguage);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage && storedLanguage !== language) {
      i18n.changeLanguage(storedLanguage);
      dispatch(setLanguage(storedLanguage));
    }
  }, [language, dispatch, i18n]);

  const handleLanguageChange = (language: string) => {
    setIsOpen(false);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    localStorage.setItem('language', language);
  };

  const handleHideModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef}>
      <Overlay isOpen={isOpen} onClick={handleHideModal} />
      <LanguageDropdownWrapper>
        <LanguageButton onClick={() => setIsOpen(!isOpen)}>
          <FlagImage src={languages.find((lang) => lang.code === language)?.flag || flagFr} alt={language} />
          {languages.find((lang) => lang.code === language)?.label || 'Français'}
        </LanguageButton>
        {isOpen && (
          <LanguageList>
            {languages.map((lang) => (
              <LanguageListItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
                <FlagImage src={lang.flag} alt={lang.label} />
                <LanguageLabel>{lang.label}</LanguageLabel>
              </LanguageListItem>
            ))}
          </LanguageList>
        )}
      </LanguageDropdownWrapper>
    </div>
  );
};

export default LanguageDropdown;
