import React, { ReactNode, useEffect, useState, useContext, createContext } from 'react';
import {
  Auth,
  UserCredential,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import { getAuth } from 'firebase/auth';

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);
export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  sendPasswordResetEmail?: (email: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();

  const signUp = (email: string, password: string): Promise<UserCredential> =>
    createUserWithEmailAndPassword(auth, email, password);

  const signIn = async (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const resetPassword = (email: string): Promise<void> => sendPasswordResetEmail(auth, email);

  const logout = (): Promise<void> => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      const token = (await user?.getIdToken()) || '';
      localStorage.setItem('authToken', token);
      setUser(user);
    });

    const storedAuthToken = localStorage.getItem('authToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (storedAuthToken && storedRefreshToken && !user) {
      setUser(auth.currentUser);
    } else {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    }

    return () => {
      unsubscribe();
    };
  }, []);

  const values = {
    signUp,
    user,
    signIn,
    resetPassword,
    logout,
    auth,
    setUser,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
