import { SubmitButton } from '@components/Button/SubmitButton';
import { ClassicFormInput } from '@components/UI/Input/ClassicFormInput';
import { ErrorSpan } from '@components/UI/Input/ErrorSpan.style';
import { Form, FormTitle, SSOButtonLayout } from '@components/UI/Input/Form.style';
import { FormInputWithIcon } from '@components/UI/Input/FormInputWithIcon';
import { TextSmall, TextUnderline } from '@components/UI/Text';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { Toast } from '@utils/Toast';
import TermsAndPrivacySignupLayout from '@components/UI/TermsAndPrivacy/TermsAndPrivacySignupLayout';
import { LabelWithAsterisk } from '@components/UI/Text/LabelWithAsterisk';
import { SignupData } from '@api/auth.interface';
import { useSubmitSignupForm } from '@hooks/useSubmitSignupForm';
import { LinkLayout } from '@components/UI/LinkLayout.style';
import GoogleSignupButton from '@components/SSO/GoogleSignupButton';
// import FacebookSignupButton from '@components/SSO/FacebookSignupButton';
import OrLineLayout from '@components/UI/LineLayout';
// import { useSignupMutation } from '@api/auth.api';
import styled from 'styled-components';
import colors from '@styles/common/colors';
import { useTranslation } from 'react-i18next';
import { analytics, firestore } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import { useSignupMutation } from '@api/auth.api';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Seo from '@components/SEO/Seo';
import Logo from '@assets/icons/LogoWithTitle.png';
import Anna from '@assets/images/AnnaShop.png';
import TLBakery from '@assets/images/TLBakery.png';
import TLStore from '@assets/images/TSstore.png';
import LogoIcon from '@assets/icons/Logo.png';

const defaultValue = {
  displayName: '',
  email: '',
  password: '',
  passwordConfirm: '',
};

const Signup = () => {
  const { control, handleSubmit, errors } = useSubmitSignupForm();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);
  const [formData, setFormData] = useState(defaultValue);
  const [signupMutation, { isLoading }] = useSignupMutation();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSubmit = useCallback(async (data: SignupData) => {
    try {
      setFormData(data);

      const usersRef = collection(firestore, 'users');
      const emailQuery = query(usersRef, where('email', '==', data.email));
      const querySnapshot = await getDocs(emailQuery);

      if (!querySnapshot.empty) {
        const toast = new Toast(t('account.signup-email-in-use'));
        toast.showError();
      } else {
        const user = await signupMutation(data);
        console.log(user);
        navigate('/verify-account');

        localStorage.setItem('rememberedEmail', data.email);

        const toast = new Toast(t('account.signup-success'));
        toast.showSuccess();
      }
    } catch (error) {
      console.log(error);
      const toast = new Toast('An error occurred while signing up. Please refresh and try again!');
      toast.showError();
    }
  }, []);

  const togglePassword: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  useEffect(() => {
    logEvent(analytics, 'Signup');
  }, []);

  return (
    <>
      <Seo
        title={t('button.signup') + ' | La Pagode Khmère'}
        description={t('metatag.about.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com/signup'}
        image={Logo}
      />
      <FormContainer>
        <InfoContainer>
          <SponsorsLayout>
            <Content>{t('partner.sponsors')}</Content>
            <Link to={'https://www.facebook.com/annaonlineshop16'}>
              <img src={Anna} width={120} height={80} />
            </Link>
            <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
              <img src={TLBakery} width={120} height={100} />
            </Link>
            <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
              <img src={TLStore} width={120} height={120} />
            </Link>
          </SponsorsLayout>
        </InfoContainer>
        <Form>
          <img src={LogoIcon} width={60} height={60} />
          <FormTitle>{t('form.register')}</FormTitle>
          <LabelWithAsterisk aria-hidden={'true'}>{t('form.username')}</LabelWithAsterisk>
          <Controller
            control={control}
            name="displayName"
            rules={{ required: true }}
            render={({ field: { onChange, value = formData.displayName }, fieldState: { error } }) => (
              <ClassicFormInput
                onChange={onChange}
                placeholder="Rachana"
                value={value}
                error={error ? true : false}
                aria-label={'username'}
                aria-required="true"
              />
            )}
          />
          <ErrorSpan>{errors.displayName?.message}</ErrorSpan>
          <LabelWithAsterisk aria-hidden={'true'}>{t('form.email')}</LabelWithAsterisk>
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = formData.email }, fieldState: { error } }) => (
              <ClassicFormInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                  onChange(sanitizedValue);
                }}
                onBlur={onBlur} // notify when input is touched/blur
                placeholder="example@gmail.com"
                value={value.split(' ').join('')}
                error={error ? true : false}
                aria-label={'email'}
                aria-required="true"
              />
            )}
          />
          <ErrorSpan>{errors.email?.message}</ErrorSpan>
          <LabelWithAsterisk aria-hidden={'true'}>{t('form.password')}</LabelWithAsterisk>
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value = formData.password }, fieldState: { error } }) => (
              <FormInputWithIcon
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                  onChange(sanitizedValue);
                }}
                onBlur={onBlur}
                placeholder="Password"
                type={passwordShown ? 'text' : 'password'}
                value={value}
                error={error ? true : false}
                icon={passwordShown ? FaEye : FaEyeSlash}
                onClick={togglePassword}
                aria-label={'Password'}
                aria-required="true"
                autoComplete="new-password"
              />
            )}
          />
          <ErrorSpan>{errors.password?.message}</ErrorSpan>
          <LabelWithAsterisk aria-hidden={'true'}>{t('form.confirmPassword')}</LabelWithAsterisk>
          <Controller
            control={control}
            name="passwordConfirm"
            rules={{ required: true }}
            render={({ field: { onChange, value = formData.passwordConfirm }, fieldState: { error } }) => (
              <FormInputWithIcon
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                  onChange(sanitizedValue);
                }}
                placeholder="Password"
                type={confirmPasswordShown ? 'text' : 'password'}
                value={value.trim()} // add .trim() to prevent to enter space in input field
                error={error ? true : false}
                icon={confirmPasswordShown ? FaEye : FaEyeSlash}
                onClick={toggleConfirmPassword}
                aria-label={'Password'}
                aria-required="true"
              />
            )}
          />
          <ErrorSpan>{errors.passwordConfirm?.message}</ErrorSpan>
          <SubmitButton
            text={t('button.signup')}
            aria-labelledby={'sign up'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            // disabled={!formData.email || !formData.displayName || !formData.password || !formData.passwordConfirm}
          />
          <LinkLayout>
            <TextSmall>{t('form.yesAccount')}</TextSmall>
            <TextUnderline
              onClick={() => {
                logEvent(analytics, 'Signup_Button_Click');
                navigate('/signin');
              }}
            >
              {t('button.signin')}
            </TextUnderline>
          </LinkLayout>
          <OrLineLayout />
          <SSOButtonLayout>
            <GoogleSignupButton />
            {/* <FacebookSignupButton /> */}
          </SSOButtonLayout>
          <TermsAndPrivacySignupLayout />{' '}
        </Form>
        <CookieConsentBanner />
      </FormContainer>
    </>
  );
};

export default Signup;

const FormContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    justify-content: flex-start;
  }
`;

const InfoContainer = styled.div`
  display: none;

  @media (min-width: 1280px) {
    display: block;
    background: ${colors.dark_tangerine};
    height: 100vh;
    width: 50%;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  letter-spacing: 0.5px;
  color: ${colors.text_white};
`;

const SponsorsLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
