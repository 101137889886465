import { useCallback } from 'react';
import { useMapEvent } from 'react-leaflet';
import debounce from 'lodash/debounce';

interface Props {
  onZoomChange: () => void;
}

const MapZoomDebounce = ({ onZoomChange }: Props) => {
  const debouncedCallback = useCallback(debounce(onZoomChange, 500), [onZoomChange]);

  useMapEvent('zoomend', () => {
    debouncedCallback();
  });

  return null;
};

export default MapZoomDebounce;
