import styled from 'styled-components';
import { TextSmall } from '../Text/TextSmall';
import { TextUnderline } from '../Text/TextUnderline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TermAndPrivacyLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2px;
`;

const TermsAndPrivacySigninLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TermAndPrivacyLayout>
      <TextSmall>{t('form.termsOfUseTextSignin')}</TextSmall>
      {/* using aria-labelledby when text is visible on the screen */}
      {/* If the element has text content, you DON'T need to add aria-label, as it is redundant. */}
      <TextUnderline onClick={() => navigate('/terms')} aria-labelledby={'Terms of Use'}>
        {t('form.termsOfUseNav')}
      </TextUnderline>
      <TextSmall>{t('form.and')}</TextSmall>
      <TextUnderline onClick={() => navigate('/privacy')} aria-labelledby={'Privacy Policy'}>
        {t('header.privacy')}
      </TextUnderline>
    </TermAndPrivacyLayout>
  );
};

export default TermsAndPrivacySigninLayout;
