import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import sizes from '@styles/common/sizes';
import styled from 'styled-components';

export const ErrorSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 2px 0;
  color: ${colors.red};
  font-size: ${sizes.base};
  line-height: 16px;

  @media ${devices.laptop} {
    font-size: ${sizes.md};
  }
`;
