import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import isUserPremium from './isUserPremium';

export default function usePremiumStatus(user: User | null) {
  const [premiumStatus, setPremiumStatus] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const checkPremiumStatus = async function () {
        setPremiumStatus(await isUserPremium());
      };

      checkPremiumStatus();
    }
  }, [user]);

  return premiumStatus;
}
