import colors from '@styles/common/colors';

import React from 'react';
import styled from 'styled-components';

interface SwitchButton {
  active: boolean;
}

const StyledSwitchButton = styled.div<SwitchButton>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  button {
    width: 32px;
    background-color: ${(props) => (props.active ? colors.dark_tangerine : colors.secondary_color.variant_two)};
    border-radius: 11px;
    border: 1px solid ${(props) => (props.active ? colors.dark_tangerine : colors.secondary_color.variant_two)};
    box-sizing: border-box;
    padding: 0;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: none;
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background-color: ${colors.white};
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.3);
      transition: all 300ms ease-in-out;
      transform: ${(props) => (props.active ? 'translate(16px)' : 'translate(0)')};
      display: block;
    }
  }
`;

interface SwitchProps {
  active: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Switch = (props: SwitchProps) => {
  const { active = false, onClick } = props;

  return (
    <StyledSwitchButton active={active}>
      <button type="button" onClick={onClick} aria-label="Switch Button"></button>
    </StyledSwitchButton>
  );
};
