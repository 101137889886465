import { Tooltip, TooltipProps } from 'react-leaflet';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
  &.custom-tooltip {
    position: absolute;
    top: -40px;
    border-radius: 12px;
    padding: 12px 16px;
  }
`;

export const tooltipOptions: TooltipProps = {
  direction: 'top',
  permanent: false,
  className: 'custom-tooltip',
};
