import colors from '@styles/common/colors';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Anna from '@assets/images/AnnaShop.png';
import TLBakery from '@assets/images/TLBakery.png';
import TLStore from '@assets/images/TSstore.png';
import { useTranslation } from 'react-i18next';

const SponsorLogoLinks: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LinksLayout>
      <RightItemTitle>{t('partner.sponsors')}</RightItemTitle>
      <LogosLayout>
        <Link to={'https://www.facebook.com/annaonlineshop16'}>
          <img src={Anna} width={120} height={80} />
        </Link>
        <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
          <img src={TLStore} width={80} height={80} />
        </Link>
        <Link to={'https://www.facebook.com/profile.php?id=100034507136040'}>
          <img src={TLBakery} width={90} height={80} />
        </Link>
      </LogosLayout>
    </LinksLayout>
  );
};

export default SponsorLogoLinks;

const LinksLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RightItemTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 32px;
`;

const LogosLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
