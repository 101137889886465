import { ClassicButton } from '@components/Button/ClassicButton';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ProceedToSignin: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Paragraph>{t('proceed.signin')}</Paragraph>
        <ButtonLayout>
          <ClassicButton title={t('proceed.go-to-signin')} onClick={() => navigate('/signin')} />
        </ButtonLayout>
      </Wrapper>
    </Container>
  );
};

export default ProceedToSignin;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  background: ${colors.white};
  border: 1px solid ${colors.secondary_color.variant_two};
  border-radius: 4px;
  box-shadow: ${shadows.md};
  padding: 24px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const ButtonLayout = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const Paragraph = styled.div`
  font-size: 22px;
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 34px;
`;
