import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Toast {
  constructor(private title: string) {}

  private get commonOptions(): ToastOptions {
    return {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'error-toast',
    };
  }

  showSuccess(): void {
    toast.success(this.title, {
      ...this.commonOptions,
    });
  }
  showError(): void {
    toast.error(this.title, {
      ...this.commonOptions,
    });
  }
}
