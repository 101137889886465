import Header from '@components/Header/Header';
import colors from '@styles/common/colors';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Seo from '@components/SEO/Seo';
import { logEvent } from '@firebase/analytics';
import { analytics } from '@database/firebase';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import VatImage from '@assets/images/30804-1-Moha-Leap-(274).jpg';
import Footer from '@components/Footer/Footer';
import typography from '@styles/common/typography';

const About: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    logEvent(analytics, 'AboutPage');
  }, []);

  return (
    <>
      <Seo
        title={t('header.about') + ' | La Pagode Khmère'}
        description={t('metatag.about.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com/about'}
        image={VatImage}
      />
      <Header />
      {/* <Banner /> */}
      <Layout>
        <Title>{t('header.about')}</Title>
        <Content>{t('about.content-1')}</Content>
        <Content>{t('about.content-2')}</Content>
        <Content>
          {t('about.content-3')}
          &nbsp;
          <Links href="/contact">{t('about.contact')}</Links>
        </Content>
      </Layout>
      <Footer />
      <CookieConsentBanner />
    </>
  );
};

export default About;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 15px;

  @media (min-width: 320px) {
    padding: 100px 30px;
  }
  @media (min-width: 768px) {
    padding: 100px 15px;
  }
  @media (min-width: 1024px) {
    padding: 100px 53px;
  }
  @media (min-width: 1280px) {
    padding: 100px 180px;
  }
  @media (min-width: 1440px) {
    padding: 100px 380px;
  }
  @media (min-width: 1920px) {
    padding: 100px 590px;
  }
`;

const Content = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.xl};
    line-height: 30px;
  }
`;

const Links = styled.a`
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: ${colors.text_grey};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${colors.dark_tangerine};
  }

  @media (min-width: 1280px) {
    font-size: 18px;
  }
`;

const Title = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.dark_tangerine};
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['4xl']};
  }
`;
