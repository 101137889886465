import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import styled from 'styled-components';
import MarkerInfoModal from '../Modal/MarkerInfoModal';
import MenuDrawer from '@components/Drawer/MenuDrawer';

const SidebarMenu: React.FC = () => {
  return (
    <Container>
      <SidebarLayout>
        <MenuDrawer />
        <MarkerInfoModal />
      </SidebarLayout>
    </Container>
  );
};

export default SidebarMenu;

const Container = styled.div`
  width: 80px;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
  box-shadow: ${shadows.lg};
  padding: 1.3rem 0;
  z-index: 9999;
`;

const SidebarLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
