import MuralPainting from '@assets/home/MuralPainting/Enteak-Kosei.webp';
import Ceiling from '@assets/home/Ceiling/Moha-Leap-Ceiling.webp';
import WoodenPanels from '@assets/home/WoodenPanels/Moha-Leap.webp';
import PaintedColumn from '@assets/home/PaintedColums/Moni-Sakor.webp';

export const paintingImageData = [
  {
    id: 1,
    imageSrc: MuralPainting,
    altText: 'Mural Painting',
    content: 'Mural Painting',
  },
  {
    id: 2,
    imageSrc: Ceiling,
    altText: 'Ceiling',
    content: 'Ceiling',
  },
  {
    id: 3,
    imageSrc: WoodenPanels,
    altText: 'Offset Panel',
    content: 'Offset Panel',
  },
  {
    id: 4,
    imageSrc: PaintedColumn,
    altText: 'Painted Column',
    content: 'Painted Column',
  },
];
