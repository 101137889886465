import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from '../public/locales/fr.json';
import en from '../public/locales/en.json';
import kh from '../public/locales/kh.json';

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
  kh: {
    translation: kh,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    fallbackLng: 'fr',
    supportedLngs: ['fr', 'en', 'kh'],
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
