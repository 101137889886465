/* eslint-disable react/no-unescaped-entities */
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Terms: React.FC = () => {
  useEffect(() => {
    logEvent(analytics, 'Terms');
  }, []);

  return (
    <>
      <Header />
      <PrivacyLayout>
        <Title>Terms of Use</Title>
        <UpdateText>Last updated: February 27, 2024</UpdateText>
        <Content>
          These General Terms of Use (GTU) aim to define the terms of use of the site. By accessing or using the mobile
          application and the website, the user unreservedly accepts the GTU and agrees to comply with them. The user is
          responsible for the lawful use of these services, in accordance with current regulations and these GTU.
        </Content>
        <Content>
          La Pagode Khmère reserves the right to update the website at any time. Updates may include changes to
          features, bug fixes, security improvements, or other enhancements. The user agrees to update the mobile
          application and the website when an update is available.
        </Content>
        <Content>
          La Pagode Khmère implements the necessary means to ensure the availability of the website. However, the owner
          of these services may decide to interrupt the service for technical maintenance. In this case, efforts are
          made to communicate the dates and times of the intervention to users.
        </Content>
        <SubTitle>Acceptance of Conditions:</SubTitle>
        <Content>
          By accessing or using the Site, you declare that you are of legal age and have the legal capacity to enter
          into this agreement. You also acknowledge having read, understood, and agreed to be bound by these Conditions.
          If you access or use the Site on behalf of an organization, you declare and warrant that you have the
          authority to bind that organization to these conditions.
        </Content>
        <SubTitle>User accounts</SubTitle>
        <Content>
          To access certain features of the Site, it may be necessary to create a user account. You are responsible for
          maintaining the confidentiality of your account information, including your username and password. You agree
          to provide accurate, up-to-date, and complete information during the registration process, and to update this
          information as needed. You are solely responsible for all activities that occur under your account, and
          Gautama Tech shall not be liable for any unauthorized use of your account.
        </Content>
        <SubTitle>User content:</SubTitle>
        <Content>
          By submitting, posting, or uploading content to the Site, you grant La Pagode Khmère a non-exclusive,
          royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish,
          translate, distribute, perform, and display that content in any medium. You represent and warrant that you
          have the necessary rights to grant this license and that the content you submit does not violate the
          intellectual property rights or other rights of any third party.
        </Content>
        <SubTitle>Prohibited Activities:</SubTitle>
        <Content>When using the Site, you agree not to engage in any of the following activities:</Content>
        <List>Violation of applicable laws or regulations;</List>
        <List>Interference or disruption of the integrity or performance of the Site or its servers;</List>{' '}
        <List>Transmission of any harmful code, viruses, or malicious software;</List>
        <List>
          Attempted unauthorized access to any part of the Site. Engagement in any behavior that restricts or hinders
          the enjoyment of use.
        </List>
        <SubTitle>Subscription Payments</SubTitle>
        <Content>
          <u>Subscription Plans</u> : The Site offers subscription plans that provide access to certain features and
          content (Subscription). By subscribing to a plan, you agree to pay the applicable fees associated with your
          chosen subscription plan.
        </Content>
        <Content>
          <u>Payment Processing:</u> : We use Stripe, a third-party payment service provider, to manage subscription
          payment transactions. By subscribing to a plan, you agree to comply with Stripe's terms and conditions, which
          can be viewed on their website.
        </Content>
        <Content>
          <u>Billing and Account Information</u> : To subscribe to a plan, you must provide accurate and complete
          billing information, including a valid payment method. You authorize us to charge the applicable fees to the
          provided payment method. You are responsible for maintaining the accuracy of your billing information and
          promptly updating it in case of any changes.
        </Content>
        <Content>
          <u>Automatic Renewal</u> : Subscriptions are billed on a recurring basis, typically monthly or annually,
          depending on the chosen plan. By subscribing, you authorize us to automatically renew your subscription and
          charge the applicable fees to your payment method, unless you cancel your subscription before the renewal
          date. You can manage and cancel your subscription via your account settings on the Site.
        </Content>
        <Content>
          <u>Fee Changes</u> : We reserve the right to modify subscription fees at any time. Any changes to fees will be
          communicated to you in advance, and you will have the option to accept the new fees or cancel your
          subscription.
        </Content>
        <Content>
          <u>Refunds</u> : Subscription fees are non-refundable unless otherwise stated. In the event of cancellation of
          your subscription, you will not receive a refund for the unused subscription period.
        </Content>
        <Content>
          <u>Taxes</u> : You are responsible for any applicable taxes or duties on subscription fees, in accordance with
          applicable law.
        </Content>
        <Content>
          <u>Disputes</u> : In case of disputes regarding payments or charges, please contact us at
          contact@tun-puthpiseth.com for assistance. However, we reserve the right to suspend or terminate your
          subscription and access to the Site in case of non-payment or fraudulent activity.
        </Content>
        {/* <SubTitle>Paiement des sponsors</SubTitle>
        <Content>
          <u>Paiement de sponsors</u> : Gautama Tech offre la possibilité de recevoir des paiements de sponsors pour
          promouvoir vos produits, services ou événements sur le site web <b>https://lapagodekhmere.com</b>. En devenant
          sponsor, vous acceptez de respecter les conditions de paiement mensuel ou annuel spécifiées pour chaque
          sponsor.
        </Content>
        <Content>
          <u>Conditions de paiement</u> : Les sponsors doivent s'engager à effectuer des paiements mensuels ou annuels
          selon les modalités convenues avec Gautama Tech. Les détails spécifiques, y compris les montants, les
          échéances et les méthodes de paiement, seront définis dans un accord de sponsoring distinct entre Gautama Tech
          et chaque sponsor.
        </Content>
        <Content>
          <u>Responsabilité des paiements</u> : Gautama Tech travaille directement avec les sponsors pour les paiements
          convenus. Les transactions financières, y compris les paiements et les litiges liés aux paiements, sont de la
          responsabilité de chaque sponsor.
        </Content>
        <Content>
          <u>Durée de promotion</u> : La durée de la promotion des produits, services ou événements des sponsors sur le
          site <b>https://lapagodekhmere.com</b> dépendra des termes spécifiques convenus entre Gautama Tech et chaque
          sponsor. Les périodes de promotion seront définies dans l'accord de sponsoring individuel.
        </Content>
        <Content>
          <u>Résiliation de la promotion</u> : Gautama Tech se réserve le droit de mettre fin à la promotion d'un
          sponsor à tout moment si celui-ci ne respecte pas les termes de paiement convenus ou en cas de violation des
          présentes Conditions générales d'utilisation.
        </Content>
        <Content>
          <u>Utilisation des fonds des sponsors</u> : Les fonds reçus des sponsors seront utilisés conformément aux
          termes convenus entre Gautama Tech et les sponsors. La Pagode Khmère s'engage à utiliser ces fonds de manière
          responsable et à les affecter aux activités de promotion et de développement du site web.
        </Content> */}
      </PrivacyLayout>
      <Footer />
    </>
  );
};

export default Terms;

const PrivacyLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 15px;

  @media (min-width: 320px) {
    padding: 100px 42px;
  }
  @media (min-width: 768px) {
    padding: 100px 15px;
  }
  @media (min-width: 1024px) {
    padding: 100px 53px;
  }
  @media (min-width: 1280px) {
    padding: 100px 180px;
  }
  @media (min-width: 1440px) {
    padding: 100px 380px;
  }
  @media (min-width: 1920px) {
    padding: 100px 590px;
  }
`;

const Title = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.dark_tangerine};
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['4xl']};
  }
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin: 8px 0;
`;

const Content = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.xl};
    line-height: 30px;
  }
`;

const List = styled.li`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.xl};
    line-height: 30px;
  }
`;

const UpdateText = styled.div`
  text-align: center;
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin-bottom: 32px;
`;
