import { ClassicButton } from '@components/Button/ClassicButton';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, 'Unauthorize');
  }, []);

  return (
    <div>
      <h1>Unauthorized</h1>
      <p>You are not authorized to access this page.</p>
      <ClassicButton title={'Back home'} onClick={() => navigate('/')} />
    </div>
  );
};

export default UnauthorizedPage;
