import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '@store/language/languageSlice';
import modalReducer from '@store/modal/modalSlice';
import authReducer from '@store/auth/authSlice';
import { locationApi } from '@api/location.api';
import { authApi } from '@api/auth.api';
import { usersApi } from '@api/users.api';

const store = configureStore({
  reducer: {
    language: languageReducer,
    modal: modalReducer,
    auth: authReducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authApi.middleware, locationApi.middleware, usersApi.middleware]),
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
