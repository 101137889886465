import React, { useRef, useState } from 'react';
import { useAuth } from '@context/AuthProvider';
import colors from '@styles/common/colors';
import styled from 'styled-components';
import shadows from '@styles/common/shadows';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { NavProps, accountIcons } from '@utils/navigation-routes';
import { useTranslation } from 'react-i18next';
import { Toast } from '@utils/Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiLogOutCircle } from 'react-icons/bi';
import ProfileIcon from './ProfileIcon';
// import StripePremiumLink from '@stripe/stripePremiumLink';s

const AccountMenuDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [activePathname, setActivePathname] = useState<string>(location.pathname);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const navLinksAccountMenu: NavProps[] = [
    // {
    //   id: 1,
    //   label: t('account.profile'),
    //   pathname: '/profile',
    // },
    {
      id: 1,
      label: t('account.settings'),
      pathname: '/settings',
    },
    {
      id: 2,
      label: t('partner.donation'),
      pathname: '/donations',
    },
    // {
    //   id: 3,
    //   label: t('partner.become-sponsor'),
    //   pathname: '/sponsors',
    // },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/signin');
    } catch (error) {
      console.error('Logout failed:', error);
      const toast = new Toast('Logout failed. Please try again.');
      toast.showError();
    }
  };

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
    navigate(pathname);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  useOnClickOutside(dropdownMenuRef, handleCloseMenu);

  return (
    <>
      <div ref={dropdownMenuRef}>
        <div onClick={() => setIsOpen(!isOpen)}>
          <ProfileIcon />
        </div>
        {isOpen && user && (
          <MenuContainer>
            <Nav>
              <Text>{user.displayName?.toUpperCase()}</Text>
              {/* <StripePremiumLink /> */}
              {navLinksAccountMenu.map((item, index) => {
                const Icon = accountIcons[index];
                return (
                  <NavLink
                    key={item.id}
                    isActive={activePathname === item.pathname}
                    onClick={() => handleLinkClick(item.pathname)}
                  >
                    <MenuItemsLayout>
                      <IconLayout>
                        <Icon size={18} color={colors.secondary_color.regular} />
                      </IconLayout>
                      <NavLinkText isActive={activePathname === item.pathname}>{item.label}</NavLinkText>
                    </MenuItemsLayout>
                  </NavLink>
                );
              })}

              <ButtonSignOutLayout>
                <BiLogOutCircle size={18} color={colors.secondary_color.regular} />
                <Text onClick={handleLogout}>{t('button.logout')}</Text>
              </ButtonSignOutLayout>
            </Nav>
          </MenuContainer>
        )}
      </div>
    </>
  );
};

export default AccountMenuDropdown;

const MenuContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  width: 270px;
  background: ${colors.white};
  border-radius: 4px;
  border: 0.5px solid ${colors.secondary_color.variant_two};
  box-shadow: ${shadows.lg};
  transition: left 0.3s ease-in-out;
  z-index: 999;

  @media (min-width: 768px) {
    width: 300px;
  }
`;

type NavLinkProps = {
  isActive: boolean;
};

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 6px;
`;

const NavLink = styled.div<NavLinkProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.text_grey};
  letter-spacing: 1px;
  cursor: pointer;

  @media (min-width: 540px) {
    font-size: 16px;
  }
`;

const NavLinkText = styled.span<NavLinkProps>`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -0.5px;
    left: 0;
    background-color: ${({ isActive }) => (isActive ? colors.dark_tangerine : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ isActive }) => (isActive ? '1' : '0')});
    transition: transform 300ms ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  ${({ isActive }) => (isActive ? 'text-decoration: underline underline-offset(4px)' : '')}
`;

const MenuItemsLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;

const IconLayout = styled.div`
  margin-bottom: -5.5px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
    svg {
      fill: ${colors.dark_tangerine};
    }
  }

  @media (min-width: 540px) {
    font-size: 16px;
  }
`;

const ButtonSignOutLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${colors.dark_tangerine};
    }
  }
`;
