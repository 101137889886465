import React from 'react';
import { Input } from './styles/form.style';
import { LocationItem } from '@api/location.interface';

interface Props {
  fieldName: string;
  location: LocationItem;
  label: string;
  accept: string;
  handleFileUpload: (
    fieldName: string,
    location: LocationItem
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const UploadFileInput: React.FC<Props> = ({ fieldName, location, label, accept, handleFileUpload }) => {
  return (
    <div>
      <label>{label}</label>
      <Input type="file" name={fieldName} onChange={handleFileUpload(fieldName, location)} accept={accept} />
    </div>
  );
};

export default UploadFileInput;
