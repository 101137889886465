import colors from '@styles/common/colors';
import styled from 'styled-components';

interface InputIconProps {
  icon: React.FC;
}

export const InputIcon = ({ icon }: InputIconProps) => {
  const Icon = styled(icon)`
    height: 15px;
    width: 15px;
    color: ${colors.secondary_color.regular};
  `;
  return <Icon />;
};
