import { useEffect, useState } from 'react';
import { collection, getDocs, QuerySnapshot } from 'firebase/firestore';
import { firestore } from '@database/firebase';
import { Toast } from '@utils/Toast';
import { Article } from '@api/article.interface';

const useFetchArticles = (): {
  articles: Article[];
  isLoading: boolean;
  isError: boolean;
  newlyUploadedArticle: Article | null;
  setArticles: React.Dispatch<React.SetStateAction<Article[]>>;
  setNewlyUploadedArticle: React.Dispatch<React.SetStateAction<Article | null>>;
} => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [newlyUploadedArticle, setNewlyUploadedArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  // Fetch articles on component mount
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articlesCollection = collection(firestore, 'articles');
        const articlesSnapshot: QuerySnapshot = await getDocs(articlesCollection);
        const articlesData: Article[] = articlesSnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as Article;
        });

        // Sort the articles alphabetically by title
        // First alternative
        // articlesData.sort((a, b) => {
        //   const titleA = a.title.toUpperCase();
        //   const titleB = b.title.toUpperCase();
        //   if (titleA < titleB) {
        //     return -1;
        //   }
        //   if (titleA > titleB) {
        //     return 1;
        //   }
        //   return 0;
        // });

        // Second alternative
        articlesData.sort((a, b) => a.title.localeCompare(b.title));

        setArticles(articlesData);
      } catch (error) {
        console.error('Error fetching locations:', error);
        setIsError(true);
        const toast = new Toast('Error fetching locations!');
        toast.showError();
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, []);

  // If there's a newly uploaded article, add it to the list of articles at the correct position
  useEffect(() => {
    if (newlyUploadedArticle) {
      // Find the index to insert the new article based on alphabetical order
      const insertIndex = articles.findIndex((article) => newlyUploadedArticle.title.localeCompare(article.title) < 0);

      // If insertIndex is -1, it means the new article should be appended to the end
      const newArticles =
        insertIndex !== -1
          ? [...articles.slice(0, insertIndex), newlyUploadedArticle, ...articles.slice(insertIndex)]
          : [...articles, newlyUploadedArticle];

      setArticles(newArticles);
      setNewlyUploadedArticle(null); // Reset the newlyUploadedArticle state
    }
  }, [newlyUploadedArticle, articles]);

  return { articles, setArticles, newlyUploadedArticle, setNewlyUploadedArticle, isLoading, isError };
};

export default useFetchArticles;
