import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const LanguageDropdownWrapper = styled.div`
  position: relative;
`;

export const LanguageButton = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2px;
  border: 1px solid ${colors.secondary_color.variant_one};
  border-radius: 4px;
  height: 35px;
  font-size: ${typography.fontSize.md};
  background: ${colors.white};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const FlagImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const LanguageList = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 2px;
  box-shadow: ${shadows.md};
  padding: 2px 0;
  z-index: 9999;
`;

export const LanguageListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.secondary_color.variant_two};
  }
`;

export const LanguageLabel = styled.div`
  font-size: ${typography.fontSize.md};
`;

interface ModalProps {
  isOpen: boolean;
}

export const Overlay = styled.div<ModalProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  z-index: 9999;
`;
