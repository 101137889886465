import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';
import { SSOIcon } from './SSOIcon';

export interface SSOButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon: React.FC;
}

const Button = styled.button<SSOButtonProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-family: ${typography.fonts.body};
  font-size: 13px;
  border-radius: 4px;
  background-color: ${colors.white};
  border: 1px solid ${colors.dark_tangerine};
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    color: ${colors.primary_color};
    border: 1px solid ${colors.primary_color};
  }
`;

const IconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.dark_tangerine};
  letter-spacing: 0.5px;
`;

export const SSOButton = (props: SSOButtonProps) => {
  const { title, icon } = props;

  return (
    <Button {...props}>
      <IconLayout>
        <SSOIcon icon={icon}></SSOIcon>
        <Title>{title}</Title>
      </IconLayout>
    </Button>
  );
};
