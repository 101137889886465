import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuDrawer from '@components/Drawer/MenuDrawer';
import Logo from '@assets/logos/LogoNoTitle.png';
import { useNavigate } from 'react-router-dom';
import shadows from '@styles/common/shadows';
import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import { useTranslation } from 'react-i18next';

const HeaderMobile: React.FC = () => {
  const navigate = useNavigate();
  const [isSticky, setSticky] = useState(false);
  const { t } = useTranslation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setSticky(scrollPosition > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* To prevent the "Profile Icon" from appearing when you refresh the page */}

      <HeaderContainer $issticky={isSticky}>
        <LogoLayout onClick={() => navigate('/')}>
          <img src={Logo} width={40} height={40} alt="La Pagode Khmère Logo" loading="lazy" />
        </LogoLayout>
        <RightIconLayout>
          <LinkText href="/donations">
            <ButtonLayout>
              <Text>{t('partner.donation')}</Text>
            </ButtonLayout>
          </LinkText>
          <MenuDrawer />
        </RightIconLayout>
      </HeaderContainer>
    </>
  );
};

export default HeaderMobile;

const RightIconLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LinkText = styled.a`
  text-decoration: none;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background: ${colors.dark_tangerine};
  border-radius: 6px;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background: ${colors.orange};
  }
`;

const Text = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.white};
  letter-spacing: 1px;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.lg};
  }
`;

const HeaderContainer = styled.div<{ $issticky: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.secondary_color.variant_two};
  padding: 15px 15px;
  background-color: ${(props) => (props.$issticky ? '#ffffff' : '#ffffff')};
  box-shadow: ${(props) => (props.$issticky ? shadows.md : 'none')};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;

  @media (min-width: 320px) {
    padding: 15px 30px;
  }
  @media (min-width: 768px) {
    padding: 15px 15px;
  }
  @media (min-width: 1024px) {
    padding: 15px 53px;
  }
`;

const LogoLayout = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
