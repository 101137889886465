import React, { useEffect, useMemo, useState } from 'react';
import {
  BoxContainer,
  BoxInnerLayout,
  ButtonLayout,
  Content,
  ContentLayout,
  DropDownLayout,
  ImageWrapper,
  LoaderLayout,
  LocationsListMobile,
  NotFoundLayout,
  PlaceholderImage,
  Title,
  TotalLocationMobileLayout,
  TotalLocations,
} from './styles/filtredData.style';
import CityDropdown from './components/CityDropdown';
import DateDropdown from './components/DateDropdown';
import Loader from '@components/Loader';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import { useTranslation } from 'react-i18next';
import DetailsPageModal from '@components/Modal/DetailsPageModal';
import { Button, ButtonInnerLayout, MovingChevron } from '@components/Maps/styles/markerMap.style';
import colors from '@styles/common/colors';
import { LocationItem } from '@api/location.interface';
import { useAuth } from '@context/AuthProvider';
// import { useGetUserByIdQuery } from '@api/users.api';
// import usePremiumStatus from '@stripe/usePremiumStatus';
// import { UserRoles } from '@components/Users/roles.enum';
// import UpgradeToPremiumUserModal from '@components/Modal/UpgradeToPremiumUserModal';
import placeholderImage from '@assets/images/image-placeholder.png';
// import useBanner from '@hooks/useBanner';
import useFetchLocation from '@hooks/useFetchLocations';
import styled from 'styled-components';
import Footer from '@components/Footer/Footer';

interface FilteredDataProps {
  location: LocationItem[];
  query: string;
}

const FilteredDataMobile: React.FC<FilteredDataProps> = ({ location, query }) => {
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDetailsPageId, setSelectedDetailsPageId] = useState<number | null>(null);
  const [itemsToShow, setItemsToShow] = useState<number>(8);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  // const [upgradeToPremium, setUpgradeToPremium] = useState<boolean>(false);
  const { t } = useTranslation();
  const language = useAppSelector(currentLanguage);
  const { user } = useAuth();
  const { locations, isLoading, isError } = useFetchLocation();
  // const { data: userData } = useGetUserByIdQuery(user?.uid || '');
  // const userIsPremium = usePremiumStatus(user);
  // const userRole = userData?.role;
  // const { Banner } = useBanner();

  // const handleDetailsPageClick = (details: LocationItem) => {
  //   if (userIsPremium || userRole === UserRoles.ADMIN || userRole === UserRoles.PREMIUM) {
  //     setSelectedDetailsPageId(details.id as any);
  //     setShowMenu(!showMenu);
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     setUpgradeToPremium(!upgradeToPremium);
  //   }
  // };

  const handleDetailsPageClick = (details: LocationItem) => {
    if (user) {
      setSelectedDetailsPageId(details.id as any);
      setShowMenu(!showMenu);
      document.body.style.overflow = 'hidden';
    } else {
      window.location.href = '/proceed-to-signin';
    }
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = 'unset';
    // setUpgradeToPremium(false);
  };

  // the location name is dynamically added to search URL when the modal is opened
  const urlSearchParams = new URLSearchParams(window.location.search);
  const locationParam = urlSearchParams.get('location');

  useEffect(() => {
    if (locationParam && locations) {
      const location = locations.find((loc) => loc.name === locationParam);
      if (location) {
        setSelectedDetailsPageId(location.id as any);
        setShowMenu(true);
      }
    }
  }, [locations, locationParam]);

  // Sort the data alphabetically
  const sortedData = useMemo(() => {
    if (location) {
      return [...location].sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [locations]);

  const filteredLocations = useMemo(() => {
    return sortedData.filter((loc) => {
      const matchesCity = !selectedCity || selectedCity === 'All' || loc.city === selectedCity;
      const matchesDate = !selectedDate || selectedDate === 'All' || loc.color === selectedDate;
      const matchesQuery = loc.name.toLowerCase().includes(query.toLowerCase());
      return matchesCity && matchesDate && matchesQuery;
    });
  }, [locations, query, selectedCity, selectedDate, itemsToShow]);

  // handle scroll down to load more
  const handleScroll = () => {
    const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (bottom) {
      setItemsToShow((prevItems) => prevItems + 8);
    }
  }; // Adjust the debounce delay (in milliseconds) as needed

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [itemsToShow]);

  const totalItems = t(`search.numberTotal.${filteredLocations.length}`, { defaultValue: filteredLocations.length });
  // add default value to hide "search.numberTotal.0" string when the page is loaded

  return (
    <>
      <DropDownLayout>
        <CityDropdown onChange={(value: string | null) => setSelectedCity(value)} />
        <DateDropdown onChange={(value: string | null) => setSelectedDate(value)} />
      </DropDownLayout>

      <TotalLocationMobileLayout>
        <TotalLocations>{t('search.totalNumber')}&nbsp;</TotalLocations>
        {totalItems}
      </TotalLocationMobileLayout>

      <LocationsListMobile>
        {/* <Banner /> */}
        {isError && <NotFoundLayout>Error occurred while fetching data.</NotFoundLayout>}
        {filteredLocations.length === 0 && <NotFoundLayout>No data found</NotFoundLayout>}

        {isLoading && (
          <LoaderLayout>
            <Loader />
          </LoaderLayout>
        )}

        {!isLoading &&
          !isError &&
          filteredLocations.slice(0, itemsToShow).map((location, index) => (
            <BoxContainer key={`${location.id}-${index}`}>
              <BoxInnerLayout>
                <ImageWrapper onClick={() => handleDetailsPageClick(location)}>
                  {location?.images?.coverImage ? (
                    <Image src={location?.images?.coverImage} alt={location.name} />
                  ) : (
                    <PlaceholderImage src={placeholderImage} alt={location.name} />
                  )}
                </ImageWrapper>
                <ContentLayout>
                  <Title>
                    {t('form.name')} {t('search.vat')} {(location.locales as any)[language].name || location.name}
                  </Title>
                  <Content>
                    <b>{t('form.otherName')}</b> {(location.locales as any)[language].otherName || location.otherName}
                  </Content>
                  <Content>
                    <b>{t('form.city')}</b> {(location.locales as any)[language].city || location.city}
                  </Content>
                  <Content>
                    <b>{t('form.district')}</b> {(location.locales as any)[language].district || location.district}
                  </Content>
                  <Content>
                    <b>{t('form.commune')}</b> {(location.locales as any)[language].commune || location.commune}
                  </Content>
                  <Content>
                    <b>{t('form.latitude')}</b> {(location.geometry as any)?.location?.lat}
                  </Content>
                  <Content>
                    <b>{t('form.longitude')}</b> {(location.geometry as any)?.location?.lng}
                  </Content>
                </ContentLayout>
              </BoxInnerLayout>
              <ButtonLayout>
                <ButtonInnerLayout onClick={() => handleDetailsPageClick(location)}>
                  <Button>{t('button.plus')}</Button>
                  <MovingChevron color={colors.white} size={20} />
                </ButtonInnerLayout>
              </ButtonLayout>
            </BoxContainer>
          ))}
      </LocationsListMobile>
      <Footer />

      {selectedDetailsPageId && (
        <DetailsPageModal selectedDetailsPageId={selectedDetailsPageId} showMenu={showMenu} onClose={handleCloseMenu} />
      )}

      {/* {upgradeToPremium && <UpgradeToPremiumUserModal showMenu={upgradeToPremium} onClose={handleCloseMenu} />} */}
    </>
  );
};

export default FilteredDataMobile;

export const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
