import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FooterLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 4px;

  @media (min-width: 768px) {
    width: 300px;
  }
  @media (min-width: 1024px) {
    width: 560px;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
  }
`;

const TermsLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const CopyRight = styled.div`
  font-size: ${typography.fontSize.xs};
  color: ${colors.text_grey};
`;

const TermsLink = styled.a`
  font-size: ${typography.fontSize.xs};
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
  }
`;

const FooterMap = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <FooterLayout>
      <TermsLayout>
        <TermsLink href={'/privacy'}>{t('header.privacy')}</TermsLink>
        <TermsLink href={'/terms'}>{t('header.terms')}</TermsLink>
      </TermsLayout>
      <CopyRight>
        &copy; {year} {t('footer.company')} - La Pagode Khmère{' '}
      </CopyRight>
    </FooterLayout>
  );
};

export default FooterMap;
