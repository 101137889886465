import colors from '@styles/common/colors';
import styled from 'styled-components';

export const MarkerInLayout = styled.div`
position: absolute
z-index: 999;
top: 21px;
right 20px;
`;

export const ModalOverlay = styled.div<{ showMenu: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.showMenu ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
`;

export const ModalContainer = styled.div<{ showMenu: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  height: 230px;
  background: ${colors.white};
  border-radius: 6px;
  opacity: ${(props) => (props.showMenu ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;

  @media (min-width: 1440px) {
    width: 710px;
    height: 260px;
  }
`;

export const InfoIconLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 6px;
  cursor: pointer;
`;

export const MenuLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem;
  gap: 0.2rem;
`;

export const TextMarkerInfo = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  text-align: center;
  line-height: 16px;
  padding: 0 4px;
`;

export const MarkerLayout = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const CloseLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
`;

export const Content = styled.div`
  font-size: 15px;
  letter-spacing: 0.5px;
  color: ${colors.text_grey};

  @media (min-width: 1440px) {
    font-size: 18px;
  }
`;
