import AdminHeader from '@components/Header/HeaderAdmin';
import CreateUsers from '@components/Users/CreateUsers';
import CreateLocation from '@pages/CreateLocation';
import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import { NavProps } from '@utils/navigation-routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const DashboardDesktop: React.FC = () => {
  const location = useLocation();

  const [activePathname, setActivePathname] = useState<string>(location.pathname);
  const { t } = useTranslation();

  const navLinksDashboardMobile: NavProps[] = [
    {
      id: 1,
      label: t('header.users'),
      pathname: '/users',
    },
    {
      id: 2,
      label: t('header.location'),
      pathname: '/create-location',
    },
  ];

  const handleLinkClick = (pathname: string) => {
    setActivePathname(pathname);
  };

  return (
    <>
      <AdminHeader />
      <DashboardWrapper>
        <DashboardHeader>
          <Nav>
            {navLinksDashboardMobile.map((item) => (
              <NavLink
                key={item.id}
                isActive={activePathname === item.pathname}
                onClick={() => handleLinkClick(item.pathname)}
              >
                <NavItem>{item.label}</NavItem>
              </NavLink>
            ))}
          </Nav>
        </DashboardHeader>
        <MainContent>
          {activePathname === '/users' && <CreateUsers />} {activePathname === '/create-location' && <CreateLocation />}{' '}
        </MainContent>
      </DashboardWrapper>
    </>
  );
};

export default DashboardDesktop;

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    margin-top: 62px;
  }

  @media (min-width: 1380px) {
    margin-top: 70px;
  }
`;
const DashboardHeader = styled.div`
  width: 100%;
  background: ${colors.dark_tangerine};
  padding: 8px 0px;
`;
const MainContent = styled.div``;

type NavLinkProps = {
  isActive: boolean;
};

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: ${({ isActive }) => (isActive ? colors.white : 'transparent')};
    transform-origin: top left;
    transform: scaleX(${({ isActive }) => (isActive ? '1' : '0')});
    transition: transform 500ms ease-in-out;
  }

  ${({ isActive }) => (isActive ? 'text-decoration: underline underline-offset(8px);' : '')}
`;

const NavItem = styled.span`
  position: relative;
  font-size: ${sizes.lg};
  color: ${colors.white};
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: ${colors.white};
    transform-origin: top left;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media (min-width: 1440px) {
    font-size: ${sizes.xl};
  }
`;
