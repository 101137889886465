import { useState, useEffect, useCallback } from 'react';

interface UseRecentSearchesReturn {
  recentSearches: string[];
  saveRecentSearch: (search: string) => void;
  clearRecentSearches: () => void;
  loadRecentSearches: () => void;
  setRecentSearches: (searches: string[]) => void;
}

const useRecentSearches = (): UseRecentSearchesReturn => {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);

  useEffect(() => {
    loadRecentSearches();
  }, []);

  const loadRecentSearches = useCallback(async () => {
    try {
      const searches = await localStorage.getItem('recentSearches');
      const searchesArray = searches ? JSON.parse(searches) : [];

      setRecentSearches(searchesArray);
    } catch (error) {
      console.error('Error loading recent searches:', error);
    }
  }, []);

  const saveRecentSearch = useCallback(async (search: string) => {
    try {
      const searches = await localStorage.getItem('recentSearches');
      const searchesArray = searches ? JSON.parse(searches) : [];

      // Remove any duplicates
      const filteredSearches = searchesArray.filter((s: string) => s !== search);

      // Add the new search query to the beginning of the array
      const updatedSearches = [search, ...filteredSearches].slice(0, 5);

      // Save the updated array to localStorage
      await localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));

      // Update the state
      setRecentSearches(updatedSearches);
    } catch (error) {
      console.error('Error saving recent search:', error);
    }
  }, []);

  const clearRecentSearches = useCallback(async () => {
    try {
      await localStorage.removeItem('recentSearches');
      setRecentSearches([]);
    } catch (error) {
      console.error('Error clearing recent searches:', error);
    }
  }, []);

  return {
    recentSearches,
    saveRecentSearch,
    clearRecentSearches,
    setRecentSearches,
    loadRecentSearches,
  };
};

export default useRecentSearches;
