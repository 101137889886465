import colors from '@styles/common/colors';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoaderButton = styled.div`
  border: 0.2em solid ${colors.secondary_color.variant_two};
  border-top: 0.2em solid ${colors.dark_tangerine};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: ${spin} 0.6s linear infinite;
`;

export default LoaderButton;
