import LoaderButton from '@components/LoaderButton';
import colors from '@styles/common/colors';
import typography from '@styles/common/typography';
import styled from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export const SubmitButton = ({ onClick, text, isLoading }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      {isLoading ? <LoaderButton /> : null}
      <ButtonText>{text}</ButtonText>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background: ${colors.dark_tangerine};
  padding: 10px;
  border-radius: 4px;
  margin: 8px 0px;
  cursor: pointer;
  border: none;

  &:hover {
    background: ${colors.orange};
  }
`;
const ButtonText = styled.div`
  color: ${colors.white};
  font-size: ${typography.fontSize.md};
  letter-spacing: 0.5px;
`;
