import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LayersControl, MapContainer, TileLayer, GeoJSON, ZoomControl } from 'react-leaflet';
// import { LatLngBoundsExpression } from 'leaflet';
import { Autocomplete } from '@components/Maps/Autocomplete';
// import GeoLocation from '@components/Maps/Geolocation';
import MapZoomDebounce from '@components/Maps/MapZoomDebounce';
import L from 'leaflet';
import SidebarMenu from '@components/Maps/SidebarMenu';
import useWindowSize from '@hooks/useWindowSize';
import styled from 'styled-components';
import FooterMap from '@components/Footer/FooterMap';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import CookieConsentBannerMap from '@components/Cookie/CookieConsentBannerMap';
// import useBanner from '@hooks/useBanner';
import Seo from '@components/SEO/Seo';
import { useTranslation } from 'react-i18next';
import MapImage from '@assets/images/map.png';
// import AccountMenuDropdown from '@components/Profile/AccountMenuDropdown';
import colors from '@styles/common/colors';
// import { useAuth } from '@context/AuthProvider';
import { renderToString } from 'react-dom/server';
import MarkerMap from '@components/Maps/MarkerMap';

const { BaseLayer } = LayersControl;
const initial_position = { lat: 11.562108, lng: 104.888535 }; // Phnom Penh
const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const attribution = "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors";
const satelliteUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

const streetLayer = <TileLayer attribution={attribution} url={tileUrl} detectRetina={true} />;
const satelliteLayer = <TileLayer attribution="&copy; Esri" url={satelliteUrl} detectRetina={true} />;

// const cambodiaBounds: LatLngBoundsExpression = [
//   [10.415, 102.268], // Southwest coordinates
//   [14.69, 107.727], // Northeast coordinates
// ];

interface GeoJSONPoint {
  type: 'Point';
  coordinates: number[];
}

interface GeoJSONFeature {
  type: 'Feature';
  properties: {
    PROVINCE: string;
  };
  geometry: GeoJSONPoint;
}

interface GeoJSONFeatureCollection {
  type: 'FeatureCollection';
  features: GeoJSONFeature[];
}

// type GeoJsonObject = GeoJSONFeature | GeoJSONFeatureCollection;

const Map: React.FC = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(9);
  const mapRef = useRef<L.Map | null>(null);
  const size = useWindowSize();
  // const { Banner } = useBanner();
  const { t } = useTranslation();
  // const { user } = useAuth();

  const provincesData = [
    {
      name: 'Banteay Meanchey',
      coordinates: { lat: 13.78111, lng: 102.90959 },
    },
    {
      name: 'Battambang',
      coordinates: { lat: 13.000053, lng: 103.122907 },
    },
    {
      name: 'Kampong Cham',
      coordinates: { lat: 11.98829, lng: 105.453541 },
    },
    {
      name: 'Kampong Chhnang',
      coordinates: { lat: 12.141393, lng: 104.376274 },
    },
    {
      name: 'Kampong Speu',
      coordinates: { lat: 11.55511, lng: 104.159191 },
    },
    {
      name: 'Kampong Thom',
      coordinates: { lat: 12.80616, lng: 104.820001 },
    },
    {
      name: 'Kandal',
      coordinates: { lat: 11.381459, lng: 104.958882 },
    },
    {
      name: 'Kampot',
      coordinates: { lat: 10.741209, lng: 104.253092 },
    },
    {
      name: 'Kep',
      coordinates: { lat: 10.515235, lng: 104.332644 },
    },
    {
      name: 'Koh Kong',
      coordinates: { lat: 11.36531, lng: 103.2638 },
    },
    {
      name: 'Kratié',
      coordinates: { lat: 12.66063, lng: 105.999988 },
    },
    {
      name: 'Mondol Kiri',
      coordinates: { lat: 12.687943, lng: 106.891193 },
    },
    {
      name: 'Oddar Meanchey',
      coordinates: { lat: 14.14172, lng: 103.636269 },
    },
    {
      name: 'Pailin',
      coordinates: { lat: 12.877296, lng: 102.572557 },
    },
    {
      name: 'Phnom Penh',
      coordinates: { lat: 11.562108, lng: 104.907035 },
    },
    {
      name: 'Prey Veng',
      coordinates: { lat: 11.355114, lng: 105.358098 },
    },
    {
      name: 'Preah Vihear',
      coordinates: { lat: 13.7005797, lng: 104.8454619 },
    },
    {
      name: 'Pursat',
      coordinates: { lat: 12.465182, lng: 103.7012 },
    },
    {
      name: 'Ratanak Kiri',
      coordinates: { lat: 13.90333, lng: 106.92167 },
    },
    {
      name: 'Siem Reap',
      coordinates: { lat: 13.347097, lng: 103.894813 },
    },
    {
      name: 'Sihanoukville',
      coordinates: { lat: 10.60932, lng: 103.52958 },
    },
    {
      name: 'Stung Treng',
      coordinates: { lat: 13.836473, lng: 105.969988 },
    },
    {
      name: 'Svay Rieng',
      coordinates: { lat: 11.162722, lng: 105.70903 },
    },
    {
      name: 'Takeo',
      coordinates: { lat: 10.922152, lng: 104.728771 },
    },
    {
      name: 'Tboung Khmum',
      coordinates: { lat: 11.89109, lng: 105.64021 },
    },
  ];

  const handleZoomChange = useCallback(() => {
    setZoomLevel(mapRef.current?.getZoom() ?? 16);
  }, []);

  const handleMapReady = useCallback(() => {
    if (mapRef.current) {
      setZoomLevel(mapRef.current.getZoom());
    }
  }, []);

  const memoizedMarkers = useMemo(() => {
    return <MarkerMap />;
  }, []); // update only when dependencies change

  const onEachProvince = (feature: any, layer: L.Layer) => {
    // Set up the title for each province
    if (feature.properties && feature.properties.PROVINCE) {
      layer.bindPopup(feature.properties.PROVINCE);
    }
  };

  // Convert provincesData to a valid GeoJSON object
  const geoJsonData: GeoJSONFeatureCollection = {
    type: 'FeatureCollection',
    features: provincesData.map((province) => ({
      type: 'Feature',
      properties: {
        PROVINCE: province.name,
      },
      geometry: {
        type: 'Point',
        coordinates: [province.coordinates.lng, province.coordinates.lat],
      },
    })),
  };

  useEffect(() => {
    logEvent(analytics, 'MapPage');
  }, []);

  return (
    <>
      <Seo
        title={t('header.map') + ' | La Pagode Khmère'}
        description={t('metatag.search.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com/map'}
        image={MapImage}
      />
      {size.width && size.width >= 768 && <SidebarMenu />}
      {/* <Banner /> */}
      <MapContainer
        center={initial_position}
        zoom={zoomLevel}
        style={{ height: '100vh', width: '100vw' }}
        scrollWheelZoom={false}
        preferCanvas={true} // improve quality of map rendering
        whenReady={handleMapReady}
        zoomControl={false}
        // maxBounds={cambodiaBounds} // Set the max bounds of the map to Cambodia
        // maxBoundsViscosity={1.0} // Enforce the bounds when user pans or zooms out
      >
        <ZoomControl position="topright" />
        <Autocomplete />
        {/* {user ? (
          <ProfileLayout>
            <AccountMenuDropdown />
          </ProfileLayout>
        ) : null} */}

        <GeoJSON
          data={geoJsonData}
          onEachFeature={onEachProvince}
          pointToLayer={(feature, latlng) => {
            return L.marker(latlng, {
              icon: L.divIcon({
                className: 'province-icon', // Define a CSS class for the icon styling
                html: renderToString(<CustomIcon>{feature.properties.PROVINCE}</CustomIcon>),
              }),
            });
          }}
        />

        {/* improve quality of map rendering by adding detechRetina={true} */}
        <TileLayer attribution={attribution} url={tileUrl} detectRetina={true} />
        <LayersControl position="topright">
          <BaseLayer name="Street Map" checked>
            {streetLayer}
          </BaseLayer>
          <BaseLayer name="Satellite Map">{satelliteLayer}</BaseLayer>
        </LayersControl>
        <MapZoomDebounce onZoomChange={handleZoomChange} />
        {/* <GeoLocation /> */}
        {memoizedMarkers}
        <FooterLayout>
          <FooterMap />
        </FooterLayout>
        <CookieConsentBannerMap />
      </MapContainer>
    </>
  );
};
export default Map;

const FooterLayout = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 0;
  z-index: 998;

  @media (min-width: 768px) {
    display: block;
    bottom: 0;
    left: 80px;
  }
`;

// const ProfileLayout = styled.div`
//   position: absolute;
//   top: 140px;
//   right: 10px;
//   z-index: 999;
//   background: ${colors.white};
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const CustomIcon = styled.div`
  color: ${colors.text_grey};
  font-weight: bold;
  padding: 5px;
  font-size: 14px;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;
