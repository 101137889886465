import colors from '@styles/common/colors';
import sizes from '@styles/common/sizes';
import styled from 'styled-components';

interface InputLayoutProps {
  error: boolean;
}

export const InputLayout = styled.div`
  width: 100%;
  position: relative;
`;

export const Input = styled.input<InputLayoutProps>`
  width: 100%;
  color: ${colors.secondary_color.regular};
  padding: 10px;
  border: 1px solid ${(prop) => (prop.error ? colors.red : colors.secondary_color.regular)};
  border-radius: 4px;
  font-size: ${sizes.md};
  &::placeholder {
    color: ${colors.secondary_color.variant_one};
    font-size: ${sizes.lg};
  }
  &:focus {
    outline: none;
    border: 1px solid ${(prop) => (prop.error ? colors.dark_tangerine : colors.dark_tangerine)};
    box-shadow: 0 0 2px ${colors.dark_tangerine};
  }
`;

export const InputButton = styled.button`
  position: absolute;
  top: 10px;
  right: 2px;
  background: none;
  border: none;
  cursor: pointer;
`;
