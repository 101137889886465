export const passwordRegex =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})(^\S*$).*$/;
export const nameRegex = /^[a-zA-Z]/;
export const atLeastOneUpperCase = /[A-Z]/;
export const atLeastOneLowerCase = /[a-z]/;
export const atLeastOneNumberic = /[0-9]/;
export const atLeastOneSpecialCharacter = /[!@#$%^&*()\-_=+{};:,<.>]/;
export const atLeastCharactersOrMore = /.{8,}/;
export const whitespaceRegex = /^\S*$/;

export const passwordRegexMessage =
  'Password must contain at least 8 characters, one uppercase, one number and one special case character (e.g. !@#$%^&*)';

export const nameRegexMessage = 'Username must contain only letter';
