import { AiOutlineHome, AiOutlineSetting } from 'react-icons/ai';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { GoLocation } from 'react-icons/go';
import { BsSearch, BsPeople, BsPerson } from 'react-icons/bs';
import { RiContactsLine } from 'react-icons/ri';
import { GiShakingHands } from 'react-icons/gi';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import { GoLaw } from 'react-icons/go';

export type NavProps = {
  id: number;
  label: string;
  pathname: string;
};

export const icons = [
  AiOutlineHome,
  IoMdInformationCircleOutline,
  BsPeople,
  BsSearch,
  GoLocation,
  RiContactsLine,
  GiShakingHands,
  MdOutlinePrivacyTip,
  GoLaw,
];

export const accountIcons = [BsPerson, AiOutlineSetting, GiShakingHands];
