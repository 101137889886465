import { LocationForm } from '@components/Forms/LocationForm';
import React from 'react';
import styled from 'styled-components';

const CreateLocation: React.FC = () => {
  return (
    <>
      <Container>
        <LocationForm />
      </Container>
    </>
  );
};

export default CreateLocation;

const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
  padding: 10vh 0;
`;
