import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import Header from '@components/Header/Header';
import Seo from '@components/SEO/Seo';
import SponsorContainer from '@components/Sponsors/SponsorContainer';
// import SponsorLogos from '@components/Sponsors/SponsorLogos';
import colors from '@styles/common/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import VatImage from '@assets/images/30804-1-Moha-Leap-(274).jpg';
import Footer from '@components/Footer/Footer';
import typography from '@styles/common/typography';
import BackgroundImage from '@assets/donation/Moha-Leap-buddha.webp';

const Sponsors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t('partner.donation') + ' - La Pagode Khmère'}
        description={t('metatag.sponsors.description')}
        type={'website'}
        name={'La Pagode Khmère'}
        url={'https://lapagodekhmere.com'}
        image={VatImage}
      />
      <Header />
      <Background />
      <Layout>
        <Title>{t('partner.donation')}</Title>
        <Content>{t('partner.title-sponsors')}</Content>
        <Content>{t('account.profile-info')}</Content>
        <Content>{t('partner.donation-sponsors1')}</Content>
        <SponsorContainer />
      </Layout>
      <Footer />
      <CookieConsentBanner />
    </>
  );
};

export default Sponsors;

const Background = styled.div`
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${BackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 15px 50px 15px;

  @media (min-width: 320px) {
    padding: 30px 30px;
  }
  @media (min-width: 540px) {
    padding: 30px 30px 50px 30px;
  }
  @media (min-width: 768px) {
    padding: 30px 15px 50px 15px;
  }
  @media (min-width: 1024px) {
    padding: 30px 150px 50px 150px;
  }
  @media (min-width: 1280px) {
    padding: 30px 250px 50px 250px;
  }
  @media (min-width: 1440px) {
    padding: 30px 400px 50px 400px;
  }
  @media (min-width: 1920px) {
    padding: 30px 850px 50px 850px;
  }
`;

const Title = styled.div`
  font-size: ${typography.fontSize['3xl']};
  font-weight: bold;
  color: ${colors.dark_tangerine};
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize['4xl']};
  }
`;

const Content = styled.div`
  font-size: ${typography.fontSize.md};
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    font-size: ${typography.fontSize.lg};
    line-height: 26px;
  }
  @media (min-width: 1440px) {
    font-size: ${typography.fontSize.xl};
    line-height: 30px;
  }
`;

// const SponsorsLayout = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   margin: 30px 0;
// `;
