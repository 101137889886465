import { useCallback, useState } from 'react';
import { FormLayout, Input, TextArea } from './styles/form.style';
import LocationFormInput from './LocationFormInput';
import LocationTextAreaInput from './LocationTextAreaInput';
import { useTranslation } from 'react-i18next';
import { LocationItem, initialValue } from '@api/location.interface';
import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '@database/firebase';
import { Toast } from '@utils/Toast';
import { nanoid } from 'nanoid';
import useFetchLocation from '@hooks/useFetchLocations';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import UploadFileInput from './UploadFileInput';
import styled from 'styled-components';
import ReactAudioPlayer from 'react-audio-player';
import { PlaceholderImage } from '@components/Search/styles/filtredData.style';
import placeholderImage from '@assets/images/image-placeholder.png';

export const LocationForm = () => {
  const [formData, setFormData] = useState<LocationItem>(initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { locations, setLocations } = useFetchLocation();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        const input = {
          id: isEditing ? formData.id : nanoid(),
          provinceOrCityCode: formData.provinceOrCityCode,
          geometry: {
            location: {
              lat: formData.geometry?.location?.lat ?? 0,
              lng: formData.geometry?.location?.lng ?? 0,
            },
          },
          name: formData.name,
          otherName: formData.otherName,
          description: formData.description,
          country: formData.country,
          city: formData.city,
          district: formData.district,
          commune: formData.commune,
          color: formData.color,
          constructionDate: formData.constructionDate,
          constructionDateSala: formData.constructionDateSala,
          decorDate: formData.decorDate,
          ancientPainting: formData.ancientPainting,
          lastVisit: formData.lastVisit,
          archives: formData.archives,
          khmerRougePeriod: formData.khmerRougePeriod,
          restoration: formData.restoration,
          exterior: formData.exterior,
          interior: formData.interior,
          bay: formData.bay,
          particularity: formData.particularity,
          audioText: formData.audioText,
          decorText: formData.decorText,
          audioFile: formData.audioFile,
          coverImage: formData.coverImage,
          painting: formData.painting,
          painting1: formData.painting1,
          painting2: formData.painting2,
          painting3: formData.painting3,
          painting4: formData.painting4,
          titlePainting: formData.painting,
          titlePainting1: formData.painting1,
          titlePainting2: formData.painting2,
          titlePainting3: formData.painting3,
          titlePainting4: formData.painting4,
          sanctuaryDrawing: formData.sanctuaryDrawing,
          sanctuaryImageSide1: formData.sanctuaryImageSide1,
          sanctuaryImageSide2: formData.sanctuaryImageSide2,
          sanctuaryImageSide3: formData.sanctuaryImageSide3,
          sanctuaryImageSide4: formData.sanctuaryImageSide4,
          titleSanctuary1: formData.titleSanctuary1,
          titleSanctuary2: formData.titleSanctuary2,
          titleSanctuary3: formData.titleSanctuary3,
          titleSanctuary4: formData.titleSanctuary4,
          images: formData.images,
          locales: formData.locales,
        };

        let result: any;

        if (isEditing) {
          const locationRef = doc(firestore, 'locations', input.id);
          const locationSnapshot = await getDoc(locationRef);

          if (locationSnapshot.exists()) {
            await updateDoc(locationRef, input);
          }
        } else {
          const locationsCollection = collection(firestore, 'locations');
          const docRef = await addDoc(locationsCollection, input);
          result = { ...input, id: docRef.id }; // Assign the generated document ID as the result
        }

        if (result) {
          if (isEditing) {
            const updatedLocations = locations.map((location) => (location.id === formData.id ? formData : location));
            setLocations(updatedLocations);
          } else {
            const newLocations = [...locations, result];
            setLocations(newLocations);
          }
        }

        const toast = new Toast(isEditing ? 'Location successfully updated! 🚀' : 'Location successfully created! 🚀');
        toast.showSuccess();

        setFormData(initialValue);
        setIsEditing(false);
      } catch (error) {
        console.log(error);
        const toast = new Toast('Error occurred!');
        toast.showError();
      }
    },
    [formData, isEditing, firestore, locations]
  );

  const handleUpdate = useCallback((location: LocationItem) => {
    setIsEditing(true);
    setFormData(location);
  }, []);

  const handleDelete = async (locationId: string) => {
    try {
      // Delete the location from the browser
      const newLocations = locations.filter((location) => location.id !== locationId);
      setLocations(newLocations);

      // Check if the document exists
      const locationRef = doc(firestore, 'locations', locationId);
      // console.log('Location ID:', locationId);

      const locationSnapshot = await getDoc(locationRef);
      // console.log('Location Snapshot:', locationSnapshot.data());

      if (!locationSnapshot.exists) {
        throw new Error('Document not found');
      }

      // Delete the location from the Firestore database
      await deleteDoc(locationRef);

      const toast = new Toast('Location successfully deleted! 🗑️');
      toast.showSuccess();
    } catch (error) {
      console.log(error);
      const toast = new Toast('Error occurred!');
      toast.showError();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedFormData = { ...formData };
    const isLocation = name.startsWith('geometry.location.');

    if (isLocation) {
      const locationKey = name.replace('geometry.location.', '');
      const location: { [key: string]: number } = formData.geometry?.location || {};
      location[locationKey] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      updatedFormData = { ...updatedFormData, geometry: { ...formData.geometry, location } as any };
    } else {
      updatedFormData = { ...updatedFormData, [name]: value };
    }

    setFormData(updatedFormData);
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLocaleChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      locale: keyof typeof formData.locales,
      field: string,
      value: string
    ) => {
      setFormData((prevValues) => ({
        ...prevValues,
        locales: {
          ...prevValues.locales,
          [locale]: {
            ...prevValues.locales[locale],
            [field]: value,
          },
        },
      }));
    },
    [setFormData]
  );

  const handleFileUpload =
    (fieldName: string, location: LocationItem) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return; // if file is null, exit early

      const fileExtension = file.name.split('.').pop();
      const fileName = file.name.replace(/\.[^/.]+$/, '');
      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString().replace(/\//g, '-')}-${currentDate
        .toLocaleTimeString()
        .replace(/:/g, ':')}`;

      try {
        // Store files in images folder
        const imageKey = ref(storage, `images/${nanoid()}-${formattedDate}-${fileName}.${fileExtension}`);
        // Upload file
        const snapshot = await uploadBytes(imageKey, file);
        // Get file url
        const url = await getDownloadURL(snapshot.ref);
        // handle the URL or update state as needed
        console.log(url);

        // Save the image URL to Firestore Database coverImage in locations collection
        const updatedImages = {
          ...location.images,
          [fieldName]: url,
        };

        // Save the updated images object to Firestore
        if (location.id) {
          const locationDocRef = doc(firestore, 'locations', location.id);
          await updateDoc(locationDocRef, {
            images: updatedImages,
          });
        }

        setFormData((prevState) => ({
          ...prevState,
          images: updatedImages,
        }));

        // Optional: Save the image URL to Firestore Database to coverImages collection
        // const imageCollectionRef = collection(firestore, 'coverImages');
        // await addDoc(imageCollectionRef, {
        //   id: nanoid(),
        //   url: url,
        //   createdAt: new Date(),
        // });
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <FormLayout>
      <form onSubmit={handleSubmit}>
        <LocationFormInput
          label={t('form.provinceOrCityCode')}
          name="provinceOrCityCode"
          value={formData.provinceOrCityCode ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.latitude')}
          name="geometry.location.lat"
          value={formData.geometry?.location?.lat ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.longitude')}
          name="geometry.location.lng"
          value={formData.geometry?.location?.lng ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.color')}
          name="color"
          value={formData.color ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.name')}
          name="name"
          value={formData.name ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.otherName')}
          name="otherName"
          value={formData.otherName ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.description')}
          name="description"
          value={formData.description ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.country')}
          name="country"
          value={formData.country ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.city')}
          name="city"
          value={formData.city ?? ''}
          onChange={handleInputChange}
        />

        <LocationFormInput
          label={t('form.district')}
          name="district"
          value={formData.district ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.commune')}
          name="commune"
          value={formData.commune ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.lastVisit')}
          name="lastVisit"
          value={formData.lastVisit ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.constructionDate')}
          name="constructionDate"
          value={formData.constructionDate ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.constructionDateSala')}
          name="constructionDateSala"
          value={formData.constructionDateSala ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.decorDate')}
          name="decorDate"
          value={formData.decorDate ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.ancientPainting')}
          name="ancientPainting"
          value={formData.ancientPainting}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.archives')}
          name="archives"
          value={formData.archives ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.khmerRougePeriod')}
          name="khmerRougePeriod"
          value={formData.khmerRougePeriod ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.restoration')}
          name="restoration"
          value={formData.restoration ?? ''}
          onChange={handleInputChange}
        />
        <LocationTextAreaInput
          label={t('form.bay')}
          name="bay"
          value={formData.bay ?? ''}
          onChange={handleTextAreaChange}
        />
        <LocationTextAreaInput
          label={t('form.particularity')}
          name="particularity"
          value={formData.particularity ?? ''}
          onChange={handleTextAreaChange}
        />

        <LocationTextAreaInput
          label={t('form.exterior')}
          name="exterior"
          value={formData.exterior ?? ''}
          onChange={handleTextAreaChange}
        />
        <LocationTextAreaInput
          label={t('form.interior')}
          name="interior"
          value={formData.interior ?? ''}
          onChange={handleTextAreaChange}
        />
        <LocationTextAreaInput
          label={t('form.audioText')}
          name="audioText"
          value={formData.audioText ?? ''}
          onChange={handleTextAreaChange}
        />

        {/* Upload image to Firebase  */}
        <UploadFileInput
          fieldName="coverImage"
          label={t('form.coverImage')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="audioFile"
          label={t('form.audioFile')}
          accept="audio/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="painting"
          label={t('form.painting')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="painting1"
          label={t('form.painting1')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="painting2"
          label={t('form.painting2')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="painting3"
          label={t('form.painting3')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="painting4"
          label={t('form.painting4')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <LocationFormInput
          label={t('form.titlePainting')}
          name="titlePainting"
          value={formData.titlePainting ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titlePainting1')}
          name="titlePainting1"
          value={formData.titlePainting1 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titlePainting2')}
          name="titlePainting2"
          value={formData.titlePainting2 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titlePainting3')}
          name="titlePainting3"
          value={formData.titlePainting3 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titlePainting4')}
          name="titlePainting4"
          value={formData.titlePainting4 ?? ''}
          onChange={handleInputChange}
        />
        <LocationTextAreaInput
          label={t('form.decorText')}
          name="decorText"
          value={formData.decorText ?? ''}
          onChange={handleTextAreaChange}
        />
        <UploadFileInput
          fieldName="sanctuaryDrawing"
          label={t('form.sanctuaryDrawing')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="sanctuaryImageSide1"
          label={t('form.sanctuaryImage1')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="sanctuaryImageSide2"
          label={t('form.sanctuaryImage2')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="sanctuaryImageSide3"
          label={t('form.sanctuaryImage3')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <UploadFileInput
          fieldName="sanctuaryImageSide4"
          label={t('form.sanctuaryImage4')}
          accept="image/*"
          handleFileUpload={handleFileUpload}
          location={formData}
        />
        <LocationFormInput
          label={t('form.titleSanctuary1')}
          name="titleSanctuary1"
          value={formData.titleSanctuary1 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titleSanctuary2')}
          name="titleSanctuary2"
          value={formData.titleSanctuary2 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titleSanctuary3')}
          name="titleSanctuary3"
          value={formData.titleSanctuary3 ?? ''}
          onChange={handleInputChange}
        />
        <LocationFormInput
          label={t('form.titleSanctuary4')}
          name="titleSanctuary4"
          value={formData.titleSanctuary4 ?? ''}
          onChange={handleInputChange}
        />

        {/* English */}
        <div>
          <h2>{t('form.formTitleEn')}</h2>
          <label>{t('form.name')}</label>
          <Input
            type="text"
            name="loales.en.name"
            value={formData.locales.en.name ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'name', e.target.value)}
          />
          <label>{t('form.otherName')}</label>
          <Input
            type="text"
            name="loales.en.otherName"
            value={formData.locales.en.otherName ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'otherName', e.target.value)}
          />
          <label>{t('form.description')}</label>
          <Input
            type="text"
            name="loales.en.description"
            value={formData.locales.en.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'description', e.target.value)}
          />
          <label>{t('form.country')}</label>
          <Input
            type="text"
            name="loales.en.country"
            value={formData.locales.en.country ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'country', e.target.value)}
          />
          <label>{t('form.city')}</label>
          <Input
            type="text"
            name="loales.en.city"
            value={formData.locales.en.city ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'city', e.target.value)}
          />
          <label>{t('form.district')}</label>
          <Input
            type="text"
            name="loales.en.district"
            value={formData.locales.en.district ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'district', e.target.value)}
          />
          <label>{t('form.commune')}</label>
          <Input
            type="text"
            name="loales.en.commune"
            value={formData.locales.en.commune ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'commune', e.target.value)}
          />

          <label>{t('form.lastVisit')}</label>
          <Input
            type="text"
            name="loales.en.lastVisit"
            value={formData.locales.en.lastVisit ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'lastVisit', e.target.value)}
          />
          <label>{t('form.constructionDate')}</label>
          <Input
            type="text"
            name="loales.en.constructionDate"
            value={formData.locales.en.constructionDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'constructionDate', e.target.value)}
          />
          <label>{t('form.constructionDateSala')}</label>
          <Input
            type="text"
            name="loales.en.constructionDateSala"
            value={formData.locales.en.constructionDateSala ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'constructionDateSala', e.target.value)}
          />
          <label>{t('form.decorDate')}</label>
          <Input
            type="text"
            name="loales.en.decorDate"
            value={formData.locales.en.decorDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'decorDate', e.target.value)}
          />
          <label>{t('form.archives')}</label>
          <Input
            type="text"
            name="loales.en.archives"
            value={formData.locales.en.archives ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'archives', e.target.value)}
          />
          <label>{t('form.khmerRougePeriod')}</label>
          <Input
            type="text"
            name="loales.en.khmerRougePeriod"
            value={formData.locales.en.khmerRougePeriod ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'khmerRougePeriod', e.target.value)}
          />
          <label>{t('form.restoration')}</label>
          <Input
            type="text"
            name="loales.en.restoration"
            value={formData.locales.en.restoration ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'restoration', e.target.value)}
          />
          <label>{t('form.titlePainting')}</label>
          <Input
            type="text"
            name="loales.en.titlePainting"
            value={formData.locales.en.titlePainting ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titlePainting', e.target.value)}
          />
          <label>{t('form.titlePainting1')}</label>
          <Input
            type="text"
            name="loales.en.titlePainting1"
            value={formData.locales.en.titlePainting1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titlePainting1', e.target.value)}
          />
          <label>{t('form.titlePainting2')}</label>
          <Input
            type="text"
            name="loales.en.titlePainting2"
            value={formData.locales.en.titlePainting2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titlePainting2', e.target.value)}
          />
          <label>{t('form.titlePainting3')}</label>
          <Input
            type="text"
            name="loales.en.titlePainting3"
            value={formData.locales.en.titlePainting3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titlePainting3', e.target.value)}
          />
          <label>{t('form.titlePainting4')}</label>
          <Input
            type="text"
            name="loales.en.titlePainting4"
            value={formData.locales.en.titlePainting4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titlePainting4', e.target.value)}
          />
          <label>{t('form.titleSanctuary1')}</label>
          <Input
            type="text"
            name="loales.en.titleSanctuary1"
            value={formData.locales.en.titleSanctuary1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titleSanctuary1', e.target.value)}
          />
          <label>{t('form.titleSanctuary2')}</label>
          <Input
            type="text"
            name="loales.en.titleSanctuary2"
            value={formData.locales.en.titleSanctuary2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titleSanctuary2', e.target.value)}
          />
          <label>{t('form.titleSanctuary3')}</label>
          <Input
            type="text"
            name="loales.en.titleSanctuary3"
            value={formData.locales.en.titleSanctuary3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titleSanctuary3', e.target.value)}
          />
          <label>{t('form.titleSanctuary4')}</label>
          <Input
            type="text"
            name="loales.en.titleSanctuary4"
            value={formData.locales.en.titleSanctuary4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'titleSanctuary4', e.target.value)}
          />
          <label>{t('form.particularity')}</label>
          <TextArea
            name="loales.en.particularity"
            value={formData.locales.en.particularity ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'particularity', e.target.value)}
          />
          <label>{t('form.bay')}</label>
          <TextArea
            name="loales.en.bay"
            value={formData.locales.en.bay ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'bay', e.target.value)}
          />
          <label>{t('form.exterior')}</label>
          <TextArea
            name="loales.en.exterior"
            value={formData.locales.en.exterior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'exterior', e.target.value)}
          />
          <label>{t('form.interior')}</label>
          <TextArea
            name="loales.en.interior"
            value={formData.locales.en.interior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'interior', e.target.value)}
          />
          <label>{t('form.decorText')}</label>
          <TextArea
            name="loales.en.decorText"
            value={formData.locales.en.decorText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'decorText', e.target.value)}
          />
          <label>{t('form.audioText')}</label>
          <TextArea
            name="loales.en.audioText"
            value={formData.locales.en.audioText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'en', 'audioText', e.target.value)}
          />
        </div>

        {/* French */}
        <div>
          <h2>{t('form.formTitleFr')}</h2>
          <label>{t('form.name')}</label>
          <Input
            type="text"
            name="loales.fr.name"
            value={formData.locales.fr.name ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'name', e.target.value)}
          />
          <label>{t('form.otherName')}</label>
          <Input
            type="text"
            name="loales.fr.otherName"
            value={formData.locales.fr.otherName ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'otherName', e.target.value)}
          />
          <label>{t('form.description')}</label>
          <Input
            type="text"
            name="loales.fr.description"
            value={formData.locales.fr.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'description', e.target.value)}
          />
          <label>{t('form.country')}</label>
          <Input
            type="text"
            name="loales.fr.country"
            value={formData.locales.fr.country ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'country', e.target.value)}
          />
          <label>{t('form.city')}</label>
          <Input
            type="text"
            name="loales.fr.city"
            value={formData.locales.fr.city ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'city', e.target.value)}
          />
          <label>{t('form.district')}</label>
          <Input
            type="text"
            name="loales.fr.district"
            value={formData.locales.fr.district ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'district', e.target.value)}
          />
          <label>{t('form.commune')}</label>
          <Input
            type="text"
            name="loales.fr.commune"
            value={formData.locales.fr.commune ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'commune', e.target.value)}
          />
          <label>{t('form.lastVisit')}</label>
          <Input
            type="text"
            name="loales.fr.lastVisit"
            value={formData.locales.fr.lastVisit ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'lastVisit', e.target.value)}
          />
          <label>{t('form.constructionDate')}</label>
          <Input
            type="text"
            name="loales.fr.constructionDate"
            value={formData.locales.fr.constructionDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'constructionDate', e.target.value)}
          />
          <label>{t('form.constructionDateSala')}</label>
          <Input
            type="text"
            name="loales.fr.constructionDateSala"
            value={formData.locales.fr.constructionDateSala ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'constructionDateSala', e.target.value)}
          />
          <label>{t('form.decorDate')}</label>
          <Input
            type="text"
            name="loales.fr.decorDate"
            value={formData.locales.fr.decorDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'decorDate', e.target.value)}
          />
          <label>{t('form.archives')}</label>
          <Input
            type="text"
            name="loales.fr.archives"
            value={formData.locales.fr.archives ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'archives', e.target.value)}
          />
          <label>{t('form.khmerRougePeriod')}</label>
          <Input
            type="text"
            name="loales.fr.khmerRougePeriod"
            value={formData.locales.fr.khmerRougePeriod ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'khmerRougePeriod', e.target.value)}
          />
          <label>{t('form.restoration')}</label>
          <Input
            type="text"
            name="loales.fr.restoration"
            value={formData.locales.fr.restoration ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'restoration', e.target.value)}
          />
          <label>{t('form.titlePainting')}</label>
          <Input
            type="text"
            name="loales.fr.titlePainting"
            value={formData.locales.fr.titlePainting ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titlePainting', e.target.value)}
          />
          <label>{t('form.titlePainting1')}</label>
          <Input
            type="text"
            name="loales.fr.titlePainting1"
            value={formData.locales.fr.titlePainting1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titlePainting1', e.target.value)}
          />
          <label>{t('form.titlePainting2')}</label>
          <Input
            type="text"
            name="loales.fr.titlePainting2"
            value={formData.locales.fr.titlePainting2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titlePainting2', e.target.value)}
          />
          <label>{t('form.titlePainting3')}</label>
          <Input
            type="text"
            name="loales.fr.titlePainting3"
            value={formData.locales.fr.titlePainting3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titlePainting3', e.target.value)}
          />
          <label>{t('form.titlePainting4')}</label>
          <Input
            type="text"
            name="loales.fr.titlePainting4"
            value={formData.locales.fr.titlePainting4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titlePainting4', e.target.value)}
          />
          <label>{t('form.titleSanctuary1')}</label>
          <Input
            type="text"
            name="loales.fr.titleSanctuary1"
            value={formData.locales.fr.titleSanctuary1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titleSanctuary1', e.target.value)}
          />
          <label>{t('form.titleSanctuary2')}</label>
          <Input
            type="text"
            name="loales.fr.titleSanctuary2"
            value={formData.locales.fr.titleSanctuary2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titleSanctuary2', e.target.value)}
          />
          <label>{t('form.titleSanctuary3')}</label>
          <Input
            type="text"
            name="loales.fr.titleSanctuary3"
            value={formData.locales.fr.titleSanctuary3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titleSanctuary3', e.target.value)}
          />
          <label>{t('form.titleSanctuary4')}</label>
          <Input
            type="text"
            name="loales.fr.titleSanctuary4"
            value={formData.locales.fr.titleSanctuary4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'titleSanctuary4', e.target.value)}
          />
          <label>{t('form.particularity')}</label>
          <TextArea
            name="loales.fr.particularity"
            value={formData.locales.fr.particularity ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'particularity', e.target.value)}
          />
          <label>{t('form.bay')}</label>
          <TextArea
            name="loales.fr.bay"
            value={formData.locales.fr.bay ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'bay', e.target.value)}
          />
          <label>{t('form.exterior')}</label>
          <TextArea
            name="loales.fr.exterior"
            value={formData.locales.fr.exterior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'exterior', e.target.value)}
          />
          <label>{t('form.interior')}</label>
          <TextArea
            name="loales.fr.interior"
            value={formData.locales.fr.interior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'interior', e.target.value)}
          />
          <label>{t('form.decorText')}</label>
          <TextArea
            name="loales.fr.decorText"
            value={formData.locales.fr.decorText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'decorText', e.target.value)}
          />
          <label>{t('form.audioText')}</label>
          <TextArea
            name="loales.fr.audioText"
            value={formData.locales.fr.audioText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'fr', 'audioText', e.target.value)}
          />
        </div>

        {/* Khmer  */}
        <div>
          <h2>{t('form.formTitleKh')}</h2>
          <label>{t('form.name')}</label>
          <Input
            type="text"
            name="loales.kh.name"
            value={formData.locales.kh.name ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'name', e.target.value)}
          />
          <label>{t('form.otherName')}</label>
          <Input
            type="text"
            name="loales.kh.otherName"
            value={formData.locales.kh.otherName ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'otherName', e.target.value)}
          />
          <label>{t('form.description')}</label>
          <Input
            type="text"
            name="loales.kh.description"
            value={formData.locales.kh.description ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'description', e.target.value)}
          />
          <label>{t('form.country')}</label>
          <Input
            type="text"
            name="loales.kh.country"
            value={formData.locales.kh.country ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'country', e.target.value)}
          />
          <label>{t('form.city')}</label>
          <Input
            type="text"
            name="loales.kh.city"
            value={formData.locales.kh.city ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'city', e.target.value)}
          />
          <label>{t('form.district')}</label>
          <Input
            type="text"
            name="loales.kh.district"
            value={formData.locales.kh.district ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'district', e.target.value)}
          />
          <label>{t('form.commune')}</label>
          <Input
            type="text"
            name="loales.kh.commune"
            value={formData.locales.kh.commune ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'commune', e.target.value)}
          />
          <label>{t('form.lastVisit')}</label>
          <Input
            type="text"
            name="loales.kh.lastVisit"
            value={formData.locales.kh.lastVisit ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'lastVisit', e.target.value)}
          />
          <label>{t('form.constructionDate')}</label>
          <Input
            type="text"
            name="loales.kh.constructionDate"
            value={formData.locales.kh.constructionDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'constructionDate', e.target.value)}
          />
          <label>{t('form.constructionDateSala')}</label>
          <Input
            type="text"
            name="loales.kh.constructionDateSala"
            value={formData.locales.kh.constructionDateSala ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'constructionDateSala', e.target.value)}
          />
          <label>{t('form.decorDate')}</label>
          <Input
            type="text"
            name="loales.kh.decorDate"
            value={formData.locales.kh.decorDate ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'decorDate', e.target.value)}
          />
          <label>{t('form.archives')}</label>
          <Input
            type="text"
            name="loales.kh.archives"
            value={formData.locales.kh.archives ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'archives', e.target.value)}
          />
          <label>{t('form.khmerRougePeriod')}</label>
          <Input
            type="text"
            name="loales.kh.khmerRougePeriod"
            value={formData.locales.kh.khmerRougePeriod ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'khmerRougePeriod', e.target.value)}
          />
          <label>{t('form.restoration')}</label>
          <Input
            type="text"
            name="loales.kh.restoration"
            value={formData.locales.kh.restoration ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'restoration', e.target.value)}
          />
          <label>{t('form.titlePainting')}</label>
          <Input
            type="text"
            name="loales.kh.titlePainting"
            value={formData.locales.kh.titlePainting ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titlePainting', e.target.value)}
          />
          <label>{t('form.titlePainting1')}</label>
          <Input
            type="text"
            name="loales.kh.titlePainting1"
            value={formData.locales.kh.titlePainting1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titlePainting1', e.target.value)}
          />
          <label>{t('form.titlePainting2')}</label>
          <Input
            type="text"
            name="loales.kh.titlePainting2"
            value={formData.locales.kh.titlePainting2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titlePainting2', e.target.value)}
          />
          <label>{t('form.titlePainting3')}</label>
          <Input
            type="text"
            name="loales.kh.titlePainting3"
            value={formData.locales.kh.titlePainting3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titlePainting3', e.target.value)}
          />
          <label>{t('form.titlePainting4')}</label>
          <Input
            type="text"
            name="loales.kh.titlePainting4"
            value={formData.locales.kh.titlePainting4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titlePainting4', e.target.value)}
          />
          <label>{t('form.titleSanctuary1')}</label>
          <Input
            type="text"
            name="loales.kh.titleSanctuary1"
            value={formData.locales.kh.titleSanctuary1 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titleSanctuary1', e.target.value)}
          />
          <label>{t('form.titleSanctuary2')}</label>
          <Input
            type="text"
            name="loales.kh.titleSanctuary2"
            value={formData.locales.kh.titleSanctuary2 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titleSanctuary2', e.target.value)}
          />
          <label>{t('form.titleSanctuary3')}</label>
          <Input
            type="text"
            name="loales.kh.titleSanctuary3"
            value={formData.locales.kh.titleSanctuary3 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titleSanctuary3', e.target.value)}
          />
          <label>{t('form.titleSanctuary4')}</label>
          <Input
            type="text"
            name="loales.kh.titleSanctuary4"
            value={formData.locales.kh.titleSanctuary4 ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'titleSanctuary4', e.target.value)}
          />
          <label>{t('form.particularity')}</label>
          <TextArea
            name="loales.kh.particularity"
            value={formData.locales.kh.particularity ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'particularity', e.target.value)}
          />
          <label>{t('form.bay')}</label>
          <TextArea
            name="loales.kh.bay"
            value={formData.locales.kh.bay ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'bay', e.target.value)}
          />
          <label>{t('form.exterior')}</label>
          <TextArea
            name="loales.kh.exterior"
            value={formData.locales.kh.exterior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'exterior', e.target.value)}
          />
          <label>{t('form.interior')}</label>
          <TextArea
            name="loales.kh.interior"
            value={formData.locales.kh.interior ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'interior', e.target.value)}
          />
          <label>{t('form.decorText')}</label>
          <TextArea
            name="loales.kh.decorText"
            value={formData.locales.kh.decorText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'decorText', e.target.value)}
          />
          <label>{t('form.audioText')}</label>
          <TextArea
            name="loales.kh.audioText"
            value={formData.locales.kh.audioText ?? ''}
            onChange={(e) => handleLocaleChange(e, 'kh', 'audioText', e.target.value)}
          />
        </div>
        <button type="submit">{isEditing ? t('button.update') : t('button.create')}</button>
      </form>

      {locations.map((location) => (
        <li key={location.id}>
          {location.name} <button onClick={() => handleUpdate(location)}>Edit</button>
          <button onClick={() => handleDelete(location.id as string)}>Delete</button>
          {location?.images?.coverImage ? (
            <Image src={location?.images?.coverImage} alt={'Image'} width={50} height={50} />
          ) : (
            <PlaceholderImage src={placeholderImage} alt={location?.name} />
          )}
          {location.audioFile ? <ReactAudioPlayer src={location.audioFile} controls /> : null}
        </li>
      ))}
    </FormLayout>
  );
};

export const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;
