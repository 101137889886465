import { useOnClickOutside } from '@hooks/useOnClickOutside';
import colors from '@styles/common/colors';
import React, { useRef, useState } from 'react';
import { BsInfoSquare } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
  CloseLayout,
  Content,
  InfoIconLayout,
  MarkerInLayout,
  MarkerLayout,
  MenuLayout,
  ModalContainer,
  ModalOverlay,
  TextMarkerInfo,
} from './styles/MarkerInfoModal.style';

const MarkerInfoModal: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const centerModalRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent event propagation to the modal
    setShowMenu(!showMenu);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(centerModalRef, handleCloseMenu);

  return (
    <MarkerInLayout>
      <InfoIconLayout onClick={handleMenuClick}>
        <BsInfoSquare size={18} color={colors.secondary_color.regular} />
        <TextMarkerInfo>{t('sidebar.markerInfo')}</TextMarkerInfo>
      </InfoIconLayout>
      {showMenu && (
        <ModalOverlay ref={centerModalRef} showMenu={showMenu} onClick={handleCloseMenu}>
          <ModalContainer showMenu={showMenu}>
            <CloseLayout>
              <MdOutlineClose size={20} color={colors.secondary_color.regular} />
            </CloseLayout>
            <MenuLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.red} />
                <Content>{t('markerInfoModal.norodom')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={'orange'} />
                <Content>{t('markerInfoModal.sisowath')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.yellow} />
                <Content>{t('markerInfoModal.monivong')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={colors.blue} />
                <Content>{t('markerInfoModal.sihanouk')}</Content>
              </MarkerLayout>
              <MarkerLayout>
                <FaMapMarkerAlt size={22} color={'gray'} />
                <Content>{t('markerInfoModal.khmerRouge')}</Content>
              </MarkerLayout>
            </MenuLayout>
          </ModalContainer>
        </ModalOverlay>
      )}
    </MarkerInLayout>
  );
};

export default MarkerInfoModal;
