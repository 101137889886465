import React from 'react';
import { Input } from './styles/form.style';
import styled from 'styled-components';
import colors from '@styles/common/colors';

interface LocationFormInputProps {
  label: string;
  name: string;
  value?: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const LocationFormInput: React.FC<LocationFormInputProps> = ({ label, name, value, onChange }) => {
  return (
    <div>
      <Label>{label}</Label>
      <Input type="text" name={name} value={value ?? ''} onChange={onChange} />
    </div>
  );
};

export default LocationFormInput;

const Label = styled.div`
  margin-top: 2px;
  color: ${colors.text_grey};
  letter-spacing: 1px;
`;
