import { LocationItem } from '@api/location.interface';
import { ClassicButton } from '@components/Button/ClassicButton';
import useFetchLocation from '@hooks/useFetchLocations';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useAppSelector } from '@store/hooks';
import { currentLanguage } from '@store/language/languageSlice';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface DetailModalProps {
  selectedDetailsPageId?: number;
  showMenu: boolean;
  onClose: () => void;
}

const DetailsPageModal: React.FC<DetailModalProps> = ({ selectedDetailsPageId, showMenu, onClose }) => {
  const centerModalRef = useRef<HTMLDivElement>(null);
  const { locations } = useFetchLocation();
  const [selectedDetailsPage, setSelectedDetailsPage] = useState<LocationItem | null>(null);
  const { t } = useTranslation();
  const language = useAppSelector(currentLanguage);
  const navigate = useNavigate();

  const handleCloseMenu = () => {
    onClose();
    document.body.style.overflow = 'unset';
    window.history.replaceState(null, '', window.location.pathname);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent event propagation to the modal
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    if (showMenu && selectedDetailsPage) {
      window.history.replaceState(null, '', `?location=Vat-${selectedDetailsPage.name}`);
    }
  }, [showMenu, selectedDetailsPage]);

  useOnClickOutside(centerModalRef, handleCloseMenu);

  useEffect(() => {
    if (showMenu && locations && selectedDetailsPageId) {
      setSelectedDetailsPage(locations.find((detail) => detail.id === (selectedDetailsPageId as any)) || null);
    }
  }, [locations, selectedDetailsPageId, showMenu]);

  if (!showMenu) return null;

  return (
    <ModalOverlay onClick={handleCloseMenu}>
      <ModalContainer ref={centerModalRef}>
        <CloseLayout onClick={handleCloseMenu}>
          <MdOutlineClose size={20} color={colors.secondary_color.regular} />
        </CloseLayout>
        <MenuLayout onClick={handleMenuClick}>
          <TitleLayout>
            <VatTitle>
              {t('search.vat')} {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.name}
              &nbsp;-&nbsp;
              {selectedDetailsPage?.provinceOrCityCode}
            </VatTitle>
          </TitleLayout>
          <CoverLayout>
            {selectedDetailsPage?.images?.sanctuaryImageSide1 ? (
              <Image src={selectedDetailsPage?.images?.sanctuaryImageSide1} alt={selectedDetailsPage?.name} />
            ) : null}
            <CoverImageTitle>
              {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary1}
            </CoverImageTitle>
          </CoverLayout>
          <ContentLayout>
            <ContentInnerLayout>
              <div>
                <SubtitleLayout>
                  <Title>{t('form.identification')}</Title>
                </SubtitleLayout>
                <Content>
                  <b>{t('form.name')}</b> {t('search.vat')}{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.name}
                </Content>
                <Content>
                  <b>{t('form.otherName')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.otherName}
                </Content>
                <Content>
                  <b>{t('form.country')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.country}
                </Content>
                <Content>
                  <b>{t('form.city')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.city}
                </Content>
                <Content>
                  <b>{t('form.district')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.district}
                </Content>
                <Content>
                  <b>{t('form.commune')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.commune}
                </Content>
                <Content>
                  <b>{t('form.latitude')}</b> {(selectedDetailsPage?.geometry as any)?.location?.lat}
                </Content>
                <Content>
                  <b>{t('form.longitude')}</b> {(selectedDetailsPage?.geometry as any)?.location?.lng}
                </Content>
                <Content>
                  <b>{t('form.lastVisit')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.lastVisit}
                </Content>

                <SubtitleLayout>
                  <Title>{t('form.paintingAndArchi')}</Title>
                  <ItalicText>{t('form.history')}</ItalicText>
                </SubtitleLayout>
                <Content>
                  <b>{t('form.constructionDate')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.constructionDate}
                </Content>
                <Content>
                  {selectedDetailsPage?.decorDate ? <b>{t('form.decorDate')}</b> : null}{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.decorDate}
                </Content>
                <Content>
                  <b>{t('form.archives')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.archives}
                </Content>
                <Content>
                  <b>{t('form.khmerRougePeriod')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.khmerRougePeriod}
                </Content>
                <Content>
                  <b>{t('form.restoration')}</b>{' '}
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.restoration}
                </Content>
                <SubtitleLayout>
                  <ContentTitle>{t('form.exterior')}</ContentTitle>
                  <Content>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.exterior}
                  </Content>
                  <ContentTitle>{t('form.bay')}</ContentTitle>
                  <Content>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.bay}
                  </Content>
                  <ContentTitle>{t('form.interior')}</ContentTitle>
                  <Content>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.interior}
                  </Content>
                  <ContentTitle>{t('form.particularity')}</ContentTitle>
                  <Content>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.particularity}
                  </Content>
                  {selectedDetailsPage?.decorText ? <ContentTitle>{t('form.decorText')}</ContentTitle> : null}{' '}
                  <Content>
                    {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.decorText}
                  </Content>
                </SubtitleLayout>
                <ButtonLayout>
                  <ClassicButton title={t('partner.donation')} onClick={() => navigate('/donations')} />
                </ButtonLayout>
              </div>

              <ImageLayout>
                {selectedDetailsPage?.images?.sanctuaryImageSide2 ? (
                  <Image src={selectedDetailsPage?.images?.sanctuaryImageSide2} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary2}
                </ImageTitle>
                {selectedDetailsPage?.images?.sanctuaryImageSide3 ? (
                  <Image src={selectedDetailsPage?.images?.sanctuaryImageSide3} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary3}
                </ImageTitle>
                {selectedDetailsPage?.images?.sanctuaryImageSide4 ? (
                  <Image src={selectedDetailsPage?.images?.sanctuaryImageSide4} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titleSanctuary4}
                </ImageTitle>
                {selectedDetailsPage?.images?.sanctuaryDrawing ? (
                  <Image src={selectedDetailsPage?.images?.sanctuaryDrawing} alt={selectedDetailsPage?.name} />
                ) : null}
                {selectedDetailsPage?.images?.painting ? (
                  <Image src={selectedDetailsPage?.images?.painting} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting}
                </ImageTitle>
                {selectedDetailsPage?.images?.painting1 ? (
                  <Image src={selectedDetailsPage?.images?.painting1} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales ? (selectedDetailsPage.locales as any)[language]?.titlePainting1 : null}
                </ImageTitle>
                {selectedDetailsPage?.images?.painting2 ? (
                  <Image src={selectedDetailsPage?.images?.painting2} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting2}
                </ImageTitle>
                {selectedDetailsPage?.images?.painting3 ? (
                  <Image src={selectedDetailsPage?.images?.painting3} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting3}
                </ImageTitle>
                {selectedDetailsPage?.images?.painting4 ? (
                  <Image src={selectedDetailsPage?.images?.painting4} alt={selectedDetailsPage?.name} />
                ) : null}
                <ImageTitle>
                  {selectedDetailsPage?.locales && (selectedDetailsPage.locales as any)[language]?.titlePainting4}
                </ImageTitle>
                <ButtonLayout>
                  <ClassicButton title={t('search.findMe')} onClick={() => navigate('/map')} />
                </ButtonLayout>
              </ImageLayout>
            </ContentInnerLayout>
          </ContentLayout>
        </MenuLayout>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default DetailsPageModal;

const CoverLayout = styled.div`
  margin: 8px 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: ${colors.white};
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: 768px) {
    border-radius: 6px;
    max-width: 650px;
    max-height: 800px;
    width: 85%;
    height: 85%;
  }

  @media (min-width: 1024px) {
    max-width: 750px;
  }

  @media (min-width: 1440px) {
    max-width: 1100px;
  }
`;

const CloseLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
`;

const MenuLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.2rem;
  height: 90%;
  overflow-y: scroll;

  /* Set the width and height of the scrollbar */
  &::-webkit-scrollbar {
    display: none;
    // width: 5px;
    // height: 5px;
  }

  /* Set the background color of the scrollbar track */
  // ::-webkit-scrollbar-track {
  //   background-color: #f1f1f1;
  // }

  /* Set the color of the scrollbar handle */
  // ::-webkit-scrollbar-thumb {
  //   background-color: ${colors.secondary_color.variant_one};
  //   border-radius: 5px;
  //   box-shadow: ${shadows.md};
  //   width: 5px;
  //   height: 5px;
  // }

  @media (min-width: 360px) {
    padding: 1rem 1.7rem;
  }
  @media (min-width: 768px) {
    padding: 1rem 2.2rem;

    ::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${colors.secondary_color.variant_one};
      border-radius: 5px;
      box-shadow: ${shadows.md};
      width: 5px;
      height: 5px;
    }
  }
`;

const ContentLayout = styled.div`
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 0 0 12px 0;
`;

const ContentInnerLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1024px) {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TitleLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const VatTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.dark_tangerine};

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.text_grey};
  margin: 0 0 12px 0;

  @media (min-width: 1440px) {
    font-size: 22px;
  }
`;

const ImageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
`;

const SubtitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 14px 0;
`;

const ItalicText = styled.div`
  font-size: 18px;
  color: ${colors.text_grey};
  margin: 4px 0;
  font-style: italic;

  @media (min-width: 1440px) {
    font-size: 20px;
  }
`;

const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text_grey};
  margin: 12px 0;

  @media (min-width: 1440px) {
    font-size: 20px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 8px 0;
  color: ${colors.text_grey};

  @media (min-width: 1440px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;
  max-width: 100%;
`;

const ImageTitle = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${colors.text_grey};
  margin-bottom: 12px;

  @media (min-width: 1440px) {
    font-size: 16px;
  }
`;

const CoverImageTitle = styled.div`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: ${colors.text_grey};
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const ButtonLayout = styled.div`
  width: 100%;
  max-width: 160px;
  margin: 30px auto;
`;

{
  /* By checking if selectedDetailsPage?.locales is not null or undefined before accessing its properties, you
            avoid the error you were seeing before. And by wrapping the call to t with &&, you ensure that the call is
            only made if selectedDetailsPage?.locales is truthy. */
  // {
  //   selectedDetailsPage?.locales && t((selectedDetailsPage.locales as any)[language].name);
  // }
}
