import { SubmitButton } from '@components/Button/SubmitButton';
import { useAuth } from '@context/AuthProvider';
import { createMonthlyDonation100EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation100EuroCheckoutSession';
import { createMonthlyDonation10EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation10EuroCheckoutSession';
import { createMonthlyDonation1EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation1EuroCheckoutSession';
import { createMonthlyDonation20EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation20EuroCheckoutSession';
import { createMonthlyDonation50EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation50EuroCheckoutSession';
import { createMonthlyDonation5EuroCheckoutSession } from '@stripe/MonthlyDonation/createMonthlyDonation5EuroCheckoutSession';
import { createDonation100CheckoutSession } from '@stripe/Sponsors/createDonation100CheckoutSession';
import { createDonation10CheckoutSession } from '@stripe/Sponsors/createDonation10CheckoutSession';
import { createDonation20CheckoutSession } from '@stripe/Sponsors/createDonation20CheckoutSession';
import { createDonation50CheckoutSession } from '@stripe/Sponsors/createDonation50CheckoutSession';
import { createDonation5CheckoutSession } from '@stripe/Sponsors/createDonation5CheckoutSession';
import { createDonationCheckoutSession } from '@stripe/Sponsors/createDonationCheckoutSession';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import typography from '@styles/common/typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SponsorContainer: React.FC = () => {
  const [isDonationLoading, setIsDonationLoading] = useState<boolean>(false);
  const [isDonationLoading5, setIsDonationLoading5] = useState<boolean>(false);
  const [isDonationLoading10, setIsDonationLoading10] = useState<boolean>(false);
  const [isDonationLoading20, setIsDonationLoading20] = useState<boolean>(false);
  const [isDonationLoading50, setIsDonationLoading50] = useState<boolean>(false);
  const [isDonationLoading100, setIsDonationLoading100] = useState<boolean>(false);
  const [isMonthlyDonation1Loading, setIsMonthlyDonation1Loading] = useState<boolean>(false);
  const [isMonthlyDonation5Loading, setIsMonthlyDonation5Loading] = useState<boolean>(false);
  const [isMonthlyDonation10Loading, setIsMonthlyDonation10Loading] = useState<boolean>(false);
  const [isMonthlyDonation20Loading, setIsMonthlyDonation20Loading] = useState<boolean>(false);
  const [isMonthlyDonation50Loading, setIsMonthlyDonation50Loading] = useState<boolean>(false);
  const [isMonthlyDonation100Loading, setIsMonthlyDonation100Loading] = useState<boolean>(false);
  const { user } = useAuth();
  const userId = user?.uid || '';
  const { t } = useTranslation();

  return (
    <BoxLayout>
      <Box>
        <PackageTitleLayout>
          <Title>{t('partner.one-time-contribution')}</Title>
        </PackageTitleLayout>
        <BorderLine />
        <BoxInnerLayout>
          <DonationBtnLayout>
            <SubmitButton
              text={'$1'}
              onClick={() => {
                setIsDonationLoading(true);
                createDonationCheckoutSession(userId);
              }}
              isLoading={isDonationLoading}
            />
            <SubmitButton
              text={'$5'}
              onClick={() => {
                setIsDonationLoading5(true);
                createDonation5CheckoutSession(userId);
              }}
              isLoading={isDonationLoading5}
            />
            <SubmitButton
              text={'$10'}
              onClick={() => {
                setIsDonationLoading10(true);
                createDonation10CheckoutSession(userId);
              }}
              isLoading={isDonationLoading10}
            />
          </DonationBtnLayout>
          <DonationBtnLayout>
            <SubmitButton
              text={'$20'}
              onClick={() => {
                setIsDonationLoading20(true);
                createDonation20CheckoutSession(userId);
              }}
              isLoading={isDonationLoading20}
            />
            <SubmitButton
              text={'$50'}
              onClick={() => {
                setIsDonationLoading50(true);
                createDonation50CheckoutSession(userId);
              }}
              isLoading={isDonationLoading50}
            />
            <SubmitButton
              text={'$100'}
              onClick={() => {
                setIsDonationLoading100(true);
                createDonation100CheckoutSession(userId);
              }}
              isLoading={isDonationLoading100}
            />
          </DonationBtnLayout>
        </BoxInnerLayout>
      </Box>
      <Box>
        <PackageTitleLayout>
          <Title>{t('partner.recurring-contribution')}</Title>
        </PackageTitleLayout>
        <BorderLine />
        <BoxInnerLayout>
          <DonationBtnLayout>
            <SubmitButton
              text={'$1'}
              onClick={() => {
                setIsMonthlyDonation1Loading(true);
                createMonthlyDonation1EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation1Loading}
            />
            <SubmitButton
              text={'$5'}
              onClick={() => {
                setIsMonthlyDonation5Loading(true);
                createMonthlyDonation5EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation5Loading}
            />
            <SubmitButton
              text={'$10'}
              onClick={() => {
                setIsMonthlyDonation10Loading(true);
                createMonthlyDonation10EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation10Loading}
            />
          </DonationBtnLayout>
          <DonationBtnLayout>
            <SubmitButton
              text={'$20'}
              onClick={() => {
                setIsMonthlyDonation20Loading(true);
                createMonthlyDonation20EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation20Loading}
            />
            <SubmitButton
              text={'$50'}
              onClick={() => {
                setIsMonthlyDonation50Loading(true);
                createMonthlyDonation50EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation50Loading}
            />
            <SubmitButton
              text={'$100'}
              onClick={() => {
                setIsMonthlyDonation100Loading(true);
                createMonthlyDonation100EuroCheckoutSession(userId);
              }}
              isLoading={isMonthlyDonation100Loading}
            />
          </DonationBtnLayout>
        </BoxInnerLayout>
      </Box>
    </BoxLayout>
  );
};

export default SponsorContainer;

const DonationBtnLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const BoxLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Box = styled.div`
  width: 100%;
  background: ${colors.white};
  box-shadow: ${shadows.base};
  border: 1px solid ${colors.secondary_color.variant_two};
  border-radius: 12px;
`;

const BoxInnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`;

const BorderLine = styled.div`
  width: 100%;
  border: 0.11px solid ${colors.secondary_color.variant_two};
`;

const PackageTitleLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 16px 0;
`;

const Title = styled.div`
  font-size: ${typography.fontSize.lg};
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;

  @media (min-width: 1280px) {
    font-size: ${typography.fontSize.xl};
  }
`;
