import colors from '@styles/common/colors';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const FooterLayout = styled.div`
  postion: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const TermsLayout = styled.div`
  display: flex;
  gap: 12px;
`;

const Text = styled.div`
  font-size: 13px;
  color: ${colors.text_grey};
  cursor: pointer;

  &:hover {
    color: ${colors.dark_tangerine};
  }
`;

const CopyRight = styled.div`
  font-size: 13px;
  color: ${colors.text_grey};
`;

const LinksLayout = styled.div`
  display: flex;
  gap: 12px;
`;

const Footer = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FooterLayout>
      <LinksLayout>
        <Link to="https://www.facebook.com/lapagodekhmere/">
          <FaFacebook size={25} color={colors.dark_blue} />
        </Link>
        <Link to="https://www.linkedin.com/company/la-pagode-khmere/">
          <AiFillLinkedin size={25} color={colors.dark_blue} />
        </Link>
        <Link to="https://twitter.com/lapagodekhmere">
          <AiOutlineTwitter size={25} color={colors.blue} />
        </Link>
      </LinksLayout>
      <TermsLayout>
        <Text onClick={() => navigate('/privacy')}>{t('header.privacy')}</Text>
        <Text onClick={() => navigate('/terms')}>{t('header.terms')}</Text>
      </TermsLayout>
      <CopyRight>
        &copy; {year} {t('footer.company')}{' '}
      </CopyRight>
    </FooterLayout>
  );
};

export default Footer;
