import styled from 'styled-components';

interface SSOIconProps {
  icon: React.FC;
}

export const SSOIcon = ({ icon }: SSOIconProps) => {
  const Icon = styled(icon)`
    height: 25px;
    width: 25px;
  `;
  return <Icon />;
};
