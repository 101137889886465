import colors from '@styles/common/colors';
import styled from 'styled-components';
import { TextMedium } from './Text';
import { useTranslation } from 'react-i18next';

const BorderLine = styled.div`
  border: 0.2px solid ${colors.secondary_color.variant_one};
  width: 100%;
`;

const ORLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 4px;
  margin: 4px 0;
`;

const OrLineLayout = () => {
  const { t } = useTranslation();
  return (
    <ORLayout>
      <BorderLine />
      <TextMedium>{t('form.or')}</TextMedium>
      <BorderLine />
    </ORLayout>
  );
};

export default OrLineLayout;
