import { RouteProps, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '@context/AuthProvider';

const ProtectedRoutes: React.FC<RouteProps> = () => {
  const { user } = useAuth();
  const storedAuthToken = localStorage.getItem('authToken');

  const isAuthenticated = user || storedAuthToken;

  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
