import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import Header from '@components/Header/Header';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React from 'react';
import styled from 'styled-components';
import { BorderLine } from '@components/UI/BorderLine';
import FooterContact from '@components/Footer/FooterContact';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { AiFillInstagram, AiFillLinkedin, AiOutlineMail } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';
import { BiPhoneCall } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Logo from '@assets/icons/LogoWithTitle.png';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Container>
        <Wrapper>
          <img src={Logo} width={300} height={50} />
          <IconLayout>
            <GoLocation size={18} color={colors.text_grey} />
            <Text>46, rue Branly, 94110, Arcueil, FRANCE</Text>
          </IconLayout>
          <IconLayout>
            <BiPhoneCall size={18} color={colors.text_grey} />
            <Text>+33 6 46 00 83 19</Text>
          </IconLayout>
          <IconLayout>
            <AiOutlineMail size={18} color={colors.text_grey} />
            <Text>contact@tun-puthpiseth.com</Text>
          </IconLayout>
          <Title>{t('contact.follow')}</Title>
          <LinksLayout>
            <IconWrapper>
              <Link to="https://www.facebook.com/lapagodekhmere">
                <FaFacebook size={25} color={colors.secondary_color.regular} />
              </Link>
            </IconWrapper>
            <IconWrapper>
              <Link to="https://www.linkedin.com/company/la-pagode-khmere/?viewAsMember=true">
                <AiFillLinkedin size={25} color={colors.secondary_color.regular} />
              </Link>
            </IconWrapper>
            <IconWrapper>
              <Link to="https://t.me/lapagodekhmere">
                <FaTelegram size={25} color={colors.secondary_color.regular} />
              </Link>
            </IconWrapper>
            <IconWrapper>
              <Link to="https://www.instagram.com/lapagodekhmere/">
                <AiFillInstagram size={25} color={colors.secondary_color.regular} />
              </Link>
            </IconWrapper>
          </LinksLayout>
        </Wrapper>
      </Container>
      <BorderLine />
      <FooterContact />
      <CookieConsentBanner />
    </>
  );
};

export default Contact;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 440px;
  background: ${colors.white};
  border: 1px solid ${colors.secondary_color.variant_two};
  border-radius: 12px;
  box-shadow: ${shadows.md};
  padding: 24px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const Text = styled.div`
  font-size: 15px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 38px;

  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: medium;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin-top: 12px;
`;

const LinksLayout = styled.div`
  display: flex;
  gap: 12px;
`;

const IconLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.div`
  &:hover svg {
    fill: ${colors.dark_tangerine};
  }
`;
