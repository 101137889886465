import React from 'react';
import { Input } from '@components/Forms/styles/form.style';
import { User } from '@components/Users/types';
import styled from 'styled-components';
import colors from '@styles/common/colors';

interface Props {
  fieldName: string;
  userData: User;
  label: string;
  accept: string;
  handleFileUpload: (
    fieldName: string,
    userData: User
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const UploadPhotoURLInput: React.FC<Props> = ({ fieldName, userData, label, accept, handleFileUpload }) => {
  return (
    <div>
      <Label>{label}</Label>
      <Input type="file" name={fieldName} onChange={handleFileUpload(fieldName, userData)} accept={accept} />
    </div>
  );
};

export default UploadPhotoURLInput;

const Label = styled.div`
  font-size: 16px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  margin: 4px 0;
`;
