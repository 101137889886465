import { useGetUserByIdQuery } from '@api/users.api';
import Header from '@components/Header/Header';
import { BorderLine } from '@components/Maps/styles/autocomplete.style';
import { useAuth } from '@context/AuthProvider';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VscAccount } from 'react-icons/vsc';
import { goToBillingPortal } from '@stripe/goToBillingPortal';
import usePremiumStatus from '@stripe/usePremiumStatus';
import { createCheckoutSession } from '@stripe/createCheckoutSession';
import { SubmitButton } from '@components/Button/SubmitButton';
import { useTranslation } from 'react-i18next';
import { usePageLoaded } from '@hooks/usePageLoaded';
import { analytics } from '@database/firebase';
import { logEvent } from '@firebase/analytics';
import CookieConsentBanner from '@components/Cookie/CookieConsentBanner';
import SponsorLogosProfile from '@components/Sponsors/SponsorLogosProfile';

const Profile: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const userId = user?.uid || '';
  const { data: currentUser } = useGetUserByIdQuery(userId);
  const userIsPremium = usePremiumStatus(user);
  const { t } = useTranslation();
  const isPageLoaded = usePageLoaded(500);

  useEffect(() => {
    logEvent(analytics, 'ProfilePage');
  }, []);

  return (
    <>
      <Header />

      <Container>
        {/* To prevent the "Free" text from appearing when you refresh the page */}
        {isPageLoaded && (
          <ProfileLayout>
            <HeaderLayout>
              <Content>{t('account.account-info')}</Content>
              <Subscription>{userIsPremium ? 'Premium' : 'Free'}</Subscription>
            </HeaderLayout>
            <BorderLine />
            <InnerLayout>
              {currentUser && currentUser.photoURL ? (
                <AccountLayout>
                  <img
                    key={currentUser.photoURL} // Add a unique key to trigger a re-render when the photoURL changes
                    src={currentUser.photoURL}
                    alt="User Profile"
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                  />
                </AccountLayout>
              ) : (
                <AccountLayout>
                  <VscAccount size={50} color={colors.secondary_color.regular} />
                </AccountLayout>
              )}
              <TitleLayout>
                <Title>
                  {currentUser &&
                    currentUser.displayName &&
                    currentUser.displayName.charAt(0).toUpperCase() + currentUser.displayName.slice(1)}
                </Title>
                <Content>{currentUser?.email}</Content>
              </TitleLayout>
              <Content>{t('account.profile-info')}</Content>
              {userIsPremium ? (
                <ButtonLayout>
                  <SubmitButton
                    text={t('account.billing')}
                    onClick={() => {
                      setIsLoading(true);
                      goToBillingPortal();
                    }}
                    isLoading={isLoading}
                  />
                </ButtonLayout>
              ) : (
                <ButtonLayout>
                  <SubmitButton
                    text={t('account.upgrade')}
                    onClick={() => {
                      setIsLoading(true);
                      createCheckoutSession(userId);
                    }}
                    isLoading={isLoading}
                  />
                </ButtonLayout>
              )}
            </InnerLayout>
          </ProfileLayout>
        )}
        <SponsorLogosProfile />
      </Container>
      <CookieConsentBanner />
    </>
  );
};

export default Profile;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;

  @media (min-width: 540px) {
    justify-content: center;
    margin: 80px auto;
  }
`;

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 18px;
`;

const AccountLayout = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

const ProfileLayout = styled.div`
  width: 100%;
  max-width: 480px;
  background: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  border-radius: 4px;
  box-shadow: ${shadows.md};
`;

const InnerLayout = styled.div`
  padding: 18px;
`;

const TitleLayout = styled.div`
  line-height: 28px;
  margin: 12px 0;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
`;

const Content = styled.div`
  font-size: 18px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
  line-height: 30px;
`;

const Subscription = styled.div`
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  background: ${colors.light_gray};
  padding: 0 8px;
`;

const ButtonLayout = styled.div`
  width: 100%;
  margin-top: 16px;
`;
