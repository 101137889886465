import MohaLeap from '@assets/home/Moha-Leap-KampongCham.webp';
import Saravan from '@assets/home/Saravan_sanctuary.webp';
import Ampil from '@assets/home/Ampil-Banteaymeachey.webp';
import RokaKandal from '@assets/home/Roka-Kandal-Chas-Kratie.webp';
import Pnov from '@assets/home/Pnov-Battambang.webp';
import Damnak from '@assets/home/Damnak-Siemreap.webp';
import PiphoatReangseiPhnomPenh from '@assets/home/Piphoat-Reangsei.webp';
import AmpaePhnomKgSpeu from '@assets/home/Ampae-Phnom_sanctuary.webp';
import KampongTralachLeuKgChnnang from '@assets/home/Kampong-Tralach-Leu.webp';
import KdeiDoeumKgThom from '@assets/home/kdei-doem-09-2023.webp';
import ChrouyTakeoKandal from '@assets/home/Chrouy-Takaev.webp';
import TakhmauKandal from '@assets/home/Takhmau.webp';

export const monumentsImageData = [
  {
    id: 1,
    imageSrc: MohaLeap,
    altText: 'Moha Leap Pagoda',
    content: 'Vat Moha Leap, Kampong Cham',
  },
  {
    id: 2,
    imageSrc: Damnak,
    altText: 'Damnak Pagoda',
    content: 'Vat Damnak pagoda, Siem Reap',
  },
  {
    id: 3,
    imageSrc: RokaKandal,
    altText: 'Roka Kandal Chas Pagoda',
    content: 'Vat Roka Kandal Chas, Kratie',
  },
  {
    id: 4,
    imageSrc: Saravan,
    altText: 'Saravan Pagoda',
    content: 'Vat Saravan, Phnom Penh',
  },
  {
    id: 5,
    imageSrc: PiphoatReangseiPhnomPenh,
    altText: 'Piphoat Reangsei Pagoda',
    content: 'Vat Piphoat Reangsei, Phnom Penh',
  },
  {
    id: 6,
    imageSrc: ChrouyTakeoKandal,
    altText: 'Chrouy Takeo Pagoda',
    content: 'Vat Chrouy Takeo, Kandal',
  },
  {
    id: 7,
    imageSrc: TakhmauKandal,
    altText: 'Takhmau Pagoda',
    content: 'Vat Takhmau, Kandal',
  },
  {
    id: 8,
    imageSrc: Pnov,
    altText: 'Pnov Pagoda',
    content: 'Vat Pnov, Battambang',
  },
  {
    id: 9,
    imageSrc: Ampil,
    altText: 'Ampil Pagoda',
    content: 'Vat Ampil, Banteay Meanchey',
  },
  {
    id: 10,
    imageSrc: AmpaePhnomKgSpeu,
    altText: 'Ampae Phnom Pagoda',
    content: 'Vat Ampae Phnom, Kampong Speu',
  },
  {
    id: 11,
    imageSrc: KampongTralachLeuKgChnnang,
    altText: 'Kampong Tralach Leu Pagoda',
    content: 'Vat Kampong Tralach Leu, Kampong Chnnang',
  },
  {
    id: 12,
    imageSrc: KdeiDoeumKgThom,
    altText: 'Kdei Doeum Pagoda',
    content: 'Vat Kdei Doeum, Kampong Thom',
  },
];
