import { useOnClickOutside } from '@hooks/useOnClickOutside';
import colors from '@styles/common/colors';
import { LocationOptions } from '@utils/city';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { GoChevronDown } from 'react-icons/go';
import { GoChevronUp } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/Switch/Switch';

interface CityDropdownProps {
  onChange: (selectedCity: string | null) => void;
}

const CityDropdown: React.FC<CityDropdownProps> = ({ onChange }) => {
  const [selectedCity, setSelectedCity] = useState<LocationOptions | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const locationOptions = [
    { label: t('city.all'), value: 'All' },
    { label: t('city.BanteayMeanchey'), value: 'Banteay Meanchey' },
    { label: t('city.Battambang'), value: 'Battambang' },
    { label: t('city.KampongCham'), value: 'Kampong Cham' },
    { label: t('city.KampongChhnang'), value: 'Kampong Chhnang' },
    { label: t('city.KampongSpeu'), value: 'Kampong Speu' },
    { label: t('city.KampongThom'), value: 'Kampong Thom' },
    { label: t('city.Kampot'), value: 'Kampot' },
    { label: t('city.Kandal'), value: 'Kandal' },
    { label: t('city.Kep'), value: 'Kep' },
    { label: t('city.Kratié'), value: 'Kratié' },
    { label: t('city.Pailin'), value: 'Pailin' },
    { label: t('city.PhnomPenh'), value: 'Phnom Penh' },
    { label: t('city.PreyVeng'), value: 'Prey Veng' },
    { label: t('city.Pursat'), value: 'Pursat' },
    { label: t('city.SiemReap'), value: 'Siem Reap' },
    { label: t('city.StungTreng'), value: 'Stung Treng' },
    { label: t('city.SvayRieng'), value: 'Svay Rieng' },
    { label: t('city.Takéo'), value: 'Takéo' },
    { label: t('city.TboungKhmum'), value: 'Tboung Khmum' },
  ];

  const handleSelectCity = (city: LocationOptions) => {
    setSelectedCity(city);
    if (onChange) {
      onChange(city.value);
    }
    setIsOpen(false);
  };

  const handleHideModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = 'hidden';
  };

  useOnClickOutside(dropdownRef, handleHideModal);

  return (
    <div ref={dropdownRef}>
      <Overlay isOpen={isOpen} onClick={handleHideModal} />
      <DropdownContainer>
        <DropdownButton onClick={toggleDropdown}>
          <IconWrapper>
            {selectedCity ? selectedCity.label : t('city.chooseCityMobile')}
            {isOpen ? <GoChevronDown color={colors.dark_tangerine} /> : <GoChevronUp color={colors.dark_tangerine} />}
          </IconWrapper>
        </DropdownButton>
        <Modal isOpen={isOpen}>
          <ModalContent isOpen={isOpen}>
            {locationOptions.map((location) => (
              <Column key={location.value} onClick={() => handleSelectCity(location)}>
                <SwitchLayout>
                  <Switch
                    key={location.value}
                    active={selectedCity?.value === location.value}
                    onClick={() => handleSelectCity(location)}
                  />
                  <Label>{location.label}</Label>
                </SwitchLayout>
              </Column>
            ))}
          </ModalContent>
          <BorderLine />
          <Close onClick={toggleDropdown}>Close</Close>
        </Modal>
      </DropdownContainer>
    </div>
  );
};

export default CityDropdown;

interface ModalProps {
  isOpen: boolean;
}

const DropdownContainer = styled.div`
  position: relative;
`;
const DropdownButton = styled.button`
  width: 100%;
  padding: 6px 7px;
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary_color.variant_one};
  border-radius: 5px;
  font-size: 11px;
  color: ${colors.text_grey};
  cursor: pointer;

  @media (min-width: 375px) {
    font-size: 13px;
  }

  @media (min-width: 540px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const Overlay = styled.div<ModalProps>`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`;

const Modal = styled.div<ModalProps>`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  background-color: ${colors.white};
  border: 0.5px solid ${colors.secondary_color.variant_two};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  z-index: 9999;
  transition: bottom 0.3s ease-in-out;
  overflow: hidden;
`;

const ModalContent = styled.div<ModalProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 45px 5px 20px 5px;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 360px) {
    padding: 45px 0 20px 20px;
  }

  @media (min-width: 412px) {
    padding: 45px 0 20px 45px;
  }

  @media (min-width: 540px) {
    padding: 45px 0 20px 80px;
  }
`;

const Column = styled.div`
  flex: 1 50%;
  cursor: pointer;

  &:hover {
    background-color: ${colors.secondary_color.variant_two};
  }
`;

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.dark_tangerine};
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const BorderLine = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
  border: 0.8px solid ${colors.secondary_color.variant_one};
`;

const SwitchLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (min-width: 360px) {
    font-size: 14px;
  }
`;
