import FormInput from '@components/Forms/FormInput';
import { FormLayout } from '@components/Forms/styles/form.style';
import { Toast } from '@utils/Toast';
import React, { useCallback, useState } from 'react';
import { useCreateUserMutation, useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from '@api/users.api';
import { User, initialValue } from './types';

const CreateUsers: React.FC = () => {
  const [formData, setFormData] = useState<User>(initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [createUserMutation] = useCreateUserMutation();
  const [updateUserMutation] = useUpdateUserMutation();
  const [deletedUserMutation] = useDeleteUserMutation();
  const { data: users = [], refetch } = useGetUsersQuery();

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        // Check if the user already has an ID (indicating an existing user)
        if (formData.id) {
          await updateUser(formData);
        } else {
          const newUser = {
            displayName: formData.displayName,
            email: formData.email,
            password: formData.password,
            passwordConfirm: formData.passwordConfirm,
            bio: formData.bio,
            country: formData.country,
            role: formData.role,
            authProvider: formData.authProvider,
          };
          const data = await createUserMutation(newUser);
          console.log(data);

          const toast = new Toast('User successfully created! 🚀');
          toast.showSuccess();
        }

        setFormData(initialValue);
        setIsEditing(false);

        // Refetch the user data to display the updated list immediately
        refetch();
      } catch (error) {
        console.error('Error:', error);
        const toast = new Toast('Error occurred!');
        toast.showError();
      }
    },
    [formData, users, isEditing]
  );

  const updateUser = async (user: User) => {
    try {
      const updatedUserData = {
        id: user.id,
        displayName: user.displayName,
        email: user.email,
        bio: user.bio,
        country: user.country,
        role: user.role,
        authProvider: user.authProvider,
      };

      const userToUpdate = await updateUserMutation(updatedUserData);
      console.log(userToUpdate);

      const toast = new Toast('User successfully updated! 🚀');
      toast.showSuccess();

      // Refetch the user data to display the updated list immediately
      refetch();
    } catch (error) {
      console.error('Error updating user:', error);
      const toast = new Toast('Error updating user!');
      toast.showError();
    }
  };

  const handleEdit = useCallback((user: User) => {
    setIsEditing(true);
    setFormData(user);
  }, []);

  const handleDelete = async (userId: string) => {
    try {
      await deletedUserMutation(userId);
      const toast = new Toast('User successfully deleted! 🗑️');
      toast.showSuccess();
    } catch (error) {
      console.error('Error deleting user:', error);
      const toast = new Toast('Error deleting user!');
      toast.showError();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <h2>Create New User</h2>
      <FormLayout>
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            label={'Username'}
            name="displayName"
            value={formData.displayName || ''}
            onChange={handleChange}
          />
          <FormInput type="email" label={'Email'} name="email" value={formData.email || ''} onChange={handleChange} />
          <FormInput
            type="password"
            label={'Password'}
            name="password"
            value={formData.password || ''}
            onChange={handleChange}
          />
          <FormInput
            type="password"
            label={'Confirm Password'}
            name="passwordConfirm"
            value={formData.passwordConfirm || ''}
            onChange={handleChange}
          />
          <FormInput type="text" label={'Bio'} name="bio" value={formData.bio ?? ''} onChange={handleChange} />
          <FormInput
            type="text"
            label={'Country'}
            name="country"
            value={formData.country || ''}
            onChange={handleChange}
          />
          <FormInput
            type="text"
            label={'Auth Provider'}
            name="authProvider"
            value={formData.authProvider || ''}
            onChange={handleChange}
          />
          <FormInput type="text" label={'Roles'} name="role" value={formData.role || ''} onChange={handleChange} />
          <button type="submit">{isEditing ? 'Update' : 'Create'}</button>
        </form>
      </FormLayout>

      <h2>Users</h2>
      {users.map((user, index) => (
        <div key={index}>
          <h3>{user.displayName}</h3>
          <button onClick={() => handleEdit(user)}>Edit</button>
          <button onClick={() => handleDelete(user.id)}>Delete</button>
        </div>
      ))}
    </div>
  );
};

export default CreateUsers;
