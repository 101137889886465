import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { ClearButton, SearchButton, SearchInputStyled } from '../styles/searchInput.style';

interface Props {
  query: string;
  setQuery: (query: string) => void;
}

export const SearchInput = ({ query, setQuery }: Props) => {
  const { t } = useTranslation();
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <SearchButton>
        <BsSearch size={15} aria-label="Search" />
      </SearchButton>
      <SearchInputStyled
        type="text"
        value={query}
        placeholder={t('search.input') as string}
        onChange={handleQueryChange}
      />
      {query && (
        <ClearButton onClick={() => setQuery('')}>
          <AiOutlineCloseCircle size={15} aria-label={'Clear'} />
        </ClearButton>
      )}
    </>
  );
};
