import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SignupData, VerificationData } from './auth.interface';

const API_BASE_URL = 'https://europe-west3-vatkhmerapp.cloudfunctions.net/api/';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: '', // Set Authorization header to an empty string to skip authentication
    },
  }),
  endpoints: (builder) => ({
    signup: builder.mutation<SignupData, SignupData>({
      query: (userData) => ({
        url: 'auth/signup',
        method: 'POST',
        body: userData,
      }),
    }),

    verifyEmail: builder.mutation<VerificationData, VerificationData>({
      query: (userData) => ({
        url: 'email-confirmation/verify-email',
        method: 'POST',
        body: userData,
      }),
    }),
  }),
});

export const { useSignupMutation, useVerifyEmailMutation } = authApi;
