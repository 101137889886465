import colors from '@styles/common/colors';
import devices from '@styles/common/devices';
import sizes from '@styles/common/sizes';
import typography from '@styles/common/typography';
import styled from 'styled-components';

export const TextUnderline = styled.nav`
  font-family: ${typography.fonts.body};
  font-size: ${sizes.base};
  color: ${colors.dark_tangerine};
  cursor: pointer;

  &:hover {
    color: ${colors.orange};
  }

  @media ${devices.tablet} {
    font-size: ${sizes.md};
  }
`;
