import { LocationOptions } from '@utils/city';
import React, { useState } from 'react';
import { Label, SwitchLayout } from './FiltredDateSidebar';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/Switch/Switch';
import typography from '@styles/common/typography';

interface CitySideBarProps {
  onChange: (selectedCity: string | null) => void;
}

const FiltredCitySidebar: React.FC<CitySideBarProps> = ({ onChange }) => {
  const [selectedCity, setSelectedCity] = useState<LocationOptions | null>(null);

  const { t } = useTranslation();

  const locationOptions = [
    { label: t('city.all'), value: 'All' },
    { label: t('city.BanteayMeanchey'), value: 'Banteay Meanchey' },
    { label: t('city.Battambang'), value: 'Battambang' },
    { label: t('city.KampongCham'), value: 'Kampong Cham' },
    { label: t('city.KampongChhnang'), value: 'Kampong Chhnang' },
    { label: t('city.KampongSpeu'), value: 'Kampong Speu' },
    { label: t('city.KampongThom'), value: 'Kampong Thom' },
    { label: t('city.Kandal'), value: 'Kandal' },
    { label: t('city.Kampot'), value: 'Kampot' },
    { label: t('city.Kep'), value: 'Kep' },
    { label: t('city.Kratié'), value: 'Kratié' },
    { label: t('city.Pailin'), value: 'Pailin' },
    { label: t('city.PhnomPenh'), value: 'Phnom Penh' },
    { label: t('city.PreyVeng'), value: 'Prey Veng' },
    { label: t('city.Pursat'), value: 'Pursat' },
    { label: t('city.SiemReap'), value: 'Siem Reap' },
    { label: t('city.StungTreng'), value: 'Stung Treng' },
    { label: t('city.SvayRieng'), value: 'Svay Rieng' },
    { label: t('city.Takéo'), value: 'Takéo' },
    { label: t('city.TboungKhmum'), value: 'Tboung Khmum' },
  ];

  const handleSelectCity = (city: LocationOptions) => {
    setSelectedCity(city);
    if (onChange) {
      onChange(city.value);
    }
  };

  return (
    <div>
      <TitleLayout>
        <Title>{t('city.chooseCity')}</Title>
      </TitleLayout>
      {locationOptions.map((location) => (
        <SwitchLayout key={location.value}>
          <Switch
            key={location.value}
            active={selectedCity?.value === location.value}
            onClick={() => handleSelectCity(location)}
          />
          <Label>{location.label}</Label>
        </SwitchLayout>
      ))}
    </div>
  );
};

export default FiltredCitySidebar;

export const TitleLayout = styled.div`
  margin-bottom: 12px;
`;
export const Title = styled.div`
  font-size: ${typography.fontSize.lg};
  font-weight: bold;
  line-height: 26px;
`;
