import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import styled from 'styled-components';

export const ArticleImage = styled.img`
  border-radius: 4px;
  width: 50%;
  height: auto;
  max-width: 50%;
`;

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 12px;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 72px 48px;
    gap: 62px;
  }

  @media (min-width: 1440px) {
    padding: 72px 54px;
  }

  @media (min-width: 1920px) {
    padding: 72px 234px;
  }
`;

export const RichTextEditorLayout = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    padding: 24px 0;
  }
`;

export const ArticlesLayout = styled.div`
  width: 100%;
`;

export const ArticleButtonLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ArticleButton = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background-color: ${(props) => (props.isActive ? colors.dark_tangerine : colors.secondary_color.variant_one)};
  color: ${colors.white};
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  box-shadow: ${shadows.base};
  cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};

  &:hover {
    background-color: ${(props) => (props.isActive ? colors.orange : colors.secondary_color.variant_one)};
  }
`;

export const ButtonText = styled.div<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? colors.white : colors.text_grey)};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

export const SelectedImageContainer = styled.div`
  margin-top: 30px;
  text-align: left;
`;

export const SectionTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin: 24px 0;
  color: ${colors.text_grey};
  letter-spacing: 1px;
`;

export const Label = styled.label`
  color: ${colors.text_grey};
  letter-spacing: 1px;
`;

export const AudioLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.text_grey};
  letter-spacing: 1px;
`;

export const PreviewAudioLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
