import Header from '@components/Header/Header';
import CreateUsers from '@components/Users/CreateUsers';
import CreateLocation from '@pages/CreateLocation';
import colors from '@styles/common/colors';
import shadows from '@styles/common/shadows';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdOutlineAddLocationAlt } from 'react-icons/md';

const DashboardMobile: React.FC = () => {
  const [activeUserManagement, setActiveUserManagement] = useState<boolean>(false);
  const [activeLocation, setActiveLocation] = useState<boolean>(false);

  const handleUserManagementClick = () => {
    setActiveUserManagement(true);
    setActiveLocation(false);
  };

  const handleLocationClick = () => {
    setActiveLocation(true);
    setActiveUserManagement(false);
  };

  return (
    <div>
      <Header />
      <DashboardWrapper>
        <BoxLayout>
          <BoxWrapper isActive={activeUserManagement} onClick={handleUserManagementClick}>
            <IconWrapper
              isActive={activeUserManagement}
              activeColor={colors.white}
              inactiveColor={colors.dark_tangerine}
            >
              <HiOutlineUserGroup size={40} />
            </IconWrapper>
            <BoxTitle>User Management</BoxTitle>
          </BoxWrapper>
          <BoxWrapper isActive={activeLocation} onClick={handleLocationClick}>
            <IconWrapper
              isActive={activeUserManagement}
              activeColor={colors.white}
              inactiveColor={colors.dark_tangerine}
            >
              <MdOutlineAddLocationAlt size={40} />
            </IconWrapper>
            <BoxTitle>Location Management</BoxTitle>
          </BoxWrapper>
        </BoxLayout>
        {activeUserManagement && <CreateUsers />}
        {activeLocation && <CreateLocation />}
      </DashboardWrapper>
    </div>
  );
};

export default DashboardMobile;

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const BoxLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  margin-top: 16px;

  @media (min-width: 375px) {
    width: 95%;
    flex-direction: row;
  }
`;

type BoxWrapperProps = {
  isActive: boolean;
};

const BoxWrapper = styled.div<BoxWrapperProps>`
  width: 100%;
  background: ${colors.white};
  box-shadow: ${shadows.base};
  padding: 24px 12px;
  border-radius: 6px;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.dark_tangerine};
      color: ${colors.white};
    `}
`;

type IconWrapperProps = {
  isActive: boolean;
  activeColor: string;
  inactiveColor: string;
};

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;

  ${({ isActive, activeColor }) =>
    isActive &&
    css`
      svg {
        stroke: ${activeColor};
      }
    `}

  ${({ isActive, inactiveColor }) =>
    !isActive &&
    css`
      svg {
        stroke: ${inactiveColor};
      }
    `}
`;

const BoxTitle = styled.div`
  margin: 0;
  font-size: 16px;
`;
