import React, { ChangeEventHandler } from 'react';
import { ReactComponent as WarningIcon } from '@assets/icons/warning.svg';
import { Input, InputButton, InputLayout } from './Input.style';
import { InputIcon } from './InputIcon';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLButtonElement> {
  error?: boolean;
  icon?: React.FC;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string;
  type?: string;
}

export const FormInputWithIcon = (props: InputProps) => {
  const { error = false, icon, onClick, placeholder, value = '', onChange, type = 'text' } = props;
  return (
    <InputLayout>
      <Input error={error} placeholder={placeholder} value={value} onChange={onChange} type={type} />
      {icon ? (
        <InputButton onClick={onClick}>
          <InputIcon icon={error ? WarningIcon : icon} />
        </InputButton>
      ) : null}
    </InputLayout>
  );
};
