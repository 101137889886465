import colors from '@styles/common/colors';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  width: 220px;
  position: fixed;
  top: 12px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  margin: 0 4px;

  @media (min-width: 360px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 350px;
    left: 85px;
  }
`;

export const SearchInputStyled = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  outline: 0.5px solid ${colors.secondary_color.variant_one};
  font-size: 12px;
  text-indent: 25px;
  letter-spacing: 0.8px;

  &:focus {
    border: 0.5px solid ${colors.dark_tangerine};
    box-shadow: 0px 0px 3px ${colors.dark_tangerine};
  }

  &:not(:focus) {
    border: none;
    box-shadow: none; /* Removed the box-shadow property */
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const BurgerLayout = styled.div`
  position: absolute;
  top: 18px;
  left: 16px;
  z-index: 1000;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MarkerInfoLayout = styled.div`
  width: 70px;
  position: absolute;
  bottom: 70px;
  right: 0;
  background: ${colors.white};
  border-radius: 6px;
  padding-top: 8px;
  z-index: 999;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  display: none;
  color: ${colors.dark_tangerine};
  background: none;
  border: none;
  z-index: 999;

  @media (min-width: 768px) {
    display: block;
    top: 23px;
    left: 95px;
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 12px;
  right: 2px;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  z-index: 999;
`;

export const SearchResults = styled.div`
  width: 220px;
  position: absolute;
  top: 55px;
  left: 10px;
  z-index: 9999;
  max-height: 250px; /* Set a maximum height */
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow-y: scroll;

  /* Hide the scroll indicator */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 360px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 350px;
    left: 90px;
  }
`;

export const SearchItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;
`;

export const BorderLine = styled.div`
  width: 100%;
  border: 0.1px solid ${colors.secondary_color.variant_two};
`;

export const LoaderLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 300px;
`;

export const RecentSearchContainer = styled.div`
  width: 220px;
  position: absolute;
  top: 53px;
  left: 10px;
  padding: 12px;
  z-index: 9999;
  height: 250px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow-y: scroll;

  &.recent-search-container {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  }

  &.recent-search-container.show {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }

  @media (min-width: 360px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 350px;
    left: 90px;
  }
`;

export const RecentSearchTitle = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  font-weight: 500;
  letter-spacing: 0.5px;
`;

export const RecentSearchItem = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  font-size: 12px;
  color: ${colors.dark_tangerine};
  cursor: pointer;
`;

export const RecentSearchName = styled.div`
  font-size: 12px;
  color: ${colors.text_grey};
  letter-spacing: 0.5px;

  &:hover {
    color: ${colors.dark_tangerine};
  }
`;

export const HeaderRecentSearchLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const SearchItemLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  line-height: 24px;
`;
